import React, { useState } from "react"
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from "reactstrap"
import useGlobal from "../../../hooks/store/index"
import Suggestions from "./Suggestions"

library.add(fas);
dom.watch();

const CollegeSearchBar = props => {
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [globalState, globalActions] = useGlobal()
  const { suggestions, searchName } = globalState.college

  const handleSubmit = (event) => {
    try{
      event.preventDefault()
      globalActions.college.handleStoreChange("currentPage", 1)
      globalActions.college.handleStoreChange("searchBy", "name")
      globalActions.college.searchByName()
    }
    catch{
      alert('Please enter a college name');
    }
  }

  const handleChange = (value) => {
    globalActions.college.handleStoreChange("suggestions", [])
    globalActions.college.handleStoreChange("searchName", value)
    if(value.length >= 3) {
      setTimeout(() => {
        globalActions.college.getSuggestions({ name: value })
      }, 1000)
    }
  }

  return (
    <div>
      <Row>
        <Form onSubmit={handleSubmit}>
          <Col className="no-padding col-xs-8 col-md-9 col-sm-8 col-lg-9">
            <Input placeholder="Enter college name" className="no-border-radius no-box-shadow" value={searchName} onChange={(event) => handleChange(event.target.value)} onFocus={() => setShowSuggestions(true)} onBlur={() => setShowSuggestions(false)}></Input>
          </Col>
          <Col className="no-padding col-xs-2 col-md-2 col-sm-2 col-lg-2">
            <Button className="search--college-button"><FontAwesomeIcon icon="search" /></Button>  
          </Col>
        </Form>
      </Row>
      <Suggestions value={searchName} showSuggestions={showSuggestions} suggestions={suggestions} />
    </div>
  )
}
export default CollegeSearchBar
