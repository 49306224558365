import React, { useState, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import { generalInformation } from "./table_columns/general_information_columns";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDownAlt,
  faSortAmountUpAlt,
} from "@fortawesome/free-solid-svg-icons";
import { renderPageButtons } from "./Pagination";
import { FilterAndSearch } from "./Filters";
import useGlobal from "../../../hooks/store";
import { emptyMessage, FILTER_TYPES } from "./utils";
import DatesFilterGeneralInformation from "./DatesFiltersGeneralInformation";
import DateFilters from "./DateFilters";
import { Col, Row } from "reactstrap";
import NextBookedWorkshopFilters from "./NextBookedWorkshopFilters";
import ClearFiltersSection from "./ClearFilters";
import EmailModalWrapper from "./EmailModalWrapper";

function GeneralInformationTable({
  studentData,
  counselor_list,
  employee,
  grad_year_list,
  next_booked_workshop_name_list,
  program_list,
  paginationData,
}) {
  const [globalState, globalActions] = useGlobal();
  const { pagination, sort } = globalState.dashboard;
  const [tableData, setTableData] = useState([]);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pagination.amountPerPage);
  const [pageNumber, setPageNumber] = useState(1);
  const [sorting, setSorting] = useState([
    { sortBy: "student_name", sortDir: false },
    { sortBy: "number_of_meetings", sortDir: false },
    { sortBy: "last_meeting", sortDir: false },
    { sortBy: "next_contact_date", sortDir: false },
    { sortBy: "next_meeting", sortDir: false },
    { sortBy: "number_of_workshops", sortDir: false },
    { sortBy: "next_booked_workshop_name", sortDir: false },
    { sortBy: "next_booked_workshop_date", sortDir: false },
  ]);
  const [filteredData, setFilteredData] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filterType, setFilterType] = useState("");
  const { handleStoreChangeDashboard } = globalActions.dashboard;
  const [selectedField, setSelectedField] = useState("");
  const [selectedDir, setSelectedDir] = useState("ASC");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [message, setMessage] = useState("");
  const {
    counselorId,
    searchValue,
    gradYear,
    daysSinceLastMeeting,
    nextBookedWorkshopName,
    nextBookedWorkshopDate,
    programSelect,
    programNames,
  } = globalState.dashboardFilters;

  useEffect(() => {
    setLoading(true);
    handleStoreChangeDashboard("pagination", {
      page: page,
      amountPerPage: pageSize,
      next: pagination.next,
      prev: pagination.prev,
      totalPages: pagination.pages,
      totalData: pagination.count,
    });
    setLoading(false);
  }, []);

  const columns = React.useMemo(() => generalInformation(), []);

  const handleFilteredStates = () => {
    setLoading(!loading);
    setIsFiltering(!isFiltering);
  };

  const updateTableData = (data) => {
    setTableData(data);
  };

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const updateSorting = (sorting, field) => {
    return sorting.map((item) => {
      if (item.sortBy === field) {
        return { sortBy: field, sortDir: !item.sortDir };
      } else {
        return item;
      }
    });
  };

  const handleSortBy = (field) => {
    const updatedSort = updateSorting(sorting, field);
    setSorting(updatedSort);
    const selectedField = field;
    const selectedDir = updatedSort.find((item) => item.sortBy === field)
      ?.sortDir
      ? "DESC"
      : "ASC";
    handleStoreChangeDashboard("sort", {
      sortDir: selectedDir,
      sortByGI: selectedField,
      sortByTask: sort.sortByTask,
    });
    filterData(field, selectedDir);
  };

  const handleFilteredData = (data, filterType) => {
    updateTableData(data);
    setFilterType(filterType);
  };

  const build_filters = () => {
    return {
      filter_by_counselor: counselorId,
      search_by_text: searchValue,
      filter_by_grad_year: gradYear,
      filter_by_last_meeting: daysSinceLastMeeting,
      filter_by_next_booked_workshop_name: nextBookedWorkshopName,
      filter_by_next_booked_workshop_date: nextBookedWorkshopDate,
      filter_by_program: programSelect,
      filter_by_programs: programNames,
    };
  };

  const filterData = (selectedF, selectedD) => {
    setLoading(true);
    globalActions.dashboard
      .filterDataBy({
        ...build_filters(),
        section: "general_info",
        page: pagination.page,
        per_page: pagination.amountPerPage,
        sort_by: selectedF,
        sort_dir: selectedD,
      })
      .then((response) => {
        handleFilteredData(response.data.records, "");
        setPageNumber(page);
        setPage(page);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cellStyle = {
    color: "black",
    textAlign: "center",
    verticalAlign: "middle",
  };

  const redCellStyle = { ...cellStyle, color: "red" };

  useEffect(() => {
    setMessage(emptyMessage(filterType, filteredData));
  }, [filterType]);

  return (
    <>
      <Row className="margin-top">
        <Col md={2} lg={2} xl={2}>
          <div className="date-filters-general-info">
            <ClearFiltersSection
              handleFilteredStates={handleFilteredStates}
              handleFilteredData={handleFilteredData}
              section="general_info"
              employee={employee}
            />
            <hr className="left-menu-divider" />
            <DatesFilterGeneralInformation
              handleFilteredData={handleFilteredData}
              section="general_info"
              handleFilteredStates={handleFilteredStates}
            />
            <hr className="left-menu-divider" />
            <DateFilters
              handleFilteredData={handleFilteredData}
              section="general_info"
              handleFilteredStates={handleFilteredStates}
            />
            <hr className="left-menu-divider" />
            <NextBookedWorkshopFilters
              handleFilteredData={handleFilteredData}
              section="general_info"
              handleFilteredStates={handleFilteredStates}
              next_booked_workshop_name_list={next_booked_workshop_name_list}
            />
            <EmailModalWrapper
              globalActions={globalActions}
              totalData={pagination.totalData}
              filters={build_filters()}
              section={"general_info"}
              setShowSuccessMessage={setShowSuccessMessage}
              setShowErrorMessage={setShowErrorMessage}
            />
          </div>
        </Col>

        <Col md={12} lg={12} className="column-top-less">
          <div className="filter-and-search">
            <FilterAndSearch
              handleFilteredData={handleFilteredData}
              counselor_list={counselor_list}
              section="general_info"
              grad_year_list={grad_year_list}
              employee={employee}
              program_list={program_list}
              setLoading={setLoading}
            />
          </div>
          <div className="table-container">
            {showSuccessMessage && (
              <div className="alert alert-success custom-message" role="alert">
                The mass email has been sent successfully
              </div>
            )}
            {showErrorMessage && (
              <div className="alert alert-danger custom-message" role="alert">
                Error Sending Mass Email
              </div>
            )}
            <table {...getTableProps()} className="table table-dashboard">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="table-header-general-info"
                      >
                        {column.render("Header")}
                        <span className="icon-arrows-sort">
                          {column.id === "notes_dashboard" ||
                          column.id === "counselor_meetings" ? null : (
                            <FontAwesomeIcon
                              onClick={() => handleSortBy(column.id)}
                              icon={
                                sorting.find(
                                  (item) => item.sortBy === column.id
                                )?.sortDir
                                  ? faSortAmountDownAlt
                                  : faSortAmountUpAlt
                              }
                            />
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {!loading && (
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                            style={
                              ((cell.column.id === "number_of_workshops" ||
                                cell.column.id === "number_of_meetings") &&
                                cell.value === 0) ||
                              (cell.column.id === "next_booked_workshop_name" &&
                                !cell.value)
                                ? redCellStyle
                                : cellStyle
                            }
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {!loading && pagination.totalData === 0 && message && (
              <div className="tasks-dashboard-title message">{message}</div>
            )}
            {loading && <div className="loading">Loading...</div>}
            <div className="pagination-container">
              {renderPageButtons(
                pageNumber,
                setPageNumber,
                listData,
                pageSize,
                setPageSize,
                globalActions,
                pagination,
                globalState,
                handleFilteredData,
                handleFilteredStates,
                "general_info",
                selectedField,
                selectedDir,
                setLoading
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default GeneralInformationTable;
