import React from "react"
import axios from "axios"
import {
  Row,
} from "reactstrap"
import { getAcceptedColleges, getWaitedColleges, getRejectedColleges, getCollegeFinancialPackage}  from "../../../../hooks/actions/collegeDecision"
import { generatePath } from "react-router" 
import useGlobal from "../../../../hooks/store"

const SuggestionsDecision = ({ value, showSuggestions, suggestions, option, student }) => {
  const [globalState, globalActions] = useGlobal();
  const { setFinalDecision, setAcceptedColleges, setClearInput, setWaitlistedColleges, setRejectedColleges, setFinancialDecision, setClearSearch, setCollegeFinancialPackage} = globalActions.collegeDecision
  const { acceptedColleges, waitlistedColleges, rejectedColleges, collegeFinancialPackage } = globalState.collegeDecision

  const addCollegeList = async (college) => {
    setClearInput(true);
    
    if (option === 'Accepted Colleges') {
      const index = acceptedColleges.filter(c => c.college === college.id).length;
  
      if (index === 0) {
        setAcceptedColleges([...acceptedColleges, college]);
  
        try {
          const data = await getAcceptedColleges({ accepted_colleges: college.id, id: student.id });
          if (data.id !== undefined) {
            setAcceptedColleges([...acceptedColleges, data]);
          }
        } catch (error) {
          console.error("Error fetching accepted colleges:", error);
        }
      }
    } else if(option == 'Waitlisted Colleges'){
      const index = waitlistedColleges.filter(c => c.id == college.id).length

      if(index == 0) {
        setWaitlistedColleges([...waitlistedColleges, college])

        try {
          const data = await getWaitedColleges({ waitlisted_colleges: college.id, id: student.id });
          if (data.id !== undefined) {
            setWaitlistedColleges([...waitlistedColleges, data]);
          }
        } catch (error) {
          console.error("Error fetching waitlist colleges:", error);
        }
      }
    } else if(option == 'Rejected Colleges'){
      const index = rejectedColleges.filter(c => c.id == college.id).length

      if(index == 0) {
        setRejectedColleges([...rejectedColleges, college])

        try {
          const data = await getRejectedColleges({ rejected_colleges: college.id, id: student.id });
          if (data.id !== undefined) {
            setRejectedColleges([...rejectedColleges, data]);
          }
        } catch (error) {
          console.error("Error fetching waitlist colleges:", error);
        }
      }
    } else if(option == 'Financial Package'){
      setClearSearch(true)
      setFinancialDecision(college)
      collegeFinancialPackage.financial_aid_package.selected_college_id = college.id
      setCollegeFinancialPackage(collegeFinancialPackage)
      try {
        const data = await getCollegeFinancialPackage({ college_id: college.id, id: student.id });
        if (data.id !== undefined) {
        }
      } catch (error) {
        console.error("Error fetching Financial Package:", error);
      }
    }
  };
  
  return (
    <Row>
        {
           !!value &&
          value.length >= 3 &&
          showSuggestions &&
          suggestions.length > 0 &&
          <div className="d-flex">
            <ul className="search-input-container .earch-input-width">
              {
                 suggestions.length > 0 ? (
                suggestions.map((college, index) => {
                  return (
                    <li key={index} className="option" onMouseDown={() => (addCollegeList(college))}>
                      <div>
                        <span>{college.name}</span>
                        <br />
                        <span>
                          {
                            college?.details?.city &&
                            college.details.state &&
                            `${college.details.city}, ${college.details.state}`
                          }
                        </span>
                      </div>
                    </li>
                  )
                })
                ) : (<div></div>)}
            </ul>
          </div>
        }
    </Row>
  )
}
export default SuggestionsDecision
