export const getUserTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const convertToUserTimeZone = (date) => {
  const userTimeZone = getUserTimeZone();

  const dateObj = new Date(date);

  const userDateStr = dateObj.toLocaleString("en-US", {
    timeZone: userTimeZone,
  });
  const userDate = new Date(userDateStr);

  return userDate;
};
