import React from "react";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import Table from "./TasksTable";

function Tasks({
  counselor_list,
  grad_year_list,
  employee,
  paginationData,
  program_list,
}) {
  return (
    <div>
      <h1 className="dashboard-title">Tasks</h1>
      <Table
        counselor_list={counselor_list}
        grad_year_list={grad_year_list}
        employee={employee}
        paginationData={paginationData}
        isWorkshops={false}
        program_list={program_list}
      />
    </div>
  );
}

export default Tasks;
