import React from 'react';

const milestonecolumns = () => [
    {
      Header: '',
      accessor: 'task_name',
    },
    {
      Header: 'Completed',
      accessor: 'completed',
      Cell: ({ value }) => value,
    },
    {
      Header: 'In Progress',
      accessor: 'in_progress',
      Cell: ({ value }) => value,
    },
    {
      Header: 'Not Started',
      accessor: 'not_started',
      Cell: ({ value }) => value,
    },
    {
      Header: 'Opted Out',
      accessor: 'opted_out',
      Cell: ({ value }) => value,
    },
    {
      Header: 'Registered',
      accessor: 'registered',
      Cell: ({ value }) => value,
    }
  ];
  
  export { milestonecolumns };