import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import useGlobal from "../../../hooks/store";
import CollegeListSearchBar from "./CollegeListSearchBar";
import SwitchToggle from "../../helpers/toggleSwitch";
import CollegeTable from "./CollegeTable";
import { gapi } from 'gapi-script';
import { download } from './college-list-export/excelUtils';
import { signInWithGoogle, initializeGapi } from '../colleges/college-list-export/googleDriveUtils';

const CollegeList = ({
  collegeLists,
  collegeDetails,
  arrayCollegesType,
  arrayCollegePriority,
  adminImpersonating,
  app_mode_preference,
  person_selected_college,
}) => {
  const [disable, setDisable] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const { collegesList, currentListType } = globalState.student;
  const [currentList, setCurrentList] = useState([]);
  const [applicationMode, setApplicationMode] = useState(app_mode_preference);
  useEffect(() => {
    if (collegesList.length === 0 && currentListType === null) {
      globalActions.student.handleStoreChange("collegesList", collegeLists)
      globalActions.student.handleStoreChange("currentListType", "my_colleges")
    }
  })
  
  useEffect(() => {
    initializeGapi();
    window.sessionStorage.setItem('isCollegeSearchComponent', false);
  }, []);

  useEffect(() => {
    if(isThereAtLeastOneCollegeWithThisType(collegesList, "my_colleges")=== undefined){
      setDisable(true)
    }
    else if(currentListType!="for_you"){
        setDisable(false)  
    }
    const filteredColleges = collegesList.filter(collegeList => (collegeList.list_type === currentListType ? collegeList : null));
    setCurrentList(filteredColleges.sort((a, b) => a.ranking - b.ranking));
  }, [currentListType, collegesList])

  const isThereAtLeastOneCollegeWithThisType = (collegesList, listType) =>
    collegesList.find(collegeList => (collegeList.list_type === listType))
    
  const setCurrentListType = (type) => {
    if (type === "my_colleges") {
      if(isThereAtLeastOneCollegeWithThisType(collegesList, "my_colleges")=== undefined){
        setDisable(true)
      }else{
        setDisable(false);
      }
    } else if (type === "for_you") {
      setDisable(true);
    }
    globalActions.student.handleStoreChange("currentListType", type)
  }

  const counselors = []
  collegesList.map(collegeList => {
    const counselor = `${collegeList.added_by_person};${collegeList.added_by_person_image}`
    if (counselors.indexOf(counselor) === -1 && collegeList.list_type === "for_you") {
      counselors.push(counselor)
    }
  })

  const forYouIds = []
  const myCollegesIds = []
  collegesList.map(collegeList => {
    if (forYouIds.indexOf(collegeList.college.id) === -1 && collegeList.list_type === "for_you") {
      forYouIds.push(collegeList.college.id)
    }
    if (myCollegesIds.indexOf(collegeList.college.id) === -1 && collegeList.list_type === "my_colleges") {
      myCollegesIds.push(collegeList.college.id)
    }
  })

  const changeAppModePreference = () => {
    globalActions.student.updateAppModePreference(!applicationMode)
    setApplicationMode(!applicationMode);
  };

  const studentName = person_selected_college[0].first_name + " " + person_selected_college[0].last_name;

  function downloadExcel() {
      download(currentList, collegeDetails, studentName, adminImpersonating);
  }
 
  return (
    <div className="college-list-container">
      {<CollegeListSearchBar adminImpersonating={adminImpersonating} />}
      <ListGroup>
        <ListGroupItem className="list-group-college-item">
          <Row>
            <Col sm={12} md={12} lg={12}>
              <SwitchToggle label="Application Mode" isChecked={applicationMode} handleToggleChange={() => changeAppModePreference()} />
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={6} lg={6} className="col-xs-6 text-center college-title">
              <Row>
                <Col sm={12} md={12} lg={12} className="col-xs-12">
                  <span
                    className={currentListType === "my_colleges" ?
                      "search-and-filter--title-active" :
                      "search-and-filter--title-inactive"
                    }
                    onClick={() => setCurrentListType("my_colleges")}
                  >
                    MY COLLEGES
                  </span>
                </Col>
                <Col sm={12} md={12} lg={12} className="col-xs-12 margin-top-14">
                  <span
                    className="search-and-filter--results"
                  >
                    {collegesList.filter(
                      collegeList => (collegeList.list_type === "my_colleges" ?
                        collegeList :
                        null
                      )).length + " " + "results"}
                  </span>
                </Col>
                <Col sm={2} md={2} lg={4} className="col-xs-12 margin-top-14">
                <Button
                  className="download--college-button"
                  disabled={disable}
                  onClick={() => downloadExcel()}
                  >
                  <FontAwesomeIcon icon="download" />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col sm={6} md={6} lg={6} className="col-xs-6 text-center college-title">
              <Row>
                <Col sm={12} md={12} lg={4} className="col-xs-12 padding-left-5">
                  {
                    counselors.map((counselor, index) => {
                      const details = counselor.split(";")
                      return (
                        <div key={index} data-toggle="tooltip" data-placement="bottom" title={details[0]}>
                          <img
                            src={details[1]}
                            width="230"
                            height="230"
                            className="round-img-hc person-photo--college-table"
                          />
                        </div>
                      )
                    })
                  }
                </Col>
                <Col sm={12} md={12} lg={8} className="col-xs-12">
                  <span
                    className={currentListType === "for_you" ?
                      "search-and-filter--title-active" :
                      "search-and-filter--title-inactive"
                    }
                    onClick={() => setCurrentListType("for_you")}
                  >
                    Recommended
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <CollegeTable
              applicationMode={applicationMode}
              collegeDetails={collegeDetails}
              currentList={currentList}
              setCurrentList={setCurrentList}
              adminImpersonating={adminImpersonating}
              arrayCollegesType={arrayCollegesType}
              arrayCollegePriority={arrayCollegePriority}
              forYouIds={forYouIds}
              myCollegesIds={myCollegesIds}
            />
          </Row>
        </ListGroupItem>
      </ListGroup>
    
    </div>
  )
}

export default CollegeList
