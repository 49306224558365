export const DEFAULT_PROGRAM_NAMES = [
  "College Admissions Program",
  "College Admissions Program Elite",
  "College Admissions Select",
];
const dashboardFilters = {
  searchValue: "",
  counselorId: "",
  gradYear: "",
  programNames: DEFAULT_PROGRAM_NAMES,
  haveSetCurrentEmployee: false,
  nextContactDate: "",
  nextMeetingDate: "",
  daysSinceLastMeeting: "",
  selectedButton: "",
  filterSelected: false,
  dropdownOptionSelected: "",
  nextBookedWorkshopName: "",
  nextBookedWorkshopDate: "",
  programSelect: "",
  hoursRemaining: false,
};

export default dashboardFilters;
