import React from "react";
import CollegeTableShow from "./CollegeTableShow";
import Section from "./Section";


const AdmissionFactors = ({title, props, customeref, admissionFactors}) => {

    return (
        <>
            <Section 
                title={title}
                props={props}
                customeref={customeref}
                leftColumnElements={4}
            />
            <CollegeTableShow 
                title={admissionFactors.title}
                headers={admissionFactors.headers}
                columns={admissionFactors.columns}
                columnsLenght={admissionFactors.columnsLenght}
            
            />
        </>
    )
}

export default AdmissionFactors
