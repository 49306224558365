import axios from "axios";
import { generatePath } from "react-router";
import { FILTERHEALTHYCONTACT } from "../apiConstants";
import { healthyContactFilters } from ".";

export const filterDataBy = (store, params) => {
  const hostUrl = generatePath(FILTERHEALTHYCONTACT, {
    filter_data_params: JSON.stringify(params),
  });

  return axios.get(hostUrl, {
    headers: {
      Accept: "application/json",
    },
  });
};

export const handleStoreChange = (store, key, value) => {
  store.setState({
    healthyContactFilters: {
      ...store.state.healthyContactFilters,
      [key]: value,
    },
  });
};
