import React from "react";
import { Row, Col } from "reactstrap";
import startCase from "lodash/startCase";

const AcceptanceRates = ({ title, props, customeref }) => {
  const { acceptanceRatesDetails, additionalNotes } = props;

  return (
    <div id="acceptance-rates">
      <label ref={customeref} className="College-Font">
        {title}
      </label>
      <div className="safe-subtitle-body">
        {Object.entries(props).map(
          ([key, value]) =>
            key !== "additionalNotes" &&
            key !== "acceptanceRatesDetails" && (
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <Row>
                    <Col sm={4} md={8} lg={8}>
                      <p className="control-label" id={key}>
                        {startCase(key)}
                      </p>
                    </Col>
                    <Col sm={2} md={4} lg={4}>
                      <p className="props-font text-start">{String(value)}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
        )}
        <label className="control-label">ADDITIONAL NOTES:</label>
        {additionalNotes && (
          <div className="notes">
            <p>
              {additionalNotes.value !== null
                ? String(additionalNotes.value)
                : "No notes available"}
            </p>
          </div>
        )}
      </div>
      {acceptanceRatesDetails &&
        acceptanceRatesDetails.majors &&
        acceptanceRatesDetails.majors.length > 0 && (
          <>
            <label className="control-label">ACCEPTANCE RATES BY MAJOR</label>
            <div className="safe-subtitle-body">
              {props.acceptanceRatesDetails.majors.map((major) => (
                <Row key={major.title}>
                  <Col sm={12} md={6} lg={6}>
                    <Row>
                      <Col sm={4} md={8} lg={8}>
                        <p className="control-label">{major.title}</p>
                      </Col>
                      <Col sm={2} md={4} lg={4}>
                        <p className="props-font text-start">
                          {String(major.value)}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </div>
          </>
        )}
    </div>
  );
};

export default AcceptanceRates;
