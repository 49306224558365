import React, { useState, useEffect } from "react"
import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap"
import useGlobal from "../../../hooks/store/index"

import { generatePath } from "react-router"
import { COLLEGEPAGE } from "../../../hooks/apiConstants"
import CollegeMenu from "./CollegeMenu"
import CollegeSearchBar from "./CollegeSearchBar"
import CollegeFilter from "./CollegeFilter"
import { ClipLoader } from "react-spinners"

const CollegeSearch = ({ statesList, adminImpersonating, studentCollegesList, arrayCollegesType, arrayCollegePriority }) => {
  const [globalState, globalActions] = useGlobal()
  const [requestingMoreResults, setRequestingMoreResults] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const { requesting, colleges, currentPage, totalPages, searchBy, total_colleges, filters, searchName } = globalState.college
  const { collegesList } = globalState.student

  useEffect(() => {  
    colleges === null ? setShowInfo(false) : setShowInfo(true) 
  }, [colleges])

  const showCollegeAction = (url) => {
    window.sessionStorage.setItem('filters', JSON.stringify(filters));
    window.sessionStorage.setItem('searchName', searchName);
    window.sessionStorage.setItem('currentPage', currentPage);
    window.sessionStorage.setItem('searchBy', searchBy);
    window.sessionStorage.setItem('isCollegeSearchComponent', true);
    window.location = url
  }

  const getFilterSummary = () => {
    let filterSummary = [];
    let lengthFilter = "";
    let filterSelected = "";
    Object.entries(filters).forEach(([key, value]) => {
      if (key){
        if ( key === "include2YrColleges"){
          !!value && filterSummary.push("Included 2 years"); 
        } else if(typeof value === "array") {
          value.forEach((vValue) => {
            !!vValue && filterSummary.push(vValue); 
          })
        } else{
          if(value && value != ""){
            filterSummary.push(value);
          }
        }
      }
    });
    lengthFilter = filterSummary.toString().split(",");
    lengthFilter.forEach((element,index)=> {
      filterSelected += lengthFilter.length - 1 == index ? element : element + ", "
    });
    if (searchBy === "filter") {
      return filterSelected;
    } else {
      return false;
    }
  }

  const loadSearch = () => {
    const inputSearchBy = window.sessionStorage.getItem("searchBy");
     if (inputSearchBy !== null) {
       const inputFilters = window.sessionStorage.getItem('filters');
       const inputCurrentPage = window.sessionStorage.getItem('currentPage');
       const inputSearchName = window.sessionStorage.getItem('searchName');
      if (inputSearchBy === "filter") {
        globalActions.college.handleStoreChange("filters", JSON.parse(inputFilters));
        globalActions.college.handleStoreChange("currentPage", inputCurrentPage);
        globalActions.college.handleStoreChange("searchBy", "filter");
        globalActions.college.filterColleges(currentPage);

      } else {
        globalActions.college.handleStoreChange("searchName", inputSearchName);
        globalActions.college.handleStoreChange("currentPage", inputCurrentPage);
        globalActions.college.handleStoreChange("searchBy", "name");
        globalActions.college.searchByName();

       }
     }
  }

  const loadMore = () => {
    setRequestingMoreResults(true)
    const nextPage = currentPage + 1
    globalActions.college.handleStoreChange("currentPage", nextPage)
    if(searchBy === "name") {
      globalActions.college.searchByName()
    } else {
      globalActions.college.filterColleges()
    }
  }

  

  useEffect(() => {
    loadSearch();
    if(collegesList !== studentCollegesList) {
      globalActions.student.handleStoreChange('collegesList', studentCollegesList)
    }
  }, [studentCollegesList])

  useEffect(() => {
    if(!requesting) {
      setRequestingMoreResults(false)
    }
  }, [requesting])

  const getCollege = (collegeId) => collegesList.find(({college}) => college.id === collegeId)
  const getCollegesInStudentList = (collegeId) => collegesList.filter(({college}) => college.id === collegeId)
  return (
    <div className="college-list-container">
      <div className="margin-btm-15">
        <h2 className="header-second-level text--bold">COLLEGES</h2>
      </div>
      <Row>
        <Col className="col-lg-1 col-sm-2 col-xs-12">
          <CollegeFilter statesList={JSON.parse(statesList)} />
        </Col>
        <Col className="col-lg-1 col-sm-1 col-xs-12"></Col>
        <Col className="col-lg-10 col-sm-9 col-xs-12 search-bar--top">
          <CollegeSearchBar />
        </Col>
      </Row>
      {
        requesting && !requestingMoreResults
        ?
        <div className="dashboard__normal-height horizontally-and-vertically-centered">
          <ClipLoader size={50} color={"#56468F"} />
        </div>
        :
        <ListGroup>
          {
            getFilterSummary().length > 0 &&
            <span className="search-and-filter--results font-weight-bold">
              <b>Filters Selected: </b>
              {
                getFilterSummary() 
              }
            </span>
          }

          {
            showInfo &&
            total_colleges >= 0 &&
            <ListGroupItem className="list-group-college-item">
              <Row className="college-search-details-header">
                <Col md={4} lg={4}>
                  <span className="search-and-filter--results">
                    {colleges.length + "/" + (total_colleges || 0) + " " + "results"}
                  </span>
                </Col>
                <Col md={2} lg={2} className="text-center hidden-xs hidden-sm">
                  Undergrad Enrollment
                </Col>
                <Col md={2} lg={2} className="text-center hidden-xs hidden-sm">
                  ACT/SAT 25/75% 
                </Col>
                <Col md={2} lg={2} className="text-center hidden-xs hidden-sm">
                  In State/Out of State Cost
                </Col>
                <Col md={2} lg={2}>
                </Col>
              </Row>
            </ListGroupItem>
          }
          {
            colleges.map((college, index) => {
              const showCollegeUrl = generatePath(COLLEGEPAGE, { id: college.id })
              const collegesInStudentList = getCollegesInStudentList(college.id)
              const collegeInformation = getCollege(college.id)
              let under_number = college.details.num_undergrads_number
              if (under_number === 0 || under_number === null){
                under_number = "n/a"
              }
              let in_state_number = college.details.in_state_total_cost_number
              if ( in_state_number === 0 ||  in_state_number === null){
                in_state_number = "n/a"
              }
              let out_state_number = college.details.out_of_state_total_cost_number
              if ( out_state_number === 0 ||  out_state_number === null){
                out_state_number = "n/a"
              }
              return (
                <ListGroupItem key={index} className="list-group-college-item separate-elements college-item">
                  <Col md={4} lg={4}>
                    <span className="list-group-college-item-name" onClick={() => (showCollegeAction(showCollegeUrl))}>{college.name}</span>
                    <br />
                    <span className="list-group-college-item-location">
                      {
                        !!college.details &&
                        !!college.details.city &&
                        !!college.details.state &&
                        college.details.city + ", " + college.details.state
                      }
                    </span>
                  </Col>
                  <Col md={2} lg={2} className="text-center hidden-xs hidden-sm">
                    { under_number }
                  </Col>
                  <Col md={2} lg={2} className="text-center hidden-xs hidden-sm">
                    { college.details.act_total }
                    <br />
                    { college.details.sat_total}
                  </Col>
                  <Col md={2} lg={2} className="text-center hidden-xs hidden-sm">
                    { in_state_number } / { out_state_number }
                  </Col>
                  <Col className="photo-container col-xs-3 col-sm-3 col-md-2 col-lg-2">
                    <CollegeMenu
                      collegeId={college.id}
                      adminImpersonating={adminImpersonating}
                      arrayCollegesType={arrayCollegesType}
                      arrayCollegePriority={arrayCollegePriority}
                      showPriorities={true}
                      isInMyColleges={!!collegesInStudentList.find(({list_type}) => list_type === "my_colleges")}
                      collegeIsInList={!!collegesInStudentList}
                      priorityType={collegeInformation ? collegeInformation.priority_type : null }
                    />
                  </Col>
                </ListGroupItem>
              )
            })
          }
        </ListGroup>
      }
      {
        !!colleges &&
        total_colleges > 50 &&
        colleges.length != total_colleges &&
        <Button className="load-more-btn" disabled={currentPage === totalPages} onClick={() => loadMore()}>
          {
            requesting && requestingMoreResults
            ?
            <div className="horizontally-and-vertically-centered">
              <ClipLoader size={25} color={"#56468F"} />
              retrieving more results...
            </div>
            :
            <>
              SHOW MORE RESULTS
            </>
          }
        </Button>
      }
    </div>
  )
}

export default CollegeSearch
