import React from "react";
import useGlobalHook from "use-global-hook";
import * as actions from "../actions";
import college from "./college";
import student from "./student";
import collegeDecision from "./collegeDecision";
import dashboardFilters from "./dashboardFilters";
import dashboard from "./dashboard";
import milestoneFilters from "./milestoneFilters";
import essayCoachPerformanceFilters from "./essayCoachPerformanceFilters";
import tutorReportFilters from "./tutorReportFilters";
import counselorPerformanceFilters from "./counselorPerformanceFilters";
import healthyContactFilters from "./healthyContactFilters";
const initialState = {
  college,
  student,
  collegeDecision,
  dashboardFilters,
  dashboard,
  milestoneFilters,
  essayCoachPerformanceFilters,
  tutorReportFilters,
  counselorPerformanceFilters,
  healthyContactFilters,
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
