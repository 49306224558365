import React, { useState } from "react"
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Col,
  Input,
  Row,
} from "reactstrap"
import useGlobal from "../../../hooks/store/index"
import SuggestionsCompare from "./SuggestionsCompare"

library.add(fas);
dom.watch();

const CollegeCompareSearchBar = props => {
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [globalState, globalActions] = useGlobal()
  const { suggestions, searchName } = globalState.college

  const handleChange = (value) => {
    globalActions.college.handleStoreChange("searchName", value)
    if(value.length >= 3) {
      setTimeout(() => {
        globalActions.college.getSuggestions({ name: value })
      }, 1000)
    }
  }

  return (
    <div>
      <Row>
        <Input 
          id='collegeName'
          className="no-border-radius no-box-shadow" 
          placeholder="Search college names"
          value={searchName} 
          onChange={(event) => handleChange(event.target.value)} 
          onFocus={() => setShowSuggestions(true)} 
          onBlur={() => setShowSuggestions(false)}
        />
      </Row>
      <SuggestionsCompare value={searchName} showSuggestions={showSuggestions} suggestions={suggestions} />
    </div>
  )
}
export default CollegeCompareSearchBar
