import React, { useState, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import { tasks } from "./table_columns/tasks_columns";
import { workshops } from "./table_columns/workshop_columns";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { faSortAmountUpAlt } from "@fortawesome/free-solid-svg-icons";
import { renderPageButtons } from "./Pagination.js";
import { FilterAndSearch } from "./Filters";
import useGlobal from "../../../hooks/store";
import { emptyMessage } from "./utils";
import ClearFiltersSection from "./ClearFilters";
import { Col, Row } from "reactstrap";

function TasksTable({
  counselor_list,
  grad_year_list,
  employee,
  program_list,
  paginationData,
  isWorkshops,
}) {
  const [globalState, globalActions] = useGlobal();
  const { pagination, sort } = globalState.dashboard;
  const { amountPerPage } = globalState.dashboard;
  const [tableData, setTableData] = useState([]);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pagination.amountPerPage);
  const [pageNumber, setPageNumber] = useState(1);
  const [sorting, setSorting] = useState([
    { sortBy: "student_name", sortDir: false },
    { sortBy: "complete_youscience_assessment", sortDir: false },
    { sortBy: "review_s_a_f_e_college_preferences", sortDir: false },
    { sortBy: "create_college_research_plan", sortDir: false },
    { sortBy: "review_high_school_course_selection", sortDir: false },
    { sortBy: "develop_test_prep_strategy", sortDir: false },
    { sortBy: "private_budget_meeting", sortDir: false },
    { sortBy: "develop_preliminary_college_list", sortDir: false },
    { sortBy: "establish_recommendation_letter_strategy", sortDir: false },
    { sortBy: "develop_resume", sortDir: false },
    { sortBy: "define_scholarship_plan", sortDir: false },
    { sortBy: "refine_college_list", sortDir: false },
    { sortBy: "common_app_personal_statement_topic_identified", sortDir: false },
    { sortBy: "supplemental_essay_strategy", sortDir: false },
    { sortBy: "complete_my_common_app_personal_statement", sortDir: false },
    { sortBy: "set_application_strategy", sortDir: false },
    { sortBy: "1_1_application_review", sortDir: false },
    { sortBy: "all_essays_reviewed", sortDir: false },
    { sortBy: "mock_college_interview_meeting", sortDir: false },
    { sortBy: "award_assessment_decision_meeting", sortDir: false },
    { sortBy: "major_and_career_exploration_workshop", sortDir: false }, //workshops
    { sortBy: "stand_out_factor_workshop", sortDir: false },
    { sortBy: "college_admissions_workshop_101", sortDir: false },
    { sortBy: "mastering_academic_success_workshop", sortDir: false },
    { sortBy: "standing_out_as_an_underclassmen_workshop", sortDir: false },
    { sortBy: "paying_for_college_workshop", sortDir: false },
    { sortBy: "take_a_practice_act_or_sat", sortDir: false },
    { sortBy: "junior_scholarship_workshop", sortDir: false },
    { sortBy: "activities_honors_workshop", sortDir: false },
    { sortBy: "common_app_personal_statement_workshop", sortDir: false },
    { sortBy: "common_application_workshop", sortDir: false },
    { sortBy: "supplemental_essay_workshop", sortDir: false },
    { sortBy: "fafsa_css_profile_workshop", sortDir: false },
    { sortBy: "senior_scholarship_bootcamp", sortDir: false },
    { sortBy: "college_career_transition_workshop", sortDir: false },
  ]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [isSorting, setIsSorting] = useState(true);
  const { handleStoreChangeDashboard } = globalActions.dashboard;
  const [selectedField, setSelectedField] = useState("");
  const [selectedDir, setSelectedDir] = useState("DESC");
  const {
    counselorId,
    searchValue,
    gradYear,
    daysSinceLastMeeting,
    nextBookedWorkshopName,
    nextBookedWorkshopDate,
    programSelect,
    programNames,
  } = globalState.dashboardFilters;

  useEffect(() => {
    setLoading(true);

    handleStoreChangeDashboard("pagination", {
      page: page,
      amountPerPage: pageSize,
      next: pagination.next,
      prev: pagination.prev,
      totalPages: pagination.pages,
      totalData: pagination.count,
    });
    updateTableData(counselor_list);
    setLoading(false);
  }, []);

  const columns = React.useMemo(
    () => (isWorkshops ? workshops() : tasks()),
    []
  );

  const tableSection = isWorkshops ? "workshops" : "tasks";

  const handleFilteredStates = () => {
    setLoading(!loading);
    setIsFiltering(!isFiltering);
  };

  const updateTableData = (data) => {
    setTableData(data);
  };

  const tableInstance = useTable({ columns, data: tableData }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const handleFilteredData = (data, filterType) => {
    setFilterType(filterType);
    updateTableData(data);
  };

  const updateSorting = (sorting, field) => {
    return sorting.map((item) => {
      if (item.sortBy === field) {
        return { sortBy: field, sortDir: !item.sortDir };
      } else {
        return item;
      }
    });
  };

  const handleSortBy = (field) => {
    const updatedSort = updateSorting(sorting, field);

    setSorting(updatedSort);

    const selectedField = field;
    const selectedDir = updatedSort.find((item) => item.sortBy === field)
      ?.sortDir
      ? "DESC"
      : "ASC";
    handleStoreChangeDashboard("sort", {
      sortDir: selectedDir,
      sortByGI: selectedField,
      sortByTask: sort.sortByTask,
    });
    filterData(field, selectedDir);
  };

  const build_filters = () => {
    return {
      filter_by_counselor: counselorId,
      search_by_text: searchValue,
      filter_by_grad_year: gradYear,
      filter_by_last_meeting: daysSinceLastMeeting,
      filter_by_next_booked_workshop_name: nextBookedWorkshopName,
      filter_by_next_booked_workshop_date: nextBookedWorkshopDate,
      filter_by_program: programSelect,
      filter_by_programs: programNames,
    };
  };

  const filterData = (selectedF, selectedD) => {
    let section = isWorkshops ? "workshops" : "tasks";
    setLoading(true);
    globalActions.dashboard
      .filterDataBy({
        ...build_filters(),
        section: section,
        page: pagination.page,
        per_page: pagination.amountPerPage,
        sort_by: selectedF,
        sort_dir: selectedD,
      })
      .then((response) => {
        handleFilteredData(response.data.records, "");
        setPageNumber(page);
        setPage(page);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const message = emptyMessage(filterType, filteredData);

  return (
    <>
      <Row className="margin-top">
        <Col md={2} lg={2} xl={2}>
          <div className="date-filters-general-info">
            <ClearFiltersSection
              handleFilteredStates={handleFilteredStates}
              handleFilteredData={handleFilteredData}
              section={tableSection}
              employee={employee}
            />
            <hr className="left-menu-divider" />
          </div>
        </Col>
        <Col md={12} lg={12} className="column-top-less">
          <div style={{ display: "flex", alignItems: "center" }}>
            <FilterAndSearch
              handleFilteredData={handleFilteredData}
              counselor_list={counselor_list}
              section={tableSection}
              grad_year_list={grad_year_list}
              program_list={program_list}
              employee={employee}
              setLoading={setLoading}
            />
          </div>
          <div className="table-container">
            <table {...getTableProps()} className="table table-dashboard">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="table-header-tasks"
                      >
                        {column.render("Header")}
                        <span className="tasks-icon-arrows-sorts">
                          <FontAwesomeIcon
                            onClick={() => handleSortBy(column.id)}
                            icon={
                              updateSorting(sorting, column.id).find(
                                (item) => item.sortBy === column.id
                              )?.sortDir
                                ? faSortAmountDownAlt
                                : faSortAmountUpAlt
                            }
                          />
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {!loading && (
                <tbody {...getTableBodyProps()} className="tasks-body-style">
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {!loading && pagination.totalData === 0 && message && (
              <div className="tasks-dashboard-title message">{message}</div>
            )}
            {loading && <div className="loading">Loading...</div>}
            <div className="pagination-container">
              {renderPageButtons(
                pageNumber,
                setPageNumber,
                listData,
                pageSize,
                setPageSize,
                globalActions,
                pagination,
                globalState,
                handleFilteredData,
                handleFilteredStates,
                tableSection,
                selectedField,
                selectedDir,
                setLoading
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default TasksTable;
