import React from "react";
import "../../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassHalf, faTimes } from "@fortawesome/free-solid-svg-icons";
const validate_status = (value) => {
  if (value && value.status) {
    switch (value.status) {
      case "Completed":
        return (
          <img src={"/images/iconos_dashboard/completed.png"} alt="Completed" />
        );
      case "In progress":
        return (
          <img
            src={"/images/iconos_dashboard/in_progress.png"}
            alt="In progress"
          />
        );
      case "Not started":
        return (
          <img
            src={"/images/iconos_dashboard/not_started.png"}
            alt="Not started"
          />
        );
      case "Registered":
        return (
          <img
            src={"/images/iconos_dashboard/registered.png"}
            alt="Registered"
          />
        );
      case "Opted out":
        return (
          <img src={"/images/iconos_dashboard/opted_out.png"} alt="Opted out" />
        );
      default:
        return (
          <img src={"/images/iconos_dashboard/opted_out.png"} alt="Opted out" />
        );
    }
  } else {
    return (
      <img src={"/images/iconos_dashboard/opted_out.png"} alt="Opted out" />
    );
  }
};

const tasks = () => [
  {
    Header: "Student",
    accessor: "student_name",
    id: "student_name",
    Cell: ({ row }) => {
      return (
        <div className="student-cell">
          <a
            href={`/backend/students/${row.original.student_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.values.student_name}
          </a>
          <div className="info-container">
            {row.original.on_hold && (
              <p>
                <FontAwesomeIcon
                  icon={faHourglassHalf}
                  style={{ color: "595959" }}
                  className="on-hold-icon"
                />
              </p>
            )}
            {row.original.suspended && (
              <p>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ color: "red" }}
                  className="on-hold-icon"
                />
              </p>
            )}
          </div>
        </div>
      );
    },
  },
  {
    Header: "Complete YouScience Assessment",
    accessor: "complete_youscience_assessment",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Review S.A.F.E College Preferences",
    accessor: "review_s_a_f_e_college_preferences",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Create College Research Plan",
    accessor: "create_college_research_plan",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Review High School Course Selection",
    accessor: "review_high_school_course_selection",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Develop Test Prep Strategy",
    accessor: "develop_test_prep_strategy",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Private Budget Meeting",
    accessor: "private_budget_meeting",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Develop Preliminary College List",
    accessor: "develop_preliminary_college_list",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Establish Recommendation Letter Strategy",
    accessor: "establish_recommendation_letter_strategy",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Develop Resume",
    accessor: "develop_resume",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Define Scholarship Plan",
    accessor: "define_scholarship_plan",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Refine College List",
    accessor: "refine_college_list",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Common App Personal Statement Topic Identified",
    accessor: "common_app_personal_statement_topic_identified",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Supplemental Essay Strategy",
    accessor: "supplemental_essay_strategy",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Complete My Common App Personal Statement",
    accessor: "complete_my_common_app_personal_statement",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Set Application Strategy",
    accessor: "set_application_strategy",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "1-1 Application Review",
    accessor: "1_1_application_review",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "All Essays Reviewed",
    accessor: "all_essays_reviewed",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Mock College Interview Meeting",
    accessor: "mock_college_interview_meeting",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "Award Assessment & Decision Meeting",
    accessor: "award_assessment_decision_meeting",
    Cell: ({ value }) => validate_status(value),
  },
];

export { tasks };
