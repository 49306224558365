import React, { useState, useEffect } from "react"
import useGlobal from "../../../hooks/store"
import AdminDropdownMenu from "./AdminDropdownMenu"
import { ClipLoader } from "react-spinners"
import { Col, Row } from "reactstrap"

const CollegeMenu = ({ collegeId, priorityType, adminImpersonating, arrayCollegesType, arrayCollegePriority, isInMyColleges, collegeIsInList, setCurrentPriorityTypeGeneralInformation, hideCategory }) => {
  const [globalState, globalActions] = useGlobal()
  const { currentIdCollegeRequest, requesting } = globalState.student
  const [priorityLocalType, setPriorityLocalType] = useState(priorityType)

  const handleMenuAction = (adminImpersonating, collegeId, collegeIsInList) => {
    if (adminImpersonating) {
      let dropdown = ".dropdown-categories-" + collegeId
      $(dropdown).toggle()
    } else if (!adminImpersonating && isInMyColleges) {
      globalActions.student.removeCollegeFromList(collegeId)
    } else if (!adminImpersonating && (!collegeIsInList || !isInMyColleges)) {
      globalActions.student.addCollegeToList("my_colleges", collegeId)
    }
  }

  return (
    <ul>
      <li className="dropdown floating-options-college" data-options={collegeId}>
        <Row className="college-menu-row">
          <Col>{
            requesting && currentIdCollegeRequest === collegeId
              ?
              <ClipLoader size={18} color={"#56468F"} />
              :
              <a className={(isInMyColleges) ? "disabled" : "enable"} style={{ cursor: "pointer" }} onClick={() => handleMenuAction(adminImpersonating, collegeId, collegeIsInList)}>
                <i className="icon-fa-plus-bars font-size--large"></i>
              </a>
          }</Col>
          <Col className="college-menu-col-category">
            {hideCategory ? <></> : <small className="font--college-list capital-letter">{priorityLocalType}</small>}
          </Col>
        </Row>
        {
          (adminImpersonating) &&
          <AdminDropdownMenu
            collegeId={collegeId}
            arrayCollegesType={arrayCollegesType}
            arrayCollegePriority={arrayCollegePriority}
            priorityType={priorityType}
            adminImpersonating={adminImpersonating}
            collegeIsInList={collegeIsInList}
            setPriorityLocalType={setPriorityLocalType}
            setCurrentPriorityTypeGeneralInformation={setCurrentPriorityTypeGeneralInformation}
          />
        }
      </li>
    </ul>
  )
}

export default CollegeMenu
