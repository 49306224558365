import React from 'react';
const assetsBaseUrl = '/assets/';

const defaultImageName = 'pfa-student-photo-a2398e01678173957539d6a7cdb1580fdf87cc245137341f5c54b02e5b3f06f1.jpg';

const MyTeam = ({ activeTeamMembers }) => {
  
  return (
    <div className='card_student_dashboard'>
      <div className="card__header_sidebar">
        <h2 className='title_student_dashboard'>
          Team
        </h2>
      </div>
      <div>
        <ul className='clean-margenes'>
          {activeTeamMembers.length > 0 ? (
            activeTeamMembers.map((member) => (

              <li key={member.id} className="team-member-wrapper-row">
                <div className='image-container-team-member'>
                  <img
                    src={member.employee_avatar_url ? member.employee_avatar_url : `${assetsBaseUrl}${defaultImageName}`}
                    alt='Employee Profile Photo'
                    className='round-img-team-member'
                  />
                </div>
                <div className='div-student-dashboard'>
                  <div className='team-member-info'>
                    {member.employee_name ? (
                       <>
                        <h3 className="subtitle_student_dashboard">
                          {member.employee_name}
                        </h3>
                        <h5 className='clean-margenes'>
                          {member.role_name}
                        </h5>
                      </>
                    ) : (
                      'Employee no longer exists'
                    )}

                   
                  </div>

                  <div className='team-member-links'>
                    {member.video_link && (
                      <h6>
                        <a href={member.video_link} target="_blank" rel="noopener noreferrer">Zoom</a>
                      </h6>
                    )}
                    <h6 className="primary">
                      {member.employee_email ? (
                        <a href={`mailto:${member.employee_email}`}>Email</a>
                      ) : (
                        'Employee no longer exists'
                      )}
                    </h6>
                    {member.scheduling_link && (
                      <h6>
                        <a href={member.scheduling_link} target="_blank" rel="noopener noreferrer">Book a Meeting</a>
                      </h6>
                    )}
                  </div>
                </div>
              </li>
            ))
          ) : (
            
            <div className='team-member-info'>
              No members have been associated
            </div>
           
          )}
        </ul>
      </div>
    </div>
  );
};

export default MyTeam;
