import React, { useState, useEffect } from "react";
import useGlobal from "../../../hooks/store";
import Select from "react-select";
import "../../../../assets/stylesheets/backend/milestone_report/milestone_report.scss";

const Filters = ({ program_list, filterData }) => {
  const [globalState, globalActions] = useGlobal();
  let { programNames } = globalState.healthyContactFilters;
  const { handleStoreChange } = globalActions.healthyContactFilters;

  const filterDataParams = (commonFilters) => commonFilters;

  const filterParams = filterDataParams({
    filter_by_programs: programNames,
  });

  const setDropdownProgram = (selectedPrograms) => {
    const programs = selectedPrograms.map((option) => option.value);
    handleStoreChange("programNames", programs);
    filterData(
      filterDataParams({ filter_by_programs: programs }),
      "programNames"
    );
  };

  useEffect(() => {
    if (!programNames || programNames.length === 0) {
      const defaultProgramNames = [
        "College Admissions Program",
        "College Admissions Program Elite",
        "College Admissions Select",
      ];
      handleStoreChange("programNames", defaultProgramNames);
      filterData(
        filterDataParams({ filter_by_programs: defaultProgramNames }),
        "programNames"
      );
    }
  }, []);

  return (
    <>
      <div className="align-filter">
        <span className="dashboard-title-filters">Program:</span>
        <Select
          className="grad-year-filter-select"
          isMulti
          value={program_list.reduce((accumulator, program) => {
            if (programNames.includes(program.program_name)) {
              accumulator.push({
                value: program.program_name,
                label: program.program_name,
              });
            }
            return accumulator;
          }, [])}
          options={program_list.map((program) => ({
            value: program.program_name,
            label: program.program_name,
          }))}
          onChange={setDropdownProgram}
          placeholder="Select a Program"
        />
      </div>
    </>
  );
};

export { Filters };
