import React, { useState } from "react";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import useGlobal from "../../../hooks/store";

const DatesFilterGeneralInformation = ({
  handleFilteredData,
  section,
  handleFilteredStates,
}) => {
  const [globalState, globalActions] = useGlobal();
  const { handleStoreChange } = globalActions.dashboardFilters;
  const { handleStoreChangeDashboard, sortBySection } = globalActions.dashboard;
  const {
    searchValue,
    gradYear,
    counselorId,
    daysSinceLastMeeting,
    nextContactDate,
    nextMeetingDate,
    nextBookedWorkshopName,
    nextBookedWorkshopDate,
    programSelect,
  } = globalState.dashboardFilters;

  const { pagination, sort } = globalState.dashboard;

  const days = [30, 60, 75, 90, 120, 180];
  const buttonValues = [days.slice(0, 3), days.slice(3)];

  const handleClick = (value) => {
    if (value === globalState.dashboardFilters.daysSinceLastMeeting) {
      handleStoreChange("daysSinceLastMeeting", "");
      filterData(
        build_filters({ filter_by_last_meeting: "" }),
        "daysSinceLastMeeting"
      );
    } else {
      handleStoreChange("daysSinceLastMeeting", value);
      filterData(
        build_filters({ filter_by_last_meeting: value }),
        "daysSinceLastMeeting"
      );
    }
  };

  const build_filters = (new_filters) => {
    return {
      ...new_filters,
      filter_by_counselor: counselorId,
      search_by_text: searchValue,
      filter_by_grad_year: gradYear,
      filter_by_next_contact_date: nextContactDate,
      filter_by_next_meeting: nextMeetingDate,
      filter_by_next_booked_workshop_name: nextBookedWorkshopName,
      filter_by_next_booked_workshop_date: nextBookedWorkshopDate,
      filter_by_program: programSelect,
    };
  };

  const filterData = (options, filterType) => {
    globalActions.dashboard
      .filterDataBy({
        ...options,
        section: section,
        page: pagination.page,
        per_page: pagination.amountPerPage,
        sort_by: sortBySection(section, sort),
        sort_dir: sort.sortDir,
      })
      .then((response) => {
        handleFilteredData(response.data.records, filterType);
        handleStoreChangeDashboard("pagination", {
          page: response.data.pagy.page,
          amountPerPage: pagination.amountPerPage,
          next: response.data.pagy.next,
          prev: response.data.pagy.prev,
          totalPages: response.data.pagy.pages,
          totalData: response.data.pagy.count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createButtonMatrix = () => {
    return (
      <div className="button-matrix-container left-menu-button-dates">
        <h6 className="date-title-filters">Days since Last Meeting </h6>
        {buttonValues.map((rowValues, rowIndex) => (
          <div key={rowIndex} className="button-row">
            {rowValues.map((value, columnIndex) => (
              <button
                key={columnIndex}
                onClick={() => handleClick(value)}
                className={`custom-button ${
                  value === globalState.dashboardFilters.daysSinceLastMeeting
                    ? "date-filter-selected"
                    : ""
                }`}
              >
                {`+${value}`}
              </button>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return <div>{createButtonMatrix()}</div>;
};

export default DatesFilterGeneralInformation;
