import React from "react"
import {
  Col,
  Row,
} from "reactstrap"
import useGlobal from "../../../hooks/store/index"

const SuggestionsCompare = ({ value, showSuggestions, suggestions }) => {
  const [globalState, globalActions] = useGlobal()
  const { collegesToCompare } = globalState.student

  const addCollegeToCompareList = (college) => {
    if(collegesToCompare.length < 6) {
      globalActions.student.addCollegeToCompareList(college);
    } else {
      alert("You have reached the maximun amount of colleges allowed!!");
    }
  }

  return (
    <Row>
        {
          !!value &&
          value.length >= 3 &&
          showSuggestions &&
          suggestions.length > 0 &&
          <div className="d-flex">
            <ul className="suggestions-container">
              {
                suggestions.map((college, index) => {
                  return (
                    <li key={index} className="option" onMouseDown={() => (addCollegeToCompareList(college))}>
                      <div>
                        <span>{college.name}</span>
                        <br />
                        <span>
                          {
                            !!college.details &&
                            !!college.details.city &&
                            !!college.details.state &&
                            college.details.city + ", " + college.details.state
                            
                          }
                        </span>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        }
    </Row>
  )
}
export default SuggestionsCompare
