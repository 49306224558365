import React from "react"
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(fas);
dom.watch();

const FilterLine = ({ title, tooltipMessage, selectedOptions, filter, setFilter }) => {
  return (
    <li className='filter--element-label' onClick = {() => setFilter(filter)}>
      <div className="profile-completition-message purple-tooltip">
      <span className="filter-name">{title}</span>
        {
          !!tooltipMessage &&
          <div
            data-toggle="tooltip"
            data-placement="top"
            title={tooltipMessage}

          >
            <FontAwesomeIcon icon="info-circle" size="lg" className="filter--information-icons"/>
          </div>
        }
      </div>
      <div className="filter--element-description ">
        {
          selectedOptions.map((option, index) => {
            return (
              <span key={index}>{option}{selectedOptions.length > index + 1 ? ", " : ""}</span>
            )
          })
        }
      </div>
      <span>{">"}</span>
    </li>
  )
}
export default FilterLine
