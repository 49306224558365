import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import useGlobal from "../../../hooks/store";

export const renderPageButtons = (
  pageNumber,
  setPageNumber,
  data,
  pageSize,
  setPageSize,
  globalAction,
  paginationData,
  globalStat,
  handleFilteredData,
  handleFilteredStates,
  section,
  field,
  dir,
  setLoading
) => {
  const [globalState, globalActions] = useGlobal();
  const { pagination, sort } = globalState.dashboard;
  pageNumber = pageNumber - 1;
  const { handleStoreChangeDashboard } = globalActions.dashboard;
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [page, setPage] = useState(1);
  const previousToLastPage = pagination.totalPages - 1;
  const visibleElements = 5;
  const [perPage, setPerPage] = useState(pagination.amountPerPage);
  let buttons = [];
  const prevDisabled = !pagination.prev;
  const nextDisabled = !pagination.next;

  const getNextPage = pagination.next;
  const getPreviousPage = pagination.prev;

  let firstPage = 0;
  let lastPage = pagination.totalPages;
  const options = [20, 40, 80, 160];

  const {
    counselorId,
    searchValue,
    gradYear,
    daysSinceLastMeeting,
    nextBookedWorkshopName,
    nextBookedWorkshopDate,
    programNames,
  } = globalState.dashboardFilters;

  const build_filters = () => {
    return {
      filter_by_counselor: counselorId,
      search_by_text: searchValue,
      filter_by_grad_year: gradYear,
      filter_by_last_meeting: daysSinceLastMeeting,
      filter_by_next_booked_workshop_name: nextBookedWorkshopName,
      filter_by_next_booked_workshop_date: nextBookedWorkshopDate,
      filter_by_programs: programNames,
    };
  };

  const filterData = (page) => {
    setLoading(true);
    globalActions.dashboard
      .filterDataBy({
        ...build_filters(),
        section: section,
        page: page,
        per_page: perPage,
        sort_by: sort.sortBy,
        sort_dir: sort.sortDir,
      })
      .then((response) => {
        const totalPages = response.data.pagy.pages;
        const newPage = page > totalPages ? totalPages : page;

        handleStoreChangeDashboard("pagination", {
          page: newPage,
          amountPerPage: response.data.pagy.items,
          next: response.data.pagy.next,
          prev: response.data.pagy.prev,
          totalPages: response.data.pagy.pages,
          totalData: response.data.pagy.count,
        });

        handleFilteredData(response.data.records, "");
        setPageNumber(newPage);
        setPage(newPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTasksPerPageChange = (event) => {
    const newPageSize = parseInt(event.target.value);
    setPageSize(newPageSize);
    setPerPage(newPageSize);
  };

  useEffect(() => {
    if (!isInitialLoad) {
      handleStoreChangeDashboard("pagination", {
        page: page,
        amountPerPage: perPage,
        next: pagination.next,
        prev: pagination.prev,
        totalPages: pagination.totalPages,
        totalData: pagination.totalData,
      });
      filterData(page);
    } else {
      setIsInitialLoad(false);
    }
  }, [perPage, page]);

  if (pagination.totalPages <= visibleElements) {
    firstPage = 0;
    lastPage = previousToLastPage;
  } else {
    if (pageNumber < Math.floor(visibleElements / 2)) {
      firstPage = 0;
      lastPage = visibleElements - 2;
    } else if (
      pageNumber >=
      previousToLastPage - Math.floor(visibleElements / 2)
    ) {
      firstPage = previousToLastPage - (visibleElements - 2);
      lastPage = previousToLastPage;
    } else {
      firstPage = pageNumber - Math.floor(visibleElements / 2) + 1;
      lastPage = pageNumber + Math.floor(visibleElements / 2) - 1;
    }
  }

  if (pagination.totalPages > 0) {
    if (firstPage > 0) {
      buttons.push(
        <button
          key={0}
          onClick={() => filterData(1)}
          className={`pagination-button`}
        >
          1
        </button>
      );
    }
    if (firstPage > 1) {
      buttons.push(
        <span key={"dots1"} className="pagination-dots">
          ...
        </span>
      );
    }
  }

  for (let i = firstPage; i <= lastPage; i++) {
    const isActive = i === pageNumber;
    buttons.push(
      <button
        key={i}
        onClick={() => filterData(i + 1)}
        className={`pagination-button ${isActive ? "active" : ""}`}
      >
        {i + 1}
      </button>
    );
  }

  if (lastPage < previousToLastPage) {
    if (lastPage < previousToLastPage - 1) {
      buttons.push(
        <span key={"dots2"} className="pagination-dots">
          ...
        </span>
      );
    }
    buttons.push(
      <button
        key={previousToLastPage}
        onClick={() => filterData(previousToLastPage + 1)}
        className={`pagination-button`}
      >
        {pagination.totalPages}
      </button>
    );
  }

  buttons.unshift(
    <button
      key={"prev"}
      onClick={() => filterData(getPreviousPage)}
      className={`pagination-button ${prevDisabled ? "disabled" : ""}`}
      disabled={prevDisabled}
    >
      <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#a9a9a9b9" }} />
    </button>
  );

  buttons.push(
    <button
      key={"next"}
      onClick={() => filterData(getNextPage)}
      className={`pagination-button ${nextDisabled ? "disabled" : ""}`}
      disabled={nextDisabled}
    >
      <FontAwesomeIcon icon={faChevronRight} style={{ color: "#a9a9a9b9" }} />
    </button>
  );

  return (
    <div className="container-div">
      <div>{buttons}</div>
      <div className="tasks-per-page-section">
        <label>Students per page:</label>
        <select value={pageSize} onChange={handleTasksPerPageChange}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <label className="spaces">Total results</label>
        <label>{pagination.totalData}</label>
      </div>
    </div>
  );
};
