import React, { useEffect, useState } from "react";
import CounselorPerformanceReportTable from "./CounselorPerformanceReportTable";
import { counselorperformancecolumns } from "./table_columns/counselor_performance_columns";
import useGlobal from "../../../hooks/store";
import "../../../../assets/stylesheets/backend/milestone_report/milestone_report.scss";
import FormulaNote from "./FormulaNote";
import { Filters } from "./Filters";

function CounselorPerformanceReport({ grad_year_list, program_list }) {
  const [loading, setLoading] = useState(true);
  const [dataReport, setDataReport] = useState([]);
  const [globalState, globalActions] = useGlobal();
  const { handleStoreChange } = globalActions.counselorPerformanceFilters;
  const { gradYear, programNames } = globalState.counselorPerformanceFilters;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filterParams = filterDataParams({
          filter_by_grad_year: gradYear || "",
          filter_by_programs: programNames || [],
        });
        filterData(filterParams, "");
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filterDataParams = (commonFilters) => commonFilters;

  const filterData = (options, filterType) => {
    globalActions.counselorPerformanceFilters
      .filterDataBy({ ...options })
      .then((response) => {
        setDataReport(response.data);
        handleStoreChange("filterType", filterType);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <h1 className="report-title">Counselor Performance Report</h1>
      <Filters
        grad_year_list={grad_year_list}
        program_list={program_list}
        filterData={filterData}
      />
      {loading && <div className="loading">Loading...</div>}
      <CounselorPerformanceReportTable
        counselorperformancecolumns={counselorperformancecolumns}
        data={dataReport}
        loading={loading}
      />
      <FormulaNote />
    </div>
  );
}

export default CounselorPerformanceReport;
