import React, { useEffect, useState } from 'react';
import TutorReportTable from './TutorReportTable';
import { tutorreportcolumns } from "./table_columns/tutor_report_columns";
import useGlobal from "../../../hooks/store";
import "../../../../assets/stylesheets/backend/milestone_report/milestone_report.scss";
import FormulaNote  from "./FormulaNote";

function TutorReport() {
    const [loading, setLoading] = useState(true);
    const [dataReport, setDataReport] = useState([]);
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        const fetchData = async () => {
            try {
                filterData();
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const filterData = () => {
        globalActions.tutorReportFilters
            .filterDataBy()
            .then((response) => {
                setDataReport(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <div>
          <h1 className="report-title">Tutor Performance Report</h1>
          {loading && <div className="loading">Loading...</div>}
            <TutorReportTable
                tutorreportcolumns={tutorreportcolumns}
                data={dataReport}
                loading={loading}
            />
            <FormulaNote />
        </div>
    );      
}
export default TutorReport;