import React from "react";
import { format } from "date-fns";
import ReactTooltip from "react-tooltip";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { convertToUserTimeZone } from "../../helpers/userTimeZone";

const TaskRow = ({
  id,
  name,
  dueDate,
  link,
  rescheduleLink,
  cancelLink,
  type,
  tasktype,
  conversationLog,
  conversationLogSubject,
  conversationLogHour,
  status,
  isHistory,
}) => {
  let conversationLogView = "";
  let availableLink = "";

  function extractName(text) {
    const dashIndex = text.indexOf("-");
    const colonIndex = text.indexOf(":");

    const separatorIndex = Math.min(
      dashIndex !== -1 ? dashIndex : Infinity,
      colonIndex !== -1 ? colonIndex : Infinity
    );

    return separatorIndex !== -1
      ? text.slice(0, separatorIndex).trim()
      : text.trim();
  }

  if (name == tasktype) {
    tasktype = "";
  }

  if (type === "YCBM") {
    const createLink = (href, text, disabled) => {
      if (href === "" || href === undefined || disabled) {
        return (
          <>
            <ReactTooltip
              className="button-like-link"
              id={`tooltip-${text}`}
              effect="solid"
            >
              Invalid {text.toLowerCase()} link
            </ReactTooltip>
            <button
              className="button-like-link"
              disabled={true}
              data-tip
              data-for={`tooltip-${text}`}
            >
              {text}
            </button>
          </>
        );
      } else {
        return (
          <a href={href} target="_blank" className="button-like-link">
            {text}
          </a>
        );
      }
    };

    if (link !== "" && link !== undefined && isHistory === true) {
      name = (
        <a href={link} target="_blank">
          {name} - {extractName(tasktype)}
        </a>
      );
    }

    if (isHistory === false) {
      name = (
        <div>
          {name} - {extractName(tasktype)}
        </div>
      );
    }

    const rescheduleAvailable =
      rescheduleLink && rescheduleLink !== "http://example.com/reschedule";
    const rescheduleText = "Reschedule";
    availableLink = createLink(
      rescheduleLink,
      rescheduleText,
      !rescheduleAvailable
    );

    const cancelAvailable =
      cancelLink && cancelLink !== "http://example.com/cancel";
    const cancelText = "Cancel";
    const cancelLinkComponent = createLink(
      cancelLink,
      cancelText,
      !cancelAvailable
    );

    if (cancelLinkComponent) {
      availableLink = (
        <>
          {availableLink}
          <span> | </span>
          {cancelLinkComponent}
        </>
      );
    }
  }

  if (conversationLog && conversationLog !== "" && status) {
    conversationLogView = (
      <span style={{ margin: 0 }}>
        <ReactTooltip
          id={`note-tooltip-${id}`}
          place="right"
          effect="solid"
          html={true}
          className="text-left"
          getContent={() => {
            let content =
              "<div>" +
              "<span style='font-size: 1.2em; font-weight: bold'>" +
              conversationLogSubject +
              "</span>&nbsp;&nbsp;";

            if (parseFloat(conversationLogHour) > 0) {
              content +=
                "<span style='font-size: 0.8em'>  " +
                conversationLogHour +
                "h</span>";
            }

            if (
              conversationLog.body !== null &&
              conversationLog.body !== "null"
            ) {
              content +=
                "</div>" +
                "<div style='font-size: 1em'>" +
                conversationLog.body +
                "</div>";
            }
            return content;
          }}
        />
        <img
          data-tip
          data-for={`note-tooltip-${id}`}
          className="student_dashboard_icon"
          src={"/images/eye.png"}
          alt="See conversation log"
          style={{ display: "block" }}
        />
      </span>
    );
  }

  if (link != undefined && link != "") {
    if (type == "TASK") {
      if (tasktype == "Workshop" && !isHistory) {
        availableLink = (
          <a href={link} target="_blank">
            Register
          </a>
        );
      } else if (tasktype == "Student Activity" || tasktype == "1-1 Meeting") {
        availableLink = (
          <a href={link} target="_blank">
            Access
          </a>
        );
      }
    }
  }
  const formatDate = (dueDat, type) => {
    if (dueDat == undefined || dueDat == "" || dueDat == null) {
      return "";
    }

    let dueDate;
    try {
      if (type == "TASK") {
        const parts = dueDat.split("-");
        dueDate = new Date(parts[0], parts[1] - 1, parts[2]);
      } else {
        dueDate = new Date(dueDat);
      }
    } catch (error) {
      return "Invalid date";
    }

    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = dueDate.toLocaleDateString("en-US", options);
    const currentDate = new Date();

    if (dueDate < currentDate && type !== "CLOG" && !isHistory) {
      return "Now";
    } else {
      if (type === "YCBM") {
        const formattedDate = formatUserDate(dueDate);
        return formattedDate;
      } else {
        return formattedDate;
      }
    }
  };

  const formatUserDate = (date) => {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    };
    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };

  const getSeason = (dueDate) => {
    const date = new Date(dueDate);
    const month = date.getMonth();
    if (month >= 2 && month <= 4) {
      return "Spring";
    } else if (month >= 5 && month <= 7) {
      return "Summer";
    } else if (month >= 8 && month <= 10) {
      return "Fall";
    } else {
      return "Winter";
    }
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator className="time_separator">
          <TimelineConnector className="timeline_connector" />
          <TimelineDot></TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent
          sx={{ py: "12px", px: 2 }}
          className="size_card_dashboard"
        >
          <div
            className={`row_card_student_dashboard ${
              type === "YCBM" || type === "CLOG"
                ? "blue_background"
                : "yellow_background"
            }`}
          >
            <div className="row_left_div">
              {type === "CLOG" ? (
                <div className="name_status_container ">
                  <h3 className="subtitle_student_dashboard">{tasktype}</h3>
                  <h4 className="row_text_student_dashboard">
                    {formatDate(dueDate, type)}
                  </h4>
                </div>
              ) : (
                <>
                  <div className="name_status_container">
                    <div className="name_status_container_status">
                      <h3 className="subtitle_student_dashboard">{name}</h3>
                      {tasktype !== "" && (
                        <>
                          <h4 className="row_text_student_dashboard">
                            {tasktype}
                          </h4>
                        </>
                      )}
                    </div>
                    <div className="name_status_container">
                      <h4 className="row_text_student_dashboard">
                        {formatDate(dueDate, type)}
                      </h4>
                      <h4 className="row_status_student_dashboard">{status}</h4>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row_right_div">
              {conversationLogView && conversationLogView}
              {availableLink && (
                <p className="row_text_student_dashboard">{availableLink}</p>
              )}
            </div>
          </div>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default TaskRow;
