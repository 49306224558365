import React from "react"
import useGlobal from "../../../../hooks/store"
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(fas);
dom.watch();

const StateMenu = ({ allStates, currentStates, setStates, setFilter }) => {
  const [globalState, globalActions] = useGlobal()
  const { currentPage } = globalState.college

  const addState = (state) => {
    let statePosition = currentStates.indexOf(state)

    if (state === "AllStates") {
      currentStates = []
      currentStates.push(state)
    } else {
      if(statePosition == -1) {
        currentStates = currentStates.filter((i) => i !== "AllStates")
        currentStates.push(state)
      } else {
        currentStates.splice(statePosition, 1)

      }
    }
    setStates(currentStates)
  }

  const filter = () => {
    globalActions.college.handleStoreChange("currentPage", 1)
    globalActions.college.handleStoreChange("searchBy", "filter")
    globalActions.college.filterColleges(currentPage)
    $(".dropdown-options").toggle()
  }

  return (
    <div>
      <div className="navigation-options-container__margin">
        <div className="profile-completition-message">
          <div className='filter-done-btn filter-done-btn filter--done-button col-xs-4 col-sm-4 col-md-4 col-lg-4'>
            <span onClick={() => setFilter("")}>{"< Filters"}</span>
          </div>
          <div className='filter--title col-xs-4 col-sm-4 col-md-4 col-lg-4'></div>
          <div className='filter-done-btn filter--done-button col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding'>
            <span onClick={() => filter()}>Search</span>
          </div>
        </div>
      </div>
      <hr className='title-separator' />
      <li className='filter--element-label'>Choose State(s)</li>
      <hr className="subtitle-separator" />
      <li className='filter--element-label' onClick={() => addState("AllStates")}>
        All States
        {
          currentStates.indexOf("AllStates") !== -1 &&
          <FontAwesomeIcon icon="check" />
        }
      </li>
      
      {
        allStates.map((state, index) => (
          <li key={index} className='element-separator filter--element-label' onClick={() => addState(state)}>
            <span>{state}</span>
            <span>
              {
                currentStates.indexOf(state) !== -1 &&
                <FontAwesomeIcon icon="check" />
              }
            </span>
          </li>
        ))
      }
    </div>
  )
}
export default StateMenu
