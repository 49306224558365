import React from 'react';

const Badge = ({ text, color }) => {
  const badgeStyle = {
    display: 'inline-flex',
    alignItems: 'center', 
    justifyContent: 'center',
    padding: '0.5em 1em 0.5em 1em',
    borderRadius: '100px',
    backgroundColor: color,
    color: 'black',
    fontSize: '1em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontFamily: "HelveticaNeue",
    height: "40px",
    width: "40px"
  };

  return <span style={badgeStyle}>{text}</span>;
};

export default Badge;
