import axios from "axios";
import { generatePath } from "react-router";
import { FILTERCOUNSELORPERFORMANCEDATA } from "../apiConstants";

export const filterDataBy = (store, params) => {
  const hostUrl = generatePath(FILTERCOUNSELORPERFORMANCEDATA, {
    filter_data_params: JSON.stringify(params),
  });

  return axios.get(hostUrl, {
    headers: {
      Accept: "application/json",
    },
  });
};

export const handleStoreChange = (store, key, value) => {
  store.setState({
    counselorPerformanceFilters: {
      ...store.state.counselorPerformanceFilters,
      [key]: value,
    },
  });
};
