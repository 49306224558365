import { shortTimeOnly, shortDayOnly } from "../helpers/timeFormat"

export const agendaDateTime = (event) => {
  let date = ""
  let time = ""

  if (event.titleize_type === "Meeting" || event.titleize_type === "Activity") {
    date = `${shortDayOnly(event.start)} - ${shortDayOnly(event.end)}`
    time = `${shortTimeOnly(event.start)} - ${shortTimeOnly(event.end)}`
  } else if (event.titleize_type === "Milestone") {
    date = `${shortDayOnly(event.start)} - ${shortDayOnly(event.end)}`
  } else {
    date = shortDayOnly(event.start)
    time = shortTimeOnly(event.start)
  }

  return { date, time }
}
