import React from "react"
import {
  Row,
  Table,
} from "reactstrap"
import CollegeMenu from "./CollegeMenu"
import { generatePath } from "react-router"
import { COLLEGEPAGE } from "../../../hooks/apiConstants"
import DatePicker from "react-datepicker"
import CollegeTableColumns from "./CollegeTableColumns"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import CollegeTableRow from "./CollegeTableRow"
import update from 'immutability-helper'
import useGlobal from "../../../hooks/store"

const CollegeTable = ({ applicationMode, collegeDetails, currentList, setCurrentList, adminImpersonating, arrayCollegesType, arrayCollegePriority, forYouIds, myCollegesIds }) => {

  const [globalState, globalActions] = useGlobal()

  const onChangeDeadline = ({ target }, collegeId) => {
    setCurrentList([...currentList].map(object => {
      if (object.college.id === collegeId) {
        object.college.deadline = target.value
        return object
      }
      else return object;
    }))

    globalActions.student.updateCollegeDeadline(collegeId, target.value)
  }

  const validateDeadline = (college) => {
    switch (college.deadline) {
      case 'early_decision':
        return college.ed_deadline ? college.ed_deadline : 'n/a';
      case 'early_action':
        return college.ea_deadline ? college.ea_deadline : 'n/a';
      case 'regular_decision':
        return college.regular_admission_deadline ? college.regular_admission_deadline : 'n/a';
      default:
        return '';
    }
  }

  const onChangeTargetSubmissionDate = (date, collegeId) => {
    setCurrentList([...currentList].map(object => {
      if (object.college.id === collegeId) {
        object.college.target_submission = date
        return object
      }
      else return object;
    }))
    globalActions.student.addTargetSubmissionDateToCollege(date ? date.toString() : null, collegeId)
  }

  const electronicAPP = (college) => {
    const collegeDetail = collegeDetails.find((detail) => detail.id === college.id);

    if (collegeDetail?.application_methods?.length > 0) {
      return (
        <>
          {collegeDetail.application_methods.map((option, index) => (
            <div key={index}>
              <span>{option}</span>
              <br />
            </div>
          ))}
        </>
      );
    } else {
      return <span>n/a</span>;
    }
  };

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = currentList[dragIndex]

    setCurrentList(
      update(currentList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    )
  }

  const updateRankings = () => {
    currentList.forEach((obj, index) => {
      obj.college.ranking = index + 1;
    })
    let colleges_list = currentList.map(({ college }) => ({ id: college.id, ranking: college.ranking }))
    globalActions.student.updateCollegesRankings(colleges_list)
    setCurrentList(currentList)
  }

  const applicationModeOnData = () => {
    return currentList.map(({ college, added_by_person, added_by_person_image, priority_type }, index) => (
      <CollegeTableRow key={index} index={index} moveRow={moveRow} updateRankings={updateRankings}>
        <td className="college-name vertical-center-style">
          <Row>
            <a href={generatePath(COLLEGEPAGE, { id: college.id })}>{college.name}</a>
          </Row>
          {college.city_and_state && <Row>
            <span>{college.city_and_state}</span>
          </Row>}
        </td>
        <td className="college-list-data vertical-center-style">
          <select value={college.deadline} onChange={(e) => onChangeDeadline(e, college.id)} className="college-list-select">
            <option value="default">Choose Deadline</option>
            {college.ed_deadline && <option value="early_decision">Early Decision</option>}
            {college.ea_deadline && <option value="early_action">Early Action</option>}
            <option value="regular_decision">Regular Decision</option>
          </select>
          <Row>
            <span>{validateDeadline(college)}</span>
          </Row>
        </td>
        <td className="college-list-data vertical-center-style">
          <DatePicker
            className="college-list-target-date"
            placeholderText="MM/DD/YYYY"
            selected={Date.parse(college.target_submission)}
            onChange={(date) => onChangeTargetSubmissionDate(date, college.id)}
            isClearable />
        </td>
        <td className="college-list-data hidden-xs hidden-sm vertical-center-style">{college.acceptance_rate ? college.acceptance_rate + "%" : 'n/a'}</td>
        <td className="college-list-data hidden-xs hidden-sm vertical-center-style">{college.test_optional}</td>
        <td className="college-list-data hidden-xs hidden-sm hidden vertical-center-style">
          <Row>
            <span>Suppl. Essay: {college.written_essay}</span>
          </Row>
          <Row>
            <span>Teacher Rec: {college.letter_of_recom}</span>
          </Row>
          <Row>
            <span>Interview: {college.interview_app_deadline}</span>
          </Row>
        </td>
        <td className="college-list-data hidden-xs hidden-sm vertical-center-style">
          {electronicAPP(college)}
        </td>
        <td className="college-list-data vertical-center-style">
          <Row>
            <span>{college.regular_admission_deadline} (Regular)</span>
          </Row>
          <Row>
            <span>{college.ea_deadline} (Priority)</span>
          </Row>
        </td>
        <td className="college-list-data vertical-center-style">{
          forYouIds.indexOf(college.id) !== -1 ?
            (<div key={index} data-toggle="tooltip" data-placement="bottom" title={added_by_person}>
              <img
                src={added_by_person_image}
                width="230"
                height="230"
                className="round-img-hc person-photo--college-table"
              />
            </div>
            ) : (
              <span></span>
            )
        }</td>
        <td className="college-filter-button vertical-center-style">
          <CollegeMenu
            collegeId={college.id}
            adminImpersonating={adminImpersonating}
            priorityType={priority_type}
            arrayCollegesType={arrayCollegesType}
            arrayCollegePriority={arrayCollegePriority}
            isInMyColleges={myCollegesIds.indexOf(college.id) !== -1}
            collegeIsInList={true}
          />
        </td>
      </CollegeTableRow>
    ))
  }

  const applicationModeOffData = () => {
    return currentList.map(({ college, added_by_person, added_by_person_image, priority_type }, index) => (
      <CollegeTableRow key={index} index={index} moveRow={moveRow} updateRankings={updateRankings}>
        <td className="college-name">
          <Row>
            <a href={generatePath(COLLEGEPAGE, { id: college.id })}>{college.name}</a>
          </Row>
          {college.city_and_state && <Row>
            <span>{college.city_and_state}</span>
          </Row>}
        </td>
        <td className="college-list-data hidden-xs hidden-sm">{college.num_undergrads}</td>
        <td className="college-list-data hidden-xs hidden-sm">
          <Row>
            {college.act_total}
          </Row>
          <Row>
            {college.sat_total}
          </Row>
        </td>
        <td className="college-list-data hidden-xs hidden-sm">{college.in_state_cost} / {college.out_of_state_cost}</td>
        <td className="college-list-data">{
          forYouIds.indexOf(college.id) !== -1 ?
            (<div key={index} data-toggle="tooltip" data-placement="bottom" title={added_by_person}>
              <img
                src={added_by_person_image}
                width="230"
                height="230"
                className="round-img-hc person-photo--college-table"
              />
            </div>
            ) : (
              <span></span>
            )
        }</td>
        <td className="college-filter-button">
          <CollegeMenu
            collegeId={college.id}
            adminImpersonating={adminImpersonating}
            priorityType={priority_type}
            arrayCollegesType={arrayCollegesType}
            arrayCollegePriority={arrayCollegePriority}
            isInMyColleges={myCollegesIds.indexOf(college.id) !== -1}
            collegeIsInList={true}
          />
        </td>
      </CollegeTableRow>
    ))
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Table className="college-table">
        <thead>
          <tr>
            <CollegeTableColumns className="college-table-columns" applicationMode={applicationMode} />
          </tr>
        </thead>
        <tbody>
          {applicationMode ? applicationModeOnData() : applicationModeOffData()}
        </tbody>
      </Table>
    </DndProvider>
  )
}

export default CollegeTable
