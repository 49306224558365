import React from "react"

const Category = ({ collegeId, description, checked, label, handleClick }) => {
  return (
    <li className="col-xs-12 checkbox-wrapper">
      <input type="checkbox" value={description} name="name" id={"college_" + collegeId + "_type_" + description} className="hc-radiobox"
        checked={checked} onChange={handleClick} />
      <label htmlFor={"college_" + collegeId + "_type_" + description} className="font--college-list capital-letter">{label}</label>
    </li>
  )
}

export default Category
