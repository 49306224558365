import axios from "axios";
import { ADDACCEPTEDCOLLEGESADMINVIEW, ADDWAITLISTEDCOLLEGESADMINVIEW, ADDREJECTEDCOLLEGESADMINVIEW, REJECTEDCOLLEGESLISTADMINVIEW, ACCEPTEDCOLLEGESLISTADMINVIEW, COLLEGEFINALDECISIONADMINVIEW, COLLEGEFINANCIALDECISIONADMINVIEW, COLLEGEFINANCIALPACKAGEADMINVIEW, ADDCOLLEGETOFINANCIALPACKAGEADMINVIEW, WAITLISTEDCOLLEGESLISTADMINVIEW } from "../../hooks/apiConstants";
import { generatePath } from "react-router";

export const setFinalDecision = (store, collegeName) => {
    store.setState({
      collegeDecision: {
        ...store.state.collegeDecision,
        finalDecision: collegeName
      }
    })
}

export const setClearInput = (store, value) => {
  store.setState({
    collegeDecision: {
      ...store.state.collegeDecision,
      clearInput: value
    }
  })
}

export const setClearSearch = (store, value) => {
  store.setState({
    collegeDecision: {
      ...store.state.collegeDecision,
      clearSearch: value
    }
  })
}

export const handleStoreChange = (store, key, value) => {
  store.setState({
    college: {
      ...store.state.college,
      [key]: value
    }
  })
}

export const setFinancialDecision = (store, decision) => {
  store.setState({
    collegeDecision: {
      ...store.state.collegeDecision,
      financialDecision: decision
    }
  })
}

export const setAcceptedColleges = (store, accepted) => {
    store.setState({
        collegeDecision: {
          ...store.state.collegeDecision,
          acceptedColleges: accepted
        }
    })
}

export const setRejectedColleges = (store, rejected) => {
    store.setState({
        collegeDecision: {
          ...store.state.collegeDecision,
          rejectedColleges: rejected
        }
    })
}

export const setWaitlistedColleges = (store, waitlisted) => {
    store.setState({
        collegeDecision: {
          ...store.state.collegeDecision,
          waitlistedColleges: waitlisted
        }
    })
}

export const setFinancialPackage = (store, financial) => {
  store.setState({
    collegeDecision: {
      ...store.state.collegeDecision,
      financialPackage: financial
    }
  })
}

export const setFinancialAmounts = (store, amounts) => {
  store.setState({
      collegeDecision: {
        ...store.state.collegeDecision,
        financialAmounts: amounts
      }
  })
}

export const setFinancialInformation = (store, information) => {
  store.setState({
      collegeDecision: {
        ...store.state.collegeDecision,
        financialInformation: information
      }
  })
}

export const setCollegeFinancialPackage = (store, information) => {
  store.setState({
      collegeDecision: {
        ...store.state.collegeDecision,
        collegeFinancialPackage: information
      }
  })
}

export const fetchAcceptedColleges = async (studentId, setAcceptedColleges) => {
  const url = generatePath(ACCEPTEDCOLLEGESLISTADMINVIEW, { id: studentId });
  const response = await axios.get(url);
  const data = response.data;
  if (data.length !== undefined) {
    setAcceptedColleges(data);
  }
};

export const fetchWaitedColleges = async (studentId, setWaitlistedColleges) => {
  try {
    const url = generatePath(WAITLISTEDCOLLEGESLISTADMINVIEW, { id: studentId });
    const response = await axios.get(url);
    const data = response.data;
    if (data.length !== undefined) {
      setWaitlistedColleges(data);
    }
  } catch (error) {
    console.error("Error when making the request:", error);
  }
};

export const fetchRejectedColleges = async (studentId, setRejectedColleges) => {
  try {
    const url = generatePath(REJECTEDCOLLEGESLISTADMINVIEW, { id: studentId });
    const response = await axios.get(url);

    const data = response.data;
    if (data.length !== undefined) {
      setRejectedColleges(data);
    }
  } catch (error) {
    console.error("Error making the request:", error);
  }
};

export const fetchCollegeFinalDecision = async (studentId, setFinalDecision) => {
  const url = generatePath(COLLEGEFINALDECISIONADMINVIEW, { id: studentId });
  const response = await axios.get(url);
  const data = response.data;
  if (data.name !== undefined) {
    setFinalDecision(data);
  }
};

export const fetchCollegeFinancialDecision = async (studentId, setFinancialDecision) => {
  const url = generatePath(COLLEGEFINANCIALDECISIONADMINVIEW, { id: studentId });
  const response = await axios.get(url);
  const data = response.data;
  if (data.name !== undefined) {
    setFinancialDecision(data);
  }
};

export const fetchCollegeFinancialPackage = async (studentId, collegeId, setCollegeFinancialPackage) => {
  const url = generatePath(COLLEGEFINANCIALPACKAGEADMINVIEW, { id: studentId, college_id: collegeId });
  const response = await axios.get(url);
  const data = response.data;
  if (data !== null) {
    setCollegeFinancialPackage({
      college_decision: data.college_decision,
      financial_aid_package: data.financial_aid_package,
      college: data.college,
    });
  }
};

const getAcceptedColleges = async (params) => {
  try {
    const url = generatePath(ADDACCEPTEDCOLLEGESADMINVIEW, params);
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching accepted colleges:", error);
    throw error;
  }
};

const getWaitedColleges = async (params) => {
  try {
    const url = generatePath(ADDWAITLISTEDCOLLEGESADMINVIEW, params);
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching accepted colleges:", error);
    throw error;
  }
};

const getRejectedColleges = async (params) => {
  try {
    const url = generatePath(ADDREJECTEDCOLLEGESADMINVIEW, params);
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching accepted colleges:", error);
    throw error;
  }
};

const getCollegeFinancialPackage = async (params) => {
  try {
    const url = generatePath(ADDCOLLEGETOFINANCIALPACKAGEADMINVIEW, params);
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching College Financial Package:", error);
    throw error;
  }
}
export { getAcceptedColleges, getWaitedColleges, getRejectedColleges, getCollegeFinancialPackage };