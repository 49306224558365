import React from "react";

const SwitchToggle = ({ label, isChecked, handleToggleChange }) => {
  return (
    <div className="wrapper-application-mode">
      <label className="label-toggle-switch">
        {label}
      </label>
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={handleToggleChange} />
        <span className="slider round"></span>
      </label>
    </div>
  )
}

export default SwitchToggle;
