import { SAVEINFORMATION, REMOVEREJECTEDCOLLEGEADMINVIEW, REMOVEACCEPTEDCOLLEGEADMINVIEW, REMOVEWAITLISTEDCOLLEGEADMINVIEW, ADDCOLLEGETOFINALDECISIONADMINVIEW, COLLEGEFINANCIALPACKAGEADMINVIEW } from "../../../../hooks/apiConstants"
import React, { useState, useEffect } from "react"
import axios from "axios"
import useGlobal from "../../../../hooks/store"
import { generatePath } from "react-router"
import {
  Input,
} from "reactstrap"
import { college } from "../../../../hooks/actions"

const CollegeDecision = ({ student, option }) => {
  const [globalState, globalActions] = useGlobal();
  const [ showInfo, setShowInfo] = useState(false);
  const { finalDecision, acceptedColleges, waitlistedColleges, rejectedColleges, financialAmounts} = globalState.collegeDecision
  const { setFinancialAmounts,setAcceptedColleges, setWaitlistedColleges, setRejectedColleges, setFinalDecision, setCollegeFinancialPackage } = globalActions.collegeDecision
  const [alert, setAlert] = useState(null);

  const showAlert = (message, type) => {
    setAlert({ message, type });
    setTimeout(() => {
      setAlert(null);
    }, 3000); 
  };
  
  const addCollegeList = (college) => {
    const url = generatePath(ADDCOLLEGETOFINALDECISIONADMINVIEW, { id: student.id, college_id: college.college})
    axios.get(url).then()
    setFinalDecision(college)
    const url_college_financial_package = generatePath(COLLEGEFINANCIALPACKAGEADMINVIEW, { id: student.id, college_id: college.college})
    axios.get(url_college_financial_package)
      .then(response => {
        const data = response.data
        if(data != null) {
          setCollegeFinancialPackage(data)
        }
      })     
  } 

  useEffect(() => {  
    finalDecision === null ? setShowInfo(false) : setShowInfo(true) 
    }, [finalDecision])

  const addFinancialAmount = (amount, accepted) => {
    let collegeExists = financialAmounts.find(element => element['college_id'] === accepted.college)
    if ( collegeExists != undefined) {
      collegeExists['financial_aid_amount'] = amount
    }
    else{
      collegeExists = {college_id: accepted.college, financial_aid_amount: amount, id: accepted.id }
      setFinancialAmounts([...financialAmounts, collegeExists])
    }
  }
  
  const removeCollege = (collegeId) => {
    if (option === 'Accepted Colleges') {
      const url = generatePath(REMOVEACCEPTEDCOLLEGEADMINVIEW, { accepted_college: collegeId, id: student.id });
      axios.get(url).then(response => {
        showAlert('Data deleted successfully', 'success');
      })
      .catch(error => {
        showAlert('Error deleting data from accepted college', 'danger');
      });
  
      const updatedColleges = acceptedColleges.filter((item) => item.college !== collegeId);
      setAcceptedColleges(updatedColleges);
    } else if (option === 'Waitlisted Colleges') {
      const url = generatePath(REMOVEWAITLISTEDCOLLEGEADMINVIEW, { waitlisted_colleges: collegeId, id: student.id });
      axios.get(url).then(response => {
        showAlert('Data deleted successfully', 'success');
      })
      .catch(error => {
        showAlert('Error deleting data from  waitlist', 'danger');
      });
  
      const updatedColleges = waitlistedColleges.filter((item) => item.id !== collegeId);
      setWaitlistedColleges(updatedColleges);
    } else if ( option === 'Rejected Colleges' ) {

      const url = generatePath(REMOVEREJECTEDCOLLEGEADMINVIEW, { rejected_colleges: collegeId, id: student.id })
      axios.get(url).then(response => {
        showAlert('Data deleted successfully', 'success');
      })
      .catch(error => {
        showAlert('Error deleting data from  rejected colleges', 'danger');
      });

      const updatedColleges = rejectedColleges.filter((item) => item.id !== collegeId);
      setRejectedColleges(updatedColleges)
    }
  };
  
  const saveChanges = (collegeId, financialAidAmount) => {
    let collegeExists = financialAmounts.find(element => element['college_id'] === collegeId)

    if (!collegeExists.financial_aid_amount  || collegeExists.financial_aid_amount.length === 0 || collegeExists.financial_aid_amount === null) {
      showAlert('Please do not enter negative numbers', 'danger');
      return;
    }

    axios.post(SAVEINFORMATION, { id: student.id, college_id: collegeId, financial_aid_amount: collegeExists.financial_aid_amount })
      .then(response => {
        showAlert('Changes saved successfully', 'success');
      })
      .catch(error => {
        showAlert('Error saving changes', 'danger');
      });
  };
  
  const Alert = ({ message, type, onClose }) => {
    return (
      <div className={`alert alert-${type}`} role="alert">
        {message}
        <button type="button" className="close" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  return (
    <div>
      {(() => {
        if (option == 'Accepted Colleges') {
          return (
            <div className="row page--font-color m-0 safe-subgroup modal-component pl-0 col-lg-12 col-md-12 col-sm-12">
              <div>
                {alert && <Alert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />}
              </div>
              <div className="row col-lg-12 col-md-12 col-sm-12 pl-0">
              { acceptedColleges.length > 0 ? (
                <div className="col-lg-12 col-md-12 col-sm-12 pl-0 pr-5">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
                    <label style={{ marginBottom: '10px'}}>{option}</label>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
                    <label style={{ marginBottom: '1px'}}>Financial Amount Per Year</label>
                  </div>
                  <div className="action-position col-lg-2 col-md-2 col-sm-2 col-xs-2 pl-0">
                    <label style={{ marginBottom: '10px'}}>Final Decision</label>
                  </div>
                  <div className="action-position col-lg-2 col-md-2 col-sm-2 col-xs-2 pl-0">
                    <label style={{ marginBottom: '10px'}}>Action</label>
                  </div>
                 
                </div>  ) : (<div></div>)}
                  <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-10'>
                  {
                    acceptedColleges !== null ? (
                      acceptedColleges.length > 0 ? (
                        acceptedColleges.map((value, index) => (
                          <div key={index} className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-10" style={{ backgroundColor: index % 2 === 0 ? '#E8E8E8' : '' }}>
                              <div className="pt-20 pl-0 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span className="pl-0"> {value.name}</span>
                              </div>
                              <div className="pb-15 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span className="currencyinput-search">$</span>
                                <Input
                                  min="0"
                                  type="number"
                                  onChange={(event) => addFinancialAmount(event.target.value, value)}
                                  className="input-amount-size space-input"
                                  defaultValue={value.financial_aid_amount !== null ? value.financial_aid_amount : ''}
                                  placeholder="0.00"
                                  pattern="[0-9*]"
                                />
                              </div>
                              <div className="radio-button col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="inlineRadio1"
                                  value={value.college}
                                  onChange={() => addCollegeList(value)}
                                  checked={value.college === finalDecision.id || value.college === finalDecision.college}
                                />
                              </div>
                              <div className="position-x col-lg-1 col-md-1 col-sm-1 col-xs-1 pl-0">
                                <a onClick={() => removeCollege(value.college)} className="remove-colleges" id="trash">
                                  {value.loading ? (
                                    <span className="glyphicon glyphicon-refresh align-right"></span>
                                  ) : (
                                    <span className="glyphicon glyphicon-remove align-right"></span>
                                  )}
                                </a>
                              </div>
                              <div className="position-x col-lg-1 col-md-1 col-sm-1 col-xs-1 pl-0">
                                <a onClick={() => saveChanges(value.college, financialAmounts)} className="remove-colleges">
                                  {value.loading ? (
                                    <span className="glyphicon glyphicon-refresh align-right"></span>
                                  ) : (
                                    <span className="glyphicon glyphicon-ok align-right"></span>
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        ''
                      )
                    ) : (
                      <div>Loading...</div>
                    )
                  }

                </div>
                <div className="collge-final-decision pl-5">
                  <label style={{ marginTop: "10px"}} className="pl-0">Final College Choice: </label>
                  <span>{ "  "+finalDecision.name }</span >
                </div>  
              </div>
            </div>
          )
        } else if ( option == 'Waitlisted Colleges'){ 
          return (
            <div className="row page--font-color m-0 safe-subgroup modal-component pl-0 col-lg-12 col-md-12 col-sm-12">
            <div className="row col-lg-12 col-md-12 col-sm-12 pl-0">
            { waitlistedColleges.length > 0 ? (
              <div className="pl-0 pr-5 col-lg-12 col-md-12 col-sm-12"> 
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0"> {option}
                </div>
              </div>   ) : (<div></div>)}
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-10'>
                  {
                    waitlistedColleges && waitlistedColleges.length > 0  ? (
                    waitlistedColleges.map((value, index) => (
                      <div key={index} className='row'>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-10"  style={{ backgroundColor: index % 2 == 0 ? '#E8E8E8' : ''}}>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-20 pl-0 pb-20">
                            <span className="pl-0"> {value.name}</span>
                          </div>
                          <div className="pb-15 col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                          <div className="position-x col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <a onClick={() => removeCollege(value.id)} className="remove-colleges" id='trash'> <span className="glyphicon glyphicon-remove align-right"></span></a>
                          </div>
                        </div> 
                      </div>
                    ))): ''
                  }
              </div>
            </div>
            </div>
         )
      } else if ( option == 'Rejected Colleges' ) {
        return (
         <div className="row page--font-color m-0 safe-subgroup modal-component pl-0 col-lg-12 col-md-12 col-sm-12">
          <div className="row col-lg-12 col-md-12 col-sm-12 pl-0">
            { rejectedColleges.length > 0 ? (
            <div className="pl-0 pr-5 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                <label style={{ marginBottom: '10px'}} className="pl-0">{option}</label>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 pl-0"></div>
              <div className="action-position col-lg-3 col-md-3 col-sm-3 col-xs-3 pl-0">
                <label style={{ marginBottom: '10px'}}>Action</label>
              </div>
            </div>
            ) : (<div></div>)}
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-10'>
                {
                  rejectedColleges && rejectedColleges.length > 0  ? (
                  rejectedColleges.map((value, index) => (
                    <div key={index} className='row'>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-10"  style={{ backgroundColor: index % 2 == 0 ? '#E8E8E8' : ''}}>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-20 pl-0 pb-20">
                          <span className="pl-0"> {value.name}</span>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 pb-15"></div>
                        <div className="position-x col-lg-3 col-md-3 col-sm-3 col-xs-3">
                          <a onClick={() => removeCollege(value.id)} className="remove-colleges" id='trash'> <span className="glyphicon glyphicon-remove align-right"></span></a>
                        </div>
                      </div> 
                    </div>
                  ))): ''
                }
            </div>
          </div>
        </div>
        )
    } 
      })()
      }
    </div>
  );
}
export default CollegeDecision