import React from 'react';

const Notes = ({ note }) => {

  return (
    <div className='card_student_dashboard'>
      <div className="card__header_sidebar">
        <h2 className="title_student_dashboard">Notes</h2>
      </div>
      <div className="notes-div">
        <p style={{ overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: note.body }}></p>
      </div>
    </div>
  );
};

export default Notes;