import React, { useState, useEffect} from "react"
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {
  Input,
  Row,
} from "reactstrap"
import axios from "axios"
import { COLLEGEFINALDECISION, COLLEGEFINANCIALDECISION,REJECTEDCOLLEGESLIST, WAITLISTEDCOLLEGESLIST, ACCEPTEDCOLLEGESLIST, COLLEGEFINANCIALPACKAGE } from "../../../hooks/apiConstants"
import useGlobal from "../../../hooks/store/index"
import SuggestionsDecision from "./SuggestionsDecision"
import CollegeDecision from "./CollegeDecision"

library.add(fas);
dom.watch();

const CollegeDecisionInfo = ({ student, option}) => {
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [globalState, globalActions] = useGlobal()
  const {suggestions} = globalState.college
  const [collegeName, setCollegeName ] = useState('')
  const { setAcceptedColleges, setRejectedColleges, setWaitlistedColleges,  setCollegeFinancialPackage, handleStoreChange, setClearInput} = globalActions.collegeDecision
  const {  collegeFinancialPackage , clearInput} = globalState.collegeDecision

  useEffect(() => {  
    if ( clearInput ){
      setCollegeName('')
    }
  }, [clearInput])

  useEffect(() => {  
    if(option == 'Accepted Colleges'){
      axios.get(ACCEPTEDCOLLEGESLIST)
        .then(response => {
          const data = response.data
          if(data.length !== undefined) {
            setAcceptedColleges(data)
          }
        })
      axios.get(COLLEGEFINALDECISION)
      .then(response => {
      const data = response.data
      if(data.name !== undefined) {
        globalActions.collegeDecision.setFinalDecision(data)
      }})
      axios.get(COLLEGEFINANCIALDECISION)
      .then(response => {
      const data = response.data
      if(data.name !== undefined) {
        globalActions.collegeDecision.setFinancialDecision(data)
      }})
      axios.get(COLLEGEFINANCIALPACKAGE)
        .then(response => {
        const data = response.data
        if(data != null) {
          collegeFinancialPackage.college_decision = data.college_decision
          collegeFinancialPackage.financial_aid_package = data.financial_aid_package
          collegeFinancialPackage.college = data.college
        }
      })
    }
    else if (option == 'Rejected Colleges'){
      axios.get(REJECTEDCOLLEGESLIST)
      .then(response => {
        const data = response.data
         if(data.length !== undefined) {
          setRejectedColleges(data)
            }
        })
    }
    else if (option == 'Waitlisted Colleges'){
        axios.get(WAITLISTEDCOLLEGESLIST)
          .then(response => {
            const data = response.data
            if(data.length !== undefined) {
              setWaitlistedColleges(data)
            }
        })
    }
  }, [])

  const handleChange = (value) => {
    setClearInput(false)
    setCollegeName(value)
    if(value.length >= 3) {
      setTimeout(() => {
        globalActions.college.getSuggestions({ name: value })
      }, 1000)
    }
  }

  return (
    <div >
      <Row className="pb-15">
        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 pl-0">
          <Input 
            id='search-colleges'
            className="no-border-radius no-box-shadow mb-0" 
            placeholder="Search college names"
            value={collegeName } 
            onChange={(event) => handleChange(event.target.value)} 
            onFocus={() => setShowSuggestions(true)} 
            onBlur={() => setShowSuggestions(false)}
          />
        </div>
      </Row>
     <SuggestionsDecision value={collegeName} showSuggestions={showSuggestions} suggestions={suggestions} option={option} student={student}  /> 
     <div><CollegeDecision student={student} option={option}/></div>
    </div>
    
  )
}
export default CollegeDecisionInfo
