import React from "react";
import { default as defaultBanner } from '../../../../assets/images/pfa-college-banner.jpg';

const CollegeBanner = ({collegeBanner}) => {
  let imgSrc = collegeBanner?collegeBanner:defaultBanner
  return (
    <img 
      className="bannner-pic"
      alt="banner college image"
      src={imgSrc}>
    </img>
  )
}

export default CollegeBanner