import React, { useState, useEffect } from "react";
import useGlobal from "../../../hooks/store";
import Select from "react-select";
import "../../../../assets/stylesheets/backend/milestone_report/milestone_report.scss";
function Filters({ counselor_list, grad_year_list, filterData}) {

    const [globalState, globalActions] = useGlobal();
    const {
        gradYear,
        counselorId,
      } = globalState.milestoneFilters;
    const { handleStoreChange } = globalActions.milestoneFilters;


    const filterDataParams = (
        commonFilters
      ) => {
        let filters = { ...commonFilters};
        return filters;
    };


  const setDropdownCounselor = (value) => {
    handleStoreChange("counselorId", value);
    const filterParams = filterDataParams(
      {
        filter_by_counselor: value,
        filter_by_grad_year: gradYear,
      }
    );
    filterData(filterParams, "counselor");
  };


  const setDropdownGradYear = (selectedYears) => {
    const years = selectedYears.map((option) => option.value).join(",");
    handleStoreChange("gradYear", years);
    const filterParams = filterDataParams(
      {
        filter_by_counselor: counselorId,
        filter_by_grad_year: years,
      }
    );
    filterData(filterParams, "gradYear");
  };

  
  return (
    <>
      <div className="align-filter">
        <span className="dashboard-title-filters">Counselor:</span>
        <select
          className="filter-style no-shadow"
          value={counselorId}
          onChange={(e) => setDropdownCounselor(e.target.value)}
        >
          <option key={0} value="">
            All Counselors
          </option>
          {counselor_list.map((counselor, index) => (
            <option key={index} value={counselor["counselor_id"]}>
              {counselor["counselor_name"]}
            </option>
          ))}
        </select>
        <span className="dashboard-title-filters">Grad Year:</span>
        <Select
          className="grad-year-filter-select"
          isMulti
          value={
            grad_year_list
            .filter((year) => gradYear.includes(year))
            .map((year) => ({ value: year, label: year }))
            }
          options={grad_year_list.map((year) => ({ value: year, label: year }))}
          onChange={setDropdownGradYear}
          placeholder="Select a grad year"
        /> 
      </div>
    </>
  );
}

export { Filters };


