import axios from "axios"
import { generatePath } from "react-router"
import {
  ADDCOLLEGETOLIST,
  UPDATELISTTYPE,
  UPDATEPRIORITYTYPE,
  REMOVECOLLEGEFROMLIST,
  ADDCOLLEGETOCOMPARISON,
  COLLEGEPAGE,
  UPDATERANKINGCOLLEGELIST,
  UPDATEAPPMODEPREFERENCE,
  UPDATEDEADLINECOLLEGELIST,
  ADDTARGETSUBMISSIONDATETOCOLLEGE
} from "../apiConstants"

export const addCollegeToList = (store, selectedOption, collegeId) => {
  store.setState({
    student: {
      ...store.state.student,
      currentIdCollegeRequest: collegeId,
      requesting: true,
    }
  })

  const hostUrl = generatePath(ADDCOLLEGETOLIST, { id: collegeId, name: selectedOption })

  axios.get(hostUrl).then(response => {
    store.setState({
      student: {
        ...store.state.student,
        collegesList: [
          ...response.data
        ],
        requesting: false
      }
    })
  }).catch(error => {
    store.setState({
      student: {
        ...store.state.student,
        error: error.response.data.error,
        requesting: false
      }
    })
  })
}

export const addTargetSubmissionDateToCollege = (store, date, id) => {
  let params = date ? { id: id, date: date } : { id: id }
  const hostUrl = generatePath(ADDTARGETSUBMISSIONDATETOCOLLEGE, params)
  axios.get(hostUrl)
}

export const addCollegeToCompareList = (store, college) => {
  if (!(store.state.student.collegesToCompare.filter(e => e.id === college.id).length > 0)) {
    const hostUrl = generatePath(ADDCOLLEGETOCOMPARISON, { college_id: college.id })

    axios.get(hostUrl).then(response => {
      store.setState({
        student: {
          ...store.state.student,
          collegesToCompare: [...store.state.student.collegesToCompare, response.data]
        }
      })
    })
  }
}

export const removeCollegeToCompareList = (store, college) => {
  store.setState({
    student: {
      ...store.state.student,
      collegesToCompare: [...store.state.student.collegesToCompare.filter(c => c.id !== college.id)]
    }
  })
}


export const updateListTypeToCollege = (store, selectedOption, collegeId) => {
  store.setState({
    student: {
      ...store.state.student,
      currentIdCollegeRequest: collegeId,
      requesting: true,
    }
  })

  const hostUrl = generatePath(UPDATELISTTYPE, { id: collegeId, name: selectedOption })

  axios.get(hostUrl).then(response => {
    store.setState({
      student: {
        ...store.state.student,
        collegesList: [
          ...response.data
        ],
        requesting: false
      }
    })
  }).catch(error => {
    store.setState({
      student: {
        ...store.state.student,
        error: error.response.data.error,
        requesting: false
      }
    })
  })
}

export const updatePriorityTypeToCollege = (store, selectedOption, collegeId) => {
  store.setState({
    student: {
      ...store.state.student,
      currentIdCollegeRequest: collegeId,
      requesting: true,
    }
  })

  const hostUrl = generatePath(UPDATEPRIORITYTYPE, { id: collegeId, name: selectedOption })

  axios.get(hostUrl).then(response => {
    store.setState({
      student: {
        ...store.state.student,
        collegesList: [
          ...response.data
        ],
        requesting: false
      }
    })
  }).catch(error => {
    console.log('CATCH', error)
    store.setState({
      student: {
        ...store.state.student,
        error: error.response.data.error,
        requesting: false
      }
    }
    )
  })
}

export const removeCollegeFromList = (store, collegeId) => {
  store.setState({
    student: {
      ...store.state.student,
      currentIdCollegeRequest: collegeId,
      requesting: true,
    }
  })

  const hostUrl = generatePath(REMOVECOLLEGEFROMLIST, { id: collegeId })

  axios.get(hostUrl).then(response => {
    let forYouColleges = []
    let myListColleges = []

    response.data.map(collegeList => {
      collegeList.list_type === "my_colleges" ? myListColleges.push(collegeList) : forYouColleges.push(collegeList)
    })

    store.setState({
      student: {
        ...store.state.student,
        myListColleges,
        forYouColleges,
        collegesList: [
          ...response.data
        ],
        requesting: false
      }
    })
  }).catch(error => {
    store.setState({
      student: {
        ...store.state.student,
        error: error.response.data.error,
        requesting: false
      }
    })
  })
}

export const handleStoreChange = (store, key, value) => {
  store.setState({
    student: {
      ...store.state.student,
      [key]: value
    }
  })
}

export const updateCollegesRankings = (store, new_rankings) => {
  const hostUrl = generatePath(UPDATERANKINGCOLLEGELIST, { new_rankings: JSON.stringify(new_rankings) })
  axios.get(hostUrl)
}

export const updateAppModePreference = (store, appMode) => {
  const hostUrl = generatePath(UPDATEAPPMODEPREFERENCE, { appMode: appMode })
  axios.get(hostUrl)
}

export const updateCollegeDeadline = (_, id, deadline) => {
  const hostUrl = generatePath(UPDATEDEADLINECOLLEGELIST, { id, deadline })
  axios.get(hostUrl)
}
