import React from "react";

const Files = ({ student, documents, latestDocumentVersions }) => {
  const has_seen_version = (version) => {
    if (
      version.event !== null &&
      version.created_at !== null &&
      version.updated_at !== null
    ) {
      return true;
    }
    return false;
  };

  const is_a_documentVersion = (version) => {
    if (typeof version === "object" && version !== null) {
      if (
        version.hasOwnProperty("event") &&
        version.hasOwnProperty("created_at") &&
        version.hasOwnProperty("updated_at")
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const renderFileUrlAndDate = (link, name, date, type) => {
    return (
      <div className="files_item">
        <div className="files_content">
          <a href={link} target="_blank" className={type}>
            {name}
          </a>
        </div>
        <div className="files_info">
          <span className="files_span">{date}</span>
        </div>
      </div>
    );
  };

  const renderFileUrlAndDateForDocument = (
    studentId,
    documentId,
    createdAt
  ) => {
    const fileUrl = `/students/${studentId}/documents/${documentId}`;
    const document = documents.find((doc) => doc.id === documentId);
    const filename = document ? document.filename : null;
    const formattedDate = formatDate(createdAt);
    return renderFileUrlAndDate(
      fileUrl,
      filename,
      formattedDate,
      "files_subtitle"
    );
  };

  const renderFileUrlAndDateForLinks = (versionId, createdAt) => {
    const document = documents.find((doc) => doc.id === versionId);
    const fileUrl = document ? document.url : null;
    const filename = document ? document.filename : null;
    const formattedDate = formatDate(createdAt);
    return renderFileUrlAndDate(
      fileUrl,
      filename,
      formattedDate,
      "files_subtitle_for_links"
    );
  };

  return (
    <div className="card_student_dashboard">
      <div className="card__header_sidebar">
        <h2 className="title_student_dashboard">Files</h2>
        <div style={{ display: "inline" }}>
          <a
            href={`/students/${student.id}/documents/new`}
            style={{ marginRight: "10px" }}
            id="upload_file_link"
          >
            Upload File
          </a>
          <a href={`/students/${student.id}/documents`}>All files</a>
        </div>
      </div>
      <div>
        {documents && latestDocumentVersions.length > 0 ? (
          <ul className="files_list">
            {latestDocumentVersions.map((version) => (
              <li
                key={version.id}
                className={`list__item ${
                  has_seen_version(version) ? "" : "list__item--alert"
                }`}
                alt={
                  has_seen_version(version)
                    ? "Document seen"
                    : "Document not seen"
                }
              >
                {is_a_documentVersion(version)
                  ? renderFileUrlAndDateForDocument(
                      student.id,
                      version.document_id,
                      version.created_at
                    )
                  : renderFileUrlAndDateForLinks(
                      version.id,
                      version.created_at
                    )}
              </li>
            ))}
          </ul>
        ) : (
          <div className="files_item">
            <div className="files_content">
              <span className="files_span">
                You haven't uploaded any documents
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Files;
