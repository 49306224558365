const dashboard = {
    requesting: false,
    successful: false,
    error: "",
    message: "",
    filteredData: [],
    pagination: {
        page: 1,           
        amountPerPage: 20,   
        next: 2,
        prev: null,
        totalPages: 0,
        totalData:0,

    },
    sort: {
        sortDir: "",           
        sortByGI: "",
        sortByTask: "",
    },

}
export default dashboard