import React from "react"
import {
  Row,
} from "reactstrap"
import useGlobal from "../../../hooks/store/index"

const SuggestionsList = ({ value, showSuggestions, suggestions, adminImpersonating }) => {
  const [globalState, globalActions] = useGlobal()
  const { currentListType } = globalState.student

  const addCollegeToList = (college) => {
    if (currentListType === "my_colleges"){
      globalActions.student.addCollegeToList(currentListType, college.id)
    }
    if (currentListType === "for_you" && adminImpersonating){
      globalActions.student.addCollegeToList(currentListType, college.id)
    }
  }

  return (
    <Row>
        {
          !!value &&
          value.length >= 3 &&
          showSuggestions &&
          suggestions.length > 0 &&
          <div className="d-flex">
            <ul className="suggestions-container suggestions-width">
              {
                suggestions.map((college, index) => {
                  return (
                    <li key={index} className="option" onMouseDown={() => (addCollegeToList(college))}>
                      <div>
                        <span>{college.name}</span>
                        <br />
                        <span>
                          {
                            !!college.details &&
                            !!college.details.city &&
                            !!college.details.state &&
                            college.details.city + ", " + college.details.state
                            
                          }
                        </span>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        }
    </Row>
  )
}
export default SuggestionsList
