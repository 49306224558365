import React from "react";
import "../../../../assets/stylesheets/backend/formula_note.scss"; // Importa tu archivo SCSS para los estilos

function FormulaNote() {
  return (
    <div>
      <p>
        Active Students: Students who have hours remaining to use.
      </p>
      <p>
        Total Hours Unused: Total Hours left to be used for all of the students assigned to them.
      </p>
      <p>
        % of Active Students with next Session Booked: Total students with an upcoming meeting / Total active students.
      </p>
    </div>
  );
}

export default FormulaNote;
