import axios from "axios";
import { generatePath } from "react-router";
import {
  FILTERDASHBOARDDATA,
  STUDENTDASHBOARDDATA,
  STUDENTMASSEMAIL,
} from "../apiConstants";

export const filterDataBy = (store, params) => {
  const hostUrl = generatePath(FILTERDASHBOARDDATA, {
    filter_data_params: JSON.stringify(params),
  });

  return axios.get(hostUrl, {
    headers: {
      Accept: "application/json",
    },
  });
};

export const getDataStudent = (store, params) => {
  const hostUrl = generatePath(STUDENTDASHBOARDDATA, {
    get_data_student: JSON.stringify(params),
  });
  return axios.get(hostUrl, {
    headers: {
      Accept: "application/json",
    },
  });
};

export const handleStoreChangeDashboard = (store, key, value) => {
  store.setState({
    dashboard: {
      ...store.state.dashboard,
      [key]: value,
    },
  });
};

export const sendMassEmailStudents = async (store, params) => {
  const { student_ids, subject, body } = params;

  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
  try {
    return axios.post(
      STUDENTMASSEMAIL,
      {
        student_ids: student_ids.join(","),
        email_subject: subject,
        email_body: body,
      },
      {
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      }
    );
  } catch (error) {
    console.error("Error in axios.post", error.response.data);
    throw error;
  }
};

export const handleStoreChange = (store, key, value) => {
  store.setState({
    dashboard: {
      ...store.state.dashboard,
      [key]: value,
    },
  });
};

export const sortBySection = (section, sort) => {
  if (section === "general_information") {
    return sort.sortByGI;
  } else {
    return sort.sortByTask;
  }
};
