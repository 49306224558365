import React from "react";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassHalf, faTimes } from "@fortawesome/free-solid-svg-icons";

export function StatusIcon({ noMargin }) {
  return (
    <div className={`left-menu-button-tasks ${noMargin ? "no-margin" : ""}`}>
      <div className="meaning-icons">
        <img
          src={"/images/iconos_dashboard/not_started.png"}
          alt="Not started"
          className="icon-status"
        />
        <p>Not started</p>
      </div>
      <div className="meaning-icons">
        <img
          src={"/images/iconos_dashboard/registered.png"}
          alt="Registered"
          className="icon-status"
        />
        <p>Registered</p>
      </div>
      <div className="meaning-icons">
        <img
          src={"/images/iconos_dashboard/in_progress.png"}
          alt="In progress"
          className="icon-status"
        />
        <p>In Progress</p>
      </div>
      <div className="meaning-icons">
        <img
          src={"/images/iconos_dashboard/completed.png"}
          alt="Completed"
          className="icon-status"
        />
        <p>Completed</p>
      </div>
      <div className="meaning-icons">
        <img
          src={"/images/iconos_dashboard/opted_out.png"}
          alt="Opted out"
          className="icon-status"
        />
        <p>Opted out</p>
      </div>
    </div>
  );
}

export function StudentStatusIcon() {
  return (
    <div className="left-menu-button-students-icons">
      <div className="meaning-icons">
        <FontAwesomeIcon
          icon={faHourglassHalf}
          style={{ color: "595959" }}
          className="icon-button"
        />
        <p>On Hold</p>
      </div>
      <div className="meaning-icons">
        <FontAwesomeIcon
          icon={faTimes}
          style={{ color: "red" }}
          className="icon-button"
        />
        <p>Suspended</p>
      </div>
    </div>
  );
}
