import axios from "axios";
import { generatePath } from "react-router";
import { FILTERTUTORREPORTDATA } from "../apiConstants";
export const filterDataBy = () => {
  const hostUrl = generatePath(FILTERTUTORREPORTDATA);
  return axios.get(hostUrl, {
    headers: {
      Accept: "application/json",
    },
  });
};
export const handleStoreChange = (store, key, value) => {
  store.setState({
    turorReportFilters: {
      ...store.state.turorReportFilters,
      [key]: value,
    },
  });
};