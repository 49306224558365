import React from "react";

const LinkWrapper = (props) => {
    const {link, text} = props
  
    const short_link = (link) => {
      return new URL(link).hostname;
    }
  
    return (
      <a style={{textDecoration: 'underline'}} href={link} target="_blank"> {text ? text : short_link(link)}</a>
    )
}

export default LinkWrapper;