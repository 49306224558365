// excelUtils.js
import { getData } from '../dataUtils';
import { signInWithGoogle, initializeGapi } from './googleDriveUtils';
import { gapi } from 'gapi-script';

export async function download(currentList, collegeDetails, studentName, adminImpersonating) {
    const data = getData(currentList, collegeDetails);
    const getD = data.getD;
    const linksCollegesPFA = data.linksCollegesPFA;
    const XlsxPopulate = require("xlsx-populate");
    fetch("/templates/TemplateReport.xlsx")
      .then((res) => res.arrayBuffer())
      .then((buffer) => {
        XlsxPopulate.fromDataAsync(buffer)
          .then((workbook) => {
            workbook.sheet(0).column("A").width(15)
            workbook.sheet(0).column("B").width(45)
            workbook.sheet(0).column("C").width(25)
            workbook.sheet(0).column("D").width(25)
            workbook.sheet(0).column("J").width(40)
            workbook.sheet(0).column("O").width(65)
            workbook
              .sheet(0)
              .cell("C1")
              .value(studentName + " College List");
            let baseRow = 3;
            for (let index = 0; index < getD.length; index++) {
              const element = getD[index];
              for (let i = 0; i < element.length; i++) {
                const cellValue = element[i];
                const cell= workbook.sheet(0).cell(baseRow, i + 1)
                if (cellValue.toString().match(/http/g)) {
                    cell.value("Application Type")
                    .style({ underline: true, horizontalAlignment: "center", verticalAlignment: "center", wrapText: true, })
                    .hyperlink(cellValue.toString());
                } else {
                    cell.value(cellValue)
                    .style({
                      horizontalAlignment: "center",
                      verticalAlignment: "center",
                      wrapText: true,
                    });
                }
              }
              baseRow = baseRow + 1;
            }
            //Add link College Name
            const indexColumn = 2
            const totalRows= (getD.length+2);
            for (var i= 3; i <= totalRows; i++) {
              let currentRoute= window.location.href;
              const myArray = currentRoute.split("/");
              let route=myArray[0]+"/"+myArray[2]+"/"+myArray[5]+"/"+linksCollegesPFA[workbook.sheet(0).cell(i,indexColumn).value()]
              workbook.sheet(0).cell(i,indexColumn)
              .style({ underline: true, horizontalAlignment: "center", wrapText: true, })
              .hyperlink(route)
            }
            // Get the output
            return workbook.outputAsync();
          })
          .then((data) => {
            if(adminImpersonating){
              signInWithGoogle(data, studentName);
            }else{
              var url = window.URL.createObjectURL(data);
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = url;
              a.download = "CollegeList-"+studentName+".xlsx";
              a.click();
            }
          
          })
          .catch((err) => console.error(err));
      });
}

function showSuccessMessage(message) {
  alert(message);
}

export function createFile(csvData, folderId, accessToken, studentName) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Zero-padding
    const day = currentDate.getDate().toString().padStart(2, '0'); // Zero-padding
    const hours = currentDate.getHours().toString().padStart(2, '0'); // Zero-padding
    const minutes = currentDate.getMinutes().toString().padStart(2, '0'); // Zero-padding
    const seconds = currentDate.getSeconds().toString().padStart(2, '0'); // Zero-padding
    const formattedDateTime = `${year}-${month}-${day}-${hours}:${minutes}:${seconds}`;

    const fileName = "CollegeList-"+studentName + "-" +formattedDateTime;

    var metadata = {
      name: fileName,
      mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      parents: [folderId], // Google Drive folder id
    };

    var form = new FormData();
    form.append(
      'metadata',
      new Blob([JSON.stringify(metadata)], { type: 'application/json' })
    );
    form.append('file', csvData);

    fetch(
      'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true',
      {
        method: 'POST',
        headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
        body: form,
      }
    )
      .then((res) => {
        showSuccessMessage('File uploaded to drive');
        return res.json();
      })
}




