import React from "react";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import Table from "./TasksTable";

function Workshops({ counselor_list, grad_year_list, employee, program_list }) {
  return (
    <div>
      <h1 className="dashboard-title">Workshops</h1>
      <Table
        counselor_list={counselor_list}
        grad_year_list={grad_year_list}
        employee={employee}
        program_list={program_list}
        isWorkshops={true}
      />
    </div>
  );
}

export default Workshops;
