import {
    ADDFINANCIALDECISIONADMINVIEW,
    COLLEGEFINANCIALPACKAGE,
} from "../../../../hooks/apiConstants"
import React, { useState, useEffect } from "react";
import axios from "axios";
import SuggestionsDecision from "./SuggestionsDecision";
import useGlobal from "../../../../hooks/store"
import { generatePath } from "react-router";
import { Row, Input } from "reactstrap";
import { fetchAcceptedColleges, fetchRejectedColleges, fetchCollegeFinalDecision, fetchCollegeFinancialDecision, fetchCollegeFinancialPackage, fetchWaitedColleges } from "../../../../hooks/actions/collegeDecision";

const FinancialCollegeDecision = ({ student, option }) => {
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [globalState, globalActions] = useGlobal();
    const {
        financialDecision,
        financialAmounts,
        collegeFinancialPackage,
        finalDecision,
        clearSearch,
    } = globalState.collegeDecision;
    const { setCollegeFinancialPackage, setClearSearch } =
        globalActions.collegeDecision;
    const [collegeName, setCollegeName] = useState("");
    const [showInfo, setShowInfo] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const { suggestions } = globalState.college;
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        financialDecision === null ? setShowInfo(false) : setShowInfo(true);
    }, []);


    useEffect(() => {
        if (clearSearch) {
            setCollegeName("");
        }
    }, [clearSearch]);

    const handleChange = (value) => {
        setClearSearch(false);
        setCollegeName(value);
        if (value.length >= 3) {
            setTimeout(() => {
                globalActions.college.getSuggestionsAdminView({ name: value }, { student_id: student.id });
            }, 1000);
        }
    };

    const addFinancialDecision = (value, option) => {
        if (option === "private") {
            collegeFinancialPackage.college_decision.private_scholarship_amount =
                value;
        } else if (option === "number") {
            collegeFinancialPackage.college_decision.private_scholarships_number =
                value;
        } else if (option === "merit") {
            collegeFinancialPackage.college_decision.merit_scholarship_amount = value;
        } else if (option === "package") {
            collegeFinancialPackage.financial_aid_package.financial_aid_package_amount =
                value;
        }
    };

    const axiosfunction = (url) => {
        return axios.get(url);
    }

    const saveCollegeDecision = async () => {
        try {
            // Validations for accepted_colleges_attributes
            if (financialAmounts.length !== 0 && financialAmounts.some(amount => amount < 0)) {
                showAlert('Please do not enter negative numbers', 'danger');
                return;
            }
    
            // Validations for financial_aid_package_attributes
            if (Object.keys(collegeFinancialPackage.financial_aid_package).length !== 0) {
                const financialAidPackageAmount = collegeFinancialPackage.financial_aid_package.financial_aid_package_amount;
                if (financialAidPackageAmount < 0) {
                    showAlert('Please do not enter negative numbers', 'danger');
                    return;
                }
    
                let student_params_package = {
                    financial_aid_package_attributes: {
                        id: collegeFinancialPackage.financial_aid_package.id,
                        negotiate_financial_aid_package: collegeFinancialPackage.financial_aid_package.negotiate_financial_aid_package,
                        financial_aid_package_amount: financialAidPackageAmount,
                        selected_college_id: collegeFinancialPackage.financial_aid_package.selected_college_id,
                    },
                };
                let params_package = JSON.stringify(student_params_package);
                const url = generatePath(ADDFINANCIALDECISIONADMINVIEW, {
                    student_params: params_package,
                    id: student.id,
                });
                await axiosfunction(url);
                showAlert('Changes saved successfully', 'success');
            }
    
            // Validations for student_college_decision_attributes
            if (Object.keys(collegeFinancialPackage.college_decision).length !== 0) {
                const meritScholarshipAmount = collegeFinancialPackage.college_decision.merit_scholarship_amount;
                const privateScholarshipsNumber = collegeFinancialPackage.college_decision.private_scholarships_number;
                const privateScholarshipAmount = collegeFinancialPackage.college_decision.private_scholarship_amount;
    
                if (meritScholarshipAmount < 0 || privateScholarshipsNumber < 0 || privateScholarshipAmount < 0) {
                    showAlert('Please do not enter negative numbers', 'danger');
                    return;
                }
                let collegeDecision = finalDecision.id ? finalDecision.id : financialDecision.id;

                let student_params = {
                    student_college_decision_attributes: {
                        id: collegeFinancialPackage.college_decision.id,
                        earn_merit_scholarship: collegeFinancialPackage.college_decision.earn_merit_scholarship,
                        merit_scholarship_amount: meritScholarshipAmount,
                        earn_private_scholarship: collegeFinancialPackage.college_decision.earn_private_scholarship,
                        private_scholarships_number: privateScholarshipsNumber,
                        private_scholarship_amount: privateScholarshipAmount,
                        final_college_id: collegeDecision,
                    },
                };
                let params = JSON.stringify(student_params);
                const url = generatePath(ADDFINANCIALDECISIONADMINVIEW, {
                    student_params: params,
                    id: student.id,
                });
                await axiosfunction(url);
                showAlert('Changes saved successfully', 'success');
            }
    
        } catch (error) {
            showAlert('Error saving changes', 'danger');
        }
    };
    
    
    const Alert = ({ message, type, onClose }) => {
        return (
          <div className={`alert alert-${type}`} role="alert">
            {message}
            <button type="button" className="close" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
    };

    const showAlert = (message, type) => {
        setAlert({ message, type });
        setTimeout(() => {
          setAlert(null);
        }, 3000); 
      };

    const financialAidEvent = (value, container) => {
        const decision = collegeFinancialPackage.college_decision;
        const financialAidPackage = collegeFinancialPackage.financial_aid_package;
    
        if (container === "earn_private_scholarship") {
            decision.earn_private_scholarship = value;
    
            if (value === "false") {
                decision.private_scholarships_number = 0;
                decision.private_scholarship_amount = 0;
            }
        } else if (container === "earn_merit_scholarship") {
            decision.earn_merit_scholarship = value;
    
            if (value === "false") {
                decision.merit_scholarship_amount = 0;
            }
        } else {
            financialAidPackage.negotiate_financial_aid_package = value;
    
            if (value === "false") {
                financialAidPackage.financial_aid_package_amount = 0;
                financialAidPackage.selected_college_id = null;
            }
        }
    
        setCollegeFinancialPackage(collegeFinancialPackage);
    
        const displayValue = value === "true" ? "block" : "none";
        document.getElementById(container).style.setProperty("display", displayValue, "important");
    };
    return (
        <div>
            {(() => {
                if (option == "Financial Package") {
                    return (
                        <div className="row p-0">
                            <div>
                                {alert && <Alert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />}
                            </div>
                            <div className="safe-subtitle col-lg-12 col-md-12">
                                <span>Financial Aid</span>
                            </div>
                            <div className="safe-subtitle-body">
                                <div className="college-decision-space">
                                    <div className="row page--font-color m-0 safe-subgroup">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                            <div
                                                className="row page--font-color pl-0"
                                                style={{ marginBottom: "20px" }}
                                            >
                                                <div className="col-lg-12 pl-0">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 mb-10">
                                                        <div className="col-lg-10 col-md-10 col-sm-8 col-xs-8 pl-0">
                                                            <label>
                                                                {" "}
                                                                1. Did you successfully appeal or negotiate your
                                                                financial aid package?
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-4 pl-0">
                                                            <select
                                                                onChange={(event) =>
                                                                    financialAidEvent(
                                                                        event.target.value,
                                                                        "negotiate_financial_aid_package"
                                                                    )
                                                                }
                                                                value={
                                                                    collegeFinancialPackage.financial_aid_package
                                                                        .negotiate_financial_aid_package !=
                                                                        undefined
                                                                        ? collegeFinancialPackage
                                                                            .financial_aid_package
                                                                            .negotiate_financial_aid_package
                                                                        : false
                                                                }
                                                            >
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    id="negotiate_financial_aid_package"
                                                    style={{
                                                        display: (
                                                            collegeFinancialPackage.financial_aid_package
                                                                .negotiate_financial_aid_package != undefined
                                                                ? collegeFinancialPackage.financial_aid_package
                                                                    .negotiate_financial_aid_package
                                                                : false
                                                        )
                                                            ? "block"
                                                            : "none",
                                                    }}
                                                >
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-12">
                                                            <Row className="pb-15">
                                                                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 pl-0 package-input">
                                                                    <Input
                                                                        className="no-border-radius no-box-shadow mb-0"
                                                                        placeholder="Select college"
                                                                        value={collegeName}
                                                                        onChange={(event) =>
                                                                            handleChange(event.target.value)
                                                                        }
                                                                        onFocus={() => setShowSuggestions(true)}
                                                                        onBlur={() => setShowSuggestions(false)}
                                                                        type="text"
                                                                    />
                                                                </div>
                                                            </Row>
                                                            <SuggestionsDecision
                                                                value={collegeName}
                                                                showSuggestions={showSuggestions}
                                                                suggestions={suggestions}
                                                                option={option}
                                                                student={student}
                                                            />
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-0  package-amount">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                <label> College </label>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-start">
                                                                <label>Package Amount</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-0">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                {financialDecision.name ? (
                                                                    <p>{financialDecision.name}</p>
                                                                ) : (
                                                                    <p></p>
                                                                )}
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-start">
                                                                <span className="currencyinput-search">$</span>
                                                                <Input
                                                                    min="0"
                                                                    className="input-component space-input"
                                                                    onChange={(event) =>
                                                                        addFinancialDecision(
                                                                            event.target.value,
                                                                            "package"
                                                                        )
                                                                    }
                                                                    defaultValue={
                                                                        collegeFinancialPackage
                                                                            .financial_aid_package
                                                                            .financial_aid_package_amount != undefined
                                                                            ? collegeFinancialPackage
                                                                                .financial_aid_package
                                                                                .financial_aid_package_amount
                                                                            : ""
                                                                    }
                                                                    type="number"
                                                                    placeholder="0.00"
                                                                    width="100"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                            <div
                                                className="row page--font-color pl-0"
                                                style={{ marginBottom: "20px" }}
                                            >
                                                <div className="col-lg-12 pl-0">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0">
                                                        <div className="col-lg-10 col-md-10 col-sm-8 col-xs-8 pl-0">
                                                            <label>
                                                                {" "}
                                                                2. Did you earn merit scholarship from your
                                                                school of choice?
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-4 pl-0">
                                                            <select
                                                                onChange={(event) =>
                                                                    financialAidEvent(
                                                                        event.target.value,
                                                                        "earn_merit_scholarship"
                                                                    )
                                                                }
                                                                value={
                                                                    collegeFinancialPackage.college_decision
                                                                        .earn_merit_scholarship != undefined
                                                                        ? collegeFinancialPackage.college_decision
                                                                            .earn_merit_scholarship
                                                                        : false
                                                                }
                                                            >
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    id="earn_merit_scholarship"
                                                    style={{
                                                        display: collegeFinancialPackage.college_decision
                                                            .earn_merit_scholarship
                                                            ? "block"
                                                            : "none",
                                                    }}
                                                >
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0">
                                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                <p> Merit Scholarship Amount </p>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-start">
                                                                <span className="currencyinput-search">$</span>
                                                                <Input
                                                                    type="number"
                                                                    min="0"
                                                                    className="input-component space-input"
                                                                    onChange={(event) =>
                                                                        addFinancialDecision(
                                                                            event.target.value,
                                                                            "merit"
                                                                        )
                                                                    }
                                                                    defaultValue={
                                                                        collegeFinancialPackage.college_decision
                                                                            .merit_scholarship_amount != undefined
                                                                            ? collegeFinancialPackage.college_decision
                                                                                .merit_scholarship_amount
                                                                            : ""
                                                                    }
                                                                    placeholder="0.00"
                                                                    width="100%"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div
                                                className="row page--font-color pl-0"
                                                style={{ marginBottom: "20px" }}
                                            >
                                                <div className="col-lg-12 pl-0">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0">
                                                        <div className="col-lg-10 col-md-10 col-sm-8 col-xs-8 pl-0">
                                                            <label>
                                                                3. Did you earn private scholarships?
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-4 pl-0">
                                                            <select
                                                                onChange={(event) =>
                                                                    financialAidEvent(
                                                                        event.target.value,
                                                                        "earn_private_scholarship"
                                                                    )
                                                                }
                                                                value={
                                                                    collegeFinancialPackage.college_decision
                                                                        .earn_private_scholarship != undefined
                                                                        ? collegeFinancialPackage.college_decision
                                                                            .earn_private_scholarship
                                                                        : false
                                                                }
                                                            >
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    id="earn_private_scholarship"
                                                    style={{
                                                        display: (
                                                            collegeFinancialPackage.college_decision
                                                                .earn_private_scholarship != undefined
                                                                ? collegeFinancialPackage.college_decision
                                                                    .earn_private_scholarship
                                                                : false
                                                        )
                                                            ? "block"
                                                            : "none",
                                                    }}
                                                >
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0">
                                                        <div className="row col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                <p> Number of private scholarship</p>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                <Input
                                                                    min="0"
                                                                    className="input-component space-input"
                                                                    onChange={(event) =>
                                                                        addFinancialDecision(
                                                                            event.target.value,
                                                                            "number"
                                                                        )
                                                                    }
                                                                    defaultValue={
                                                                        collegeFinancialPackage.college_decision
                                                                            .private_scholarships_number != null
                                                                            ? collegeFinancialPackage.college_decision
                                                                                .private_scholarships_number
                                                                            : ""
                                                                    }
                                                                    type="number"
                                                                    placeholder="0"
                                                                    width="100%"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                <p> Private Scholarship Amount </p>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                <span className="currencyinput-search">$</span>
                                                                <Input
                                                                    min="0"
                                                                    className="input-component space-input"
                                                                    onChange={(event) =>
                                                                        addFinancialDecision(
                                                                            event.target.value,
                                                                            "private"
                                                                        )
                                                                    }
                                                                    defaultValue={
                                                                        collegeFinancialPackage.college_decision
                                                                            .private_scholarship_amount != undefined
                                                                            ? collegeFinancialPackage.college_decision
                                                                                .private_scholarship_amount
                                                                            : ""
                                                                    }
                                                                    type="number"
                                                                    placeholder="0.00"
                                                                    pattern="[0-9*]"
                                                                    width="100%"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                           
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8"></div>
                                        <div className="col-lg-4 pull-right">
                                        <button className="button--primary-blue"  onClick={() => saveCollegeDecision()}>SAVE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                }
            })()}
        </div>
    );
};
export default FinancialCollegeDecision;
