import React from "react";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import EssayCoachTable from "./EssayCoachTable";
function EssayCoach({
  essayInfoStudents,
  counselor_list,
  grad_year_list,
  employee,
  paginationData,
  program_list,
}) {
  return (
    <div>
      <h1 className="dashboard-title">Essay Coach</h1>
      <EssayCoachTable
        essayInfoStudents={essayInfoStudents}
        counselor_list={counselor_list}
        grad_year_list={grad_year_list}
        employee={employee}
        paginationData={paginationData}
        program_list={program_list}
      />
    </div>
  );
}

export default EssayCoach;
