import React from "react"
import axios from "axios"
import {
  Row,
} from "reactstrap"
import { ADDCOLLEGETOFINALDECISION,ADDACCEPTEDCOLLEGES, ADDAREJECTEDCOLLEGES, ADDWAITLISTEDCOLLEGES, ADDCOLLEGETOFINANCIALPACKAGE, COLLEGEFINANCIALPACKAGE}  from "../../../hooks/apiConstants"
import { generatePath } from "react-router" 
import useGlobal from "../../../hooks/store"

const SuggestionsDecision = ({ value, showSuggestions, suggestions, option, student }) => {
  const [globalState, globalActions] = useGlobal();
  const { setFinalDecision, setAcceptedColleges, setRejectedColleges, setWaitlistedColleges, setFinancialDecision, setCollegeFinancialPackage, setClearInput, setClearSearch} = globalActions.collegeDecision
  const { acceptedColleges, rejectedColleges, waitlistedColleges, collegeFinancialPackage, clearInput } = globalState.collegeDecision

  const addCollegeList = (college) => {
    setClearInput(true)
    if(option == 'Accepted Colleges'){
      const index = acceptedColleges.filter(c => c.college == college.id).length
      if(index == 0) {
        setAcceptedColleges([...acceptedColleges, college])
        const url = generatePath(ADDACCEPTEDCOLLEGES, { accepted_colleges:  college.id  } )
        axios.get(url).then(
          response => {
            const data = response.data
            if(data.id !== undefined) {
              setAcceptedColleges([...acceptedColleges, data])
            }
          }
        );
      }
    }
    else if(option == 'Financial Package'){
      setClearSearch(true)
      setFinancialDecision(college)
      collegeFinancialPackage.financial_aid_package.selected_college_id = college.id
      setCollegeFinancialPackage(collegeFinancialPackage)
      const url = generatePath(ADDCOLLEGETOFINANCIALPACKAGE, { student_id: student.id, college_id: college.id })
      axios.get(url).then();
    }
    else if(option == 'Rejected Colleges'){
      const index = rejectedColleges.filter(c => c.id == college.id).length
      if(index == 0) {
        setRejectedColleges([...rejectedColleges, college])
        const url = generatePath(ADDAREJECTEDCOLLEGES, { rejected_colleges:  college.id  } )
        axios.get(url).then();
      }
    }
    else if(option == 'Waitlisted Colleges'){
      const index = waitlistedColleges.filter(c => c.id == college.id).length
      if(index == 0) {
        setWaitlistedColleges([...waitlistedColleges, college])
        const url = generatePath(ADDWAITLISTEDCOLLEGES, { waitlisted_colleges:  college.id  } )
        axios.get(url).then();
      }
    }
  }

  return (
    <Row>
        {
          !!value &&
          value.length >= 3 &&
          showSuggestions &&
          suggestions.length > 0 &&
          <div className="d-flex">
            <ul className="search-input-container .earch-input-width">
              {
                 suggestions.length > 0 ? (
                suggestions.map((college, index) => {
                  return (
                    <li key={index} className="option" onMouseDown={() => (addCollegeList(college))}>
                      <div>
                        <span>{college.name}</span>
                        <br />
                        <span>
                          {
                            !!college.details &&
                            !!college.details.city &&
                            !!college.details.state &&
                            college.details.city + ", " + college.details.state
                            
                          }
                        </span>
                      </div>
                    </li>
                  )
                })
                ) : (<div></div>)}
            </ul>
          </div>
        }
    </Row>
  )
}
export default SuggestionsDecision
