import React from "react"
import ListTypeMenu from "./ListTypeMenu";
import PriorityTypeMenu from "./PriorityTypeMenu";

const AdminDropdownMenu = ({ collegeId, arrayCollegesType, arrayCollegePriority, priorityType, adminImpersonating, collegeIsInList, setPriorityLocalType, setCurrentPriorityTypeGeneralInformation }) => {

  return (
    <ul className={"margin-college-menu dropdown-1 dropdown-menu dropdown-menu-right" + " dropdown-categories-" + collegeId}>
      <ListTypeMenu
        collegeId={collegeId}
        arrayCategoriesType={arrayCollegesType}
        title={"List"}
        collegeIsInList={collegeIsInList}
      />
      {
        (adminImpersonating) &&
        <PriorityTypeMenu
          collegeId={collegeId}
          arrayCategoriesType={arrayCollegePriority}
          selectedOption={priorityType}
          title={"Categories"}
          setPriorityLocalType={setPriorityLocalType}
          setCurrentPriorityTypeGeneralInformation={setCurrentPriorityTypeGeneralInformation}
        />
      }
    </ul>
  )
}

export default AdminDropdownMenu
