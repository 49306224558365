document.addEventListener("DOMContentLoaded", function () {
  const hoursTypeRemove = document.getElementById("hoursTypeRemove");
  const hoursToTransfer = document.getElementById("hoursToTransfer");
  const hoursTypeAdd = document.getElementById("hoursTypeAdd");
  const transferForm = document.getElementById("transferHoursForm");
  const studentId = transferForm.getAttribute("data-student-id");
  const flashMessage = document.getElementById("flash-message");

  let transferableNoteTypes = [];
  let addableNoteTypes = [];

  function fetchAvailableNoteTypes() {
    fetch(`/backend/students/${studentId}/available_note_types`)
      .then((response) => response.json())
      .then((data) => {
        transferableNoteTypes = data.transferable_note_types;
        addableNoteTypes = data.addable_note_types;
        updateHoursTypeRemoveOptions();
        updateHoursTypeAddOptions();
      })
      .catch((error) =>
        console.error("Error fetching available note types:", error)
      );
  }

  function updateHoursTypeRemoveOptions() {
    hoursTypeRemove.innerHTML = "<option value='' disabled selected>Select...</option>";
    transferableNoteTypes.forEach((type) => {
      const option = document.createElement("option");
      option.value = type;
      option.textContent = type;
      hoursTypeRemove.appendChild(option);
    });
  }

  function updateHoursTypeAddOptions() {
    hoursTypeAdd.innerHTML = "<option value='' disabled selected>Select...</option>";
    addableNoteTypes.forEach((type) => {
      if (type !== hoursTypeRemove.value) {
        const option = document.createElement("option");
        option.value = type;
        option.textContent = type;
        hoursTypeAdd.appendChild(option);
      }
    });
  }

  function updateHoursToTransferOptions(remainingHours) {
    hoursToTransfer.innerHTML = "<option value='' disabled selected>Select...</option>";
    for (let i = 1; i <= remainingHours; i++) {
      const option = document.createElement("option");
      option.value = i;
      option.textContent = i;
      hoursToTransfer.appendChild(option);
    }
  }

  hoursTypeRemove.addEventListener("change", function () {
    const selectedType = hoursTypeRemove.value;
    if (selectedType) {
      fetch(`/backend/students/${studentId}/remaining_hours?hour_type=${encodeURIComponent(selectedType)}`)
        .then(response => response.json())
        .then(data => {
          const remainingHours = data.remaining_hours;
          updateHoursToTransferOptions(remainingHours);
        })
        .catch(error => console.error("Error fetching remaining hours:", error));
    } else {
      hoursToTransfer.innerHTML = "<option value='' disabled selected>Select...</option>";
    }
    updateHoursTypeAddOptions();
  });

  transferForm.addEventListener("submit", function (event) {
    event.preventDefault();

    const fromType = hoursTypeRemove.value;
    const toType = hoursTypeAdd.value;
    const quantity = parseInt(hoursToTransfer.value, 10);

    fetch(`/backend/students/${studentId}/transfer_hours`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
      },
      body: JSON.stringify({
        from_type: fromType,
        to_type: toType,
        quantity: quantity,
      }),
    })
      .then((response) => {
        if (response.ok) {
          $("#transferHours").modal("hide");
          location.reload();
        } else {
          return response.json().then((data) => {
            alert(`Error: ${data.error}`);
          });
        }
      })
      .catch((error) => console.error("Error transferring hours:", error));
  });

  fetchAvailableNoteTypes();

  if (flashMessage) {
    setTimeout(() => {
      flashMessage.style.transition = "opacity 0.5s ease";
      flashMessage.style.opacity = "0";

      setTimeout(() => flashMessage.remove(), 500); 
    }, 3000);
  }
});
