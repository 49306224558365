const TAB_GENERAL_INFORMATION = 1;
const TAB_TASKS = 2;
const TAB_WORKSHOPS = 3;
const TAB_ESSAY_COACH = 4;
const TAB_TUTOR = 5;
const NO_COUNSELOR = "0";
const FILTER_TYPES = {
  search: "search",
  gradYear: "gradYear",
  nextContactDate: "nextContactDate",
  nextMeetingDate: "nextMeetingDate",
  nextBookedWorkshopDate: "nextBookedWorkshopDate",
  program: "program",
  counselor: "counselor",
  programs: "programId",
  hoursRemaining: "hoursRemaining",
};

const emptyMessage = (filterType, filteredData) => {
  if (filterType === FILTER_TYPES.search && filteredData.length === 0) {
    return "No matching records found";
  }
  if (filterType === FILTER_TYPES.gradYear && filteredData.length === 0) {
    return "No students were found to graduate in the selected year";
  }
  if (
    filterType === FILTER_TYPES.nextContactDate &&
    filteredData.length === 0
  ) {
    return "No students found with a next contact date";
  }
  if (
    filterType === FILTER_TYPES.nextMeetingDate &&
    filteredData.length === 0
  ) {
    return "No students found with a next meeting";
  }
  if (
    filterType === FILTER_TYPES.nextBookedWorkshopDate &&
    filteredData.length === 0
  ) {
    return "No students found with a next booked workshop";
  }
  if (filterType === FILTER_TYPES.programs && filteredData.length === 0) {
    return "No matching records found";
  }
  if (filterType === FILTER_TYPES.counselor && filteredData.length === 0) {
    return "Counselor has no assigned students";
  }
  if (filterType === FILTER_TYPES.hoursRemaining && filteredData.length === 0) {
    return "No students found with the filter selected";
  }
  if (filterType === "" && filteredData.length === 0) {
    return "No matching records found";
  }
  return null;
};

export {
  TAB_GENERAL_INFORMATION,
  TAB_TASKS,
  TAB_WORKSHOPS,
  TAB_TUTOR,
  TAB_ESSAY_COACH,
  FILTER_TYPES,
  emptyMessage,
  NO_COUNSELOR,
};

export function formatShortDate(dateStr) {
  if (!dateStr) return "-";

  const [year, month, day] = dateStr.split("-");
  const date = new Date(year, month - 1, day);
  const formattedDate = `${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}/${String(date.getDate()).padStart(2, "0")}/${String(
    date.getFullYear()
  ).slice(-2)}`;
  return formattedDate;
}
