import React from "react"
import options from "../../../constants/RetentionRange"
import useGlobal from "../../../../hooks/store"
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(fas);
dom.watch();

const RetentionMenu = ({ currentRetentionRate, setRetentionRate, setFilter }) => {
  const [globalState, globalActions] = useGlobal()
  const { currentPage } = globalState.college

  const addOption = (option) => {
    let rangePosition = currentRetentionRate.indexOf(option)
    if(rangePosition === -1)
      currentRetentionRate.push(option)
    else
      currentRetentionRate.splice(rangePosition, 1)
    setRetentionRate(currentRetentionRate)
  }

  const filter = () => {
    globalActions.college.handleStoreChange("currentPage", 1)
    globalActions.college.handleStoreChange("searchBy", "filter")
    globalActions.college.filterColleges(currentPage)
    $(".dropdown-options").toggle()
  }

  return (
    <div>
      <div className="navigation-options-container__margin">
        <div className="profile-completition-message">
          <div className='filter-done-btn filter--done-button col-sm-4 col-md-4 col-lg-4'>
            <span onClick={() => setFilter("")}>{"< Filters"}</span>
          </div>
          <div className='filter--title col-sm-4 col-md-4 col-lg-4 no-padding'></div>
          <div className='filter-done-btn filter--done-button col-xs-4 col-sm-4 col-md-4 col-lg-4'>
            <span onClick={() => filter()}>Search</span>
          </div>
        </div>
      </div>
      <hr className='title-separator' />
      {
        options.map((option, index) => (
          <div key={index}>
            <li className='element-separator filter--element-label' onClick={() => addOption(option.field)}>
              <span>{option.value}</span>
              <span>
                {
                  currentRetentionRate.indexOf(option.field) !== -1 &&
                  <FontAwesomeIcon icon="check" />
                }
              </span>
            </li>
          </div>
        ))
      }
    </div>
  )
}
export default RetentionMenu
