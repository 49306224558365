import React from "react"
import moment from "moment"
import { agendaDateTime } from "../../helpers/agendaDateTime"

import { completeMonthName, shortTimeOnly, shortDayOnly } from "../../helpers/timeFormat"

const Index = ({ events }) => {
  let currentMonth = (events.length > 0) ? completeMonthName(events[0].start) : completeMonthName(new Date())

  return (
    <div className="container agenda-container">
      <table className="agenda-table">
        <thead>
          <tr>
            <th className="agenda-header">Date</th>
            <th className="agenda-header">Time</th>
            <th className="agenda-header">Topic</th>
            <th className="agenda-header">Type</th>
            <th className="agenda-header">Status</th>
            <th className="agenda-header">Advisor</th>
          </tr>
        </thead>
      </table>
      <table className="agenda-table">
        <tbody>
          {
            (events.length > 0) ?
              <tr className="agenda-month-separator">
                <td colSpan="6">{completeMonthName(event.start)}</td>
              </tr>
            :
              <tr className="agenda-row">
                <td className="agenda-cell agenda-date-cell">There are no events for this student.</td>
              </tr>
          }
          {
            events.map(event => {
              const month = completeMonthName(event.start)
              const { date, time } = agendaDateTime(event)

              if(currentMonth === month) {
                return (
                  <tr key={event.id} className="agenda-row">
                    <td className="agenda-cell agenda-date-cell">{date}</td>
                    <td className="agenda-cell agenda-date-cell">{time}</td>
                    <td className="agenda-cell agenda-date-cell text-center">{event.title}</td>
                    <td className="agenda-cell agenda-date-cell">{event.titleize_type}</td>
                    <td className="agenda-cell agenda-date-cell">{event.titleize_status}</td>
                    <td className="agenda-cell agenda-date-cell">{event.employee || "N/A"}</td>
                  </tr>
                )
              } else {
                currentMonth = month
                return (
                  <>
                    <tr className="agenda-month-separator">
                      <td colSpan="6">{month}</td>
                    </tr>
                    <tr className="agenda-row">
                      <td className="agenda-cell agenda-date-cell">{`${shortDayOnly(event.start)} - ${shortDayOnly(event.end)}`}</td>
                      <td className="agenda-cell agenda-date-cell">{shortTimeOnly(event.start)}</td>
                      <td className="agenda-cell agenda-date-cell text-center">{event.title}</td>
                      <td className="agenda-cell agenda-date-cell">{event.titleize_type}</td>
                      <td className="agenda-cell agenda-date-cell">{event.titleize_status}</td>
                      <td className="agenda-cell agenda-date-cell">{event.employee || "N/A"}</td>
                    </tr>
                  </>
                )
              }
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default Index
