const college = {
  requesting: false,
  successful: false,
  error: "",
  message: "",
  colleges: [],
  suggestions: [],
  totalPages: 0,
  filters: {
    states: [],
    range: [],
    education: "",
    unweightedGPA: [],
    acceptanceRate: [],
    retention: [],
    needMet: [],
    include2YrColleges: false,
    duration: [],
  },
  searchName: "",
  collegeFinalDecision: "",
  searchBy: "",
  currentPage: 0,
}
export default college
