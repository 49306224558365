import React from "react";
import { useTable } from "react-table";
import { healthyContact } from "./table_columns/client_load_and_healthy_contact_columns";
import "../../../../assets/stylesheets/backend/healthy_report/healthy_report.scss";

const ReportTable = ({ columns, tableData }) => {
  const memoizedColumns = React.useMemo(
    () => healthyContact(columns),
    [columns]
  );

  const data = React.useMemo(() => {
    return tableData.map((row) => {
      const rowData = Object.keys(row).reduce((acc, columnKey) => {
        const value = row[columnKey];
        return { ...acc, [columnKey]: value };
      }, {});

      return rowData;
    });
  }, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: memoizedColumns,
      data,
    });

  return (
    <div className="table-report-scrolls">
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    columnIndex === 0
                      ? "table-header-fixed-report"
                      : "table-header-reports"
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td {...cell.getCellProps()} className={"table-cell-fixed"}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;
