import React, { useEffect, useState } from "react"
import useGlobal from "../../../hooks/store/index"
import CollegeMenu from "./CollegeMenu"

const CollegeProfile = ({ collegeId, studentCollegesList, priorityType, arrayCollegesType, arraycollegePriority, categories, collegeIsInList, adminImpersonating }) => {
  const [globalState, globalActions] = useGlobal()
  const [college, setCollege] = useState()
  const { collegesList } = globalState.student

  useEffect(() => {
    if(globalState.student.collegesList !== studentCollegesList) {
      globalActions.student.handleStoreChange('collegesList', studentCollegesList)
    }
  }, [studentCollegesList])

  useEffect(() => {
    setCollege(collegesList.find(({college}) => college.id === collegeId))
  }, [collegesList])

  return (
    <CollegeMenu
      collegeId={collegeId}
      adminImpersonating={adminImpersonating}
      arrayCollegesType={arrayCollegesType}
      arrayCollegePriority={arraycollegePriority}
      showPriorities={!!college || categories}
      collegeIsInList={!!college || collegeIsInList}
      priorityType={college ? college.priority_type : priorityType}
      isInMyColleges={!!college && college.list_type === "my_colleges"}
    />
  )
}

export default CollegeProfile
