export const detailValues = [
    {
      name: 'Overview',
      value: 'division',
      isUrl: false,
    },
    {
      name: 'Institution Type',
      value: 'institution_type',
      isUrl: false,
    },
    {
      name: 'Nearest Metro Area',
      value: 'nearest_metro_area',
      isUrl: false,
    },
    {
      name: 'Acceptance Rate',
      value: 'acceptance_rate',
      isUrl: false,
    },
    {
      name: 'Enrolled Freshmen',
      value: 'enrolled_freshmen_number',
      isUrl: false,
    },
    {
      name: 'Undergrads',
      value: 'num_undergrads',
      isUrl: false,
    },
    {
      name: 'Total Enrollment',
      value: 'total_enrollment_number',
      isUrl: false,
    },
    {
      name: 'First Year Retention',
      value: 'first_year_retention',
      isUrl: false,
    },
    {
      name: '4 & 6 year Graduation Rate',
      value: 'four_and_six_graduation_rate',
      isUrl: false,
    },
    {
      name: 'In State Total Cost',
      value: 'in_state_total_cost_number',
      isUrl: false,
    },
    {
      name: 'Out of State Total Cost',
      value: 'out_of_state_total_cost_number',
      isUrl: false,
    },
    {
      name: 'Student Faculty Ratio',
      value: 'student_faculty_ratio',
      isUrl: false,
    },
    {
      name: 'College Website',
      value: 'homepage',
      isUrl: true,
    },
    {
      name: 'Academics',
      value: 'division',
      isUrl: false,
    },
    {
      name: 'GPA/Which Used',
      value: 'averaged_gpa_value',
      isUrl: false,
    },
    {
      name: 'ACT/SAT 25th %',
      value: 'act_sat_25',
      isUrl: false,
    },
    {
      name: 'ACT/SAT 75th %',
      value: 'act_sat_75',
      isUrl: false,
    },
    {
      name: 'ACT Superscore?',
      value: 'superscore',
      isUrl: false,
    },
    {
      name: 'BS/MD Duration',
      value: 'bs_md_years',
      isUrl: false,
    },
    {
      name: 'BS/MD Program',
      value: 'bs_md_page',
      isUrl: true,
    },
    {
      name: 'Admissions',
      value: 'division',
      isUrl: false,
    },
    {
      name: 'Common Application Used?',
      value: 'common_app',
      isUrl: false,
    },
    {
      name: 'Essay(s) Required?',
      value: 'essays',
      isUrl: false,
    },
    {
      name: 'Interview',
      value: 'interviews',
      isUrl: false,
    },
    {
      name: 'Recommendation(s) Req?',
      value: 'letters_of_rec',
      isUrl: false,
    },
    {
      name: 'Application Fees',
      value: 'application_fees_amount',
      isUrl: false,
    },
    {
      name: 'Admissions Phone Number',
      value: 'admission_phone',
      isUrl: false,
    },
    {
      name: 'Application Webpage',
      value: 'electronic_app',
      isUrl: true,
    },
    {
      name: 'Admissions Deadline',
      value: 'division',
      isUrl: false,
    },
    {
      name: 'Regular Deadline',
      value: 'regular_admission_deadline',
      isUrl: false,
    },
    {
      name: 'Early Decision Deadlines',
      value: 'ed_deadline',
      isUrl: false,
    },
    {
      name: 'Student Notification EA/ED',
      value: 'ea_ed_student_notification',
      isUrl: false,
    },
    {
      name: 'Student Notification Regular',
      value: 'notification_by',
      isUrl: false,
    },
    {
      name: 'Financial Aid',
      value: 'division',
      isUrl: false,
    },
    {
      name: 'Net Price Calculator',
      value: 'net_price_calc_url',
      isUrl: true,
    },
    {
      name: 'Average % of Need Met',
      value: 'avg_need_met_percentage',
      isUrl: false,
    },
    {
      name: 'Financial Aid Deadline',
      value: 'finaid_deadline',
      isUrl: false,
    },
    {
      name: 'Financial Aid Webpage',
      value: 'finaid_website',
      isUrl: true,
    },
  ]