const collegeDecision = {
    finalDecision: '',
    acceptedColleges: [],
    rejectedColleges: [],
    waitlistedColleges: [],
    financialDecision: '',
    financialAmounts: [],
    financialPackage: '',
    collegeFinancialPackage: {
     financial_aid_package: {}, 
     college: {}, 
     college_decision: {}
    },
    clearInput: false, 
    clearSearch: true
}
export default collegeDecision