import { ADDFINANCIALDECISION, COLLEGEFINANCIALPACKAGE} from "../../../hooks/apiConstants"
import React, { useState, useEffect} from "react"
import axios from "axios"
import SuggestionsDecision from "./SuggestionsDecision"
import useGlobal from "../../../hooks/store"
import { generatePath } from "react-router"
import {
  Row,
  Input,
} from "reactstrap"

const FinancialCollegeDecision = ({ student,option }) => {
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [globalState, globalActions] = useGlobal();
  const { financialDecision, financialAmounts, collegeFinancialPackage, finalDecision, clearSearch } = globalState.collegeDecision
  const { setCollegeFinancialPackage, setClearSearch} = globalActions.collegeDecision
  const [collegeName, setCollegeName] = useState('')
  const [ showInfo, setShowInfo] = useState(false);
  const [ btnDisabled, setBtnDisabled] = useState(false);
  const { suggestions } = globalState.college

  useEffect(() => {  
    financialDecision === null ? setShowInfo(false) : setShowInfo(true) 
  }, [])


  useEffect(() => {  
    if ( clearSearch ){
      setCollegeName('')
    }
  }, [clearSearch])

  const handleChange = (value) => {
    setClearSearch(false)
    setCollegeName(value)
    if(value.length >= 3) {
      setTimeout(() => {
        globalActions.college.getSuggestions({ name: value })
      }, 1000)
    }
  }

  const addFinancialDecision = (value, option) => {
    if( option === 'private' ){
      collegeFinancialPackage.college_decision.private_scholarship_amount = value
    }
    else if ( option === 'number' ) {
      collegeFinancialPackage.college_decision.private_scholarships_number = value
    }
    else if ( option === 'merit'){
      collegeFinancialPackage.college_decision.merit_scholarship_amount = value
    }
    else if( option === 'package'){
      collegeFinancialPackage.financial_aid_package.financial_aid_package_amount = value
    }
  }

  const backTo = () =>{
    window.location.href = '/students/profiles/transcript';
  }

  const saveCollegeDecision = () =>{
    if(financialAmounts.length != 0) {      
      let accepted_colleges_attributes = { accepted_colleges_attributes: financialAmounts }
      accepted_colleges_attributes = JSON.stringify(accepted_colleges_attributes);
      const url = generatePath(ADDFINANCIALDECISION, { student_params: accepted_colleges_attributes })
      axios.get(url).then();
    }
    if( Object.keys(collegeFinancialPackage.college_decision).length != 0 ){
      let collegeDecision = finalDecision.id ? finalDecision.id : financialDecision.id;

      let student_params = 
      { 
        student_college_decision_attributes: {
          id: collegeFinancialPackage.college_decision.id,
          earn_merit_scholarship: collegeFinancialPackage.college_decision.earn_merit_scholarship,
          merit_scholarship_amount: collegeFinancialPackage.college_decision.merit_scholarship_amount,
          earn_private_scholarship: collegeFinancialPackage.college_decision.earn_private_scholarship,
          private_scholarships_number: collegeFinancialPackage.college_decision.private_scholarships_number,
          private_scholarship_amount: collegeFinancialPackage.college_decision.private_scholarship_amount,
          final_college_id: collegeDecision,
        }
      }
      let params = JSON.stringify(student_params);
      const url = generatePath(ADDFINANCIALDECISION, { student_params: params  })
      axios.get(url).then();
    }

    if( Object.keys(collegeFinancialPackage.financial_aid_package).length != 0 ){
      let student_params_package = {
        financial_aid_package_attributes: {
          id: collegeFinancialPackage.financial_aid_package.id,
          negotiate_financial_aid_package: collegeFinancialPackage.financial_aid_package.negotiate_financial_aid_package,
          financial_aid_package_amount: collegeFinancialPackage.financial_aid_package.financial_aid_package_amount,
          selected_college_id: collegeFinancialPackage.financial_aid_package.selected_college_id
        }
      }
      let params_package = JSON.stringify(student_params_package);
      const url = generatePath(ADDFINANCIALDECISION, { student_params: params_package  })
      axios.get(url).then();
    }
    window.location.href = '/students/profiles/contact_info';
  }
  
  const financialAidEvent = (value, container) => {
    if( value === 'true' ){
      if(container === 'earn_private_scholarship' || container === 'earn_merit_scholarship') {
        if(container === 'earn_private_scholarship') {
          collegeFinancialPackage.college_decision.earn_private_scholarship = value
          setCollegeFinancialPackage(collegeFinancialPackage)
        }
        else{
          collegeFinancialPackage.college_decision.earn_merit_scholarship = value
          setCollegeFinancialPackage(collegeFinancialPackage)
        }
      }
      else{
        collegeFinancialPackage.financial_aid_package.negotiate_financial_aid_package = value
        setCollegeFinancialPackage(collegeFinancialPackage)
      }
      document.getElementById(container).style.setProperty("display","block","important");

    }
    else{
      if(container === 'earn_private_scholarship' || container === 'earn_merit_scholarship') {
        if(container === 'earn_private_scholarship') {
          collegeFinancialPackage.college_decision.earn_private_scholarship = value
          collegeFinancialPackage.college_decision.private_scholarships_number = 0
          collegeFinancialPackage.college_decision.private_scholarship_amount = 0
          setCollegeFinancialPackage(collegeFinancialPackage)
        }
        else{
          collegeFinancialPackage.college_decision.earn_merit_scholarship = value
          collegeFinancialPackage.college_decision.merit_scholarship_amount = 0      
          setCollegeFinancialPackage(collegeFinancialPackage)
        }
      }
      else{
        collegeFinancialPackage.financial_aid_package.negotiate_financial_aid_package = value
        collegeFinancialPackage.financial_aid_package.financial_aid_package_amount = 0
        collegeFinancialPackage.financial_aid_package.selected_college_id = null
        setCollegeFinancialPackage(collegeFinancialPackage)
      }
      document.getElementById(container).style.setProperty("display","none","important");
    }
  }

  return (
    <div>
      {(() => {
        if (option == 'Financial Package') {
          return (
          <div className="row p-0">
           <div className="safe-subtitle col-lg-12 col-md-12">
            <span>Financial Aid</span>
          </div>
          <div className="safe-subtitle-body">
            <div className="college-decision-space">
              <div className="row page--font-color m-0 safe-subgroup">
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 '>
              <div className="row page--font-color pl-0" style={{marginBottom:'20px'}}>
                <div className="col-lg-12 pl-0">
                  <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 mb-10'>
                    <div className='col-lg-10 col-md-10 col-sm-8 col-xs-8 pl-0'>
                      <label> 1. Did you successfully appeal or negotiate your financial aid package?</label>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-xs-4 pl-0'>
                      <select 
                          onChange={(event) => financialAidEvent(event.target.value, 'negotiate_financial_aid_package')} 
                          value={ ( collegeFinancialPackage.financial_aid_package.negotiate_financial_aid_package != undefined ? 
                          collegeFinancialPackage.financial_aid_package.negotiate_financial_aid_package : false )}
                      >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
               <div 
                className="row" id="negotiate_financial_aid_package"  
                style={{display: ( collegeFinancialPackage.financial_aid_package.negotiate_financial_aid_package != undefined ? 
                collegeFinancialPackage.financial_aid_package.negotiate_financial_aid_package : false) ? 'block' : 'none'  }}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                   <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-12">
                      <Row className="pb-15">
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 pl-0 package-input">
                          <Input
                            className="no-border-radius no-box-shadow mb-0"
                            placeholder="Select college"
                            value={collegeName}
                            onChange={(event) => handleChange(event.target.value)}
                            onFocus={() => setShowSuggestions(true)}
                            onBlur={() => setShowSuggestions(false)}
                            type="text"
                          />
                        </div>
                      </Row>
                      <SuggestionsDecision 
                        value={collegeName} 
                        showSuggestions={showSuggestions} 
                        suggestions={suggestions} 
                        option={option} 
                        student={student}  />
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-0  package-amount">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <label> College </label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-start">
                        <label>Package Amount Per Year</label>
                     </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-0">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    { financialDecision.name ? (
                      <p>{ financialDecision.name }</p>
                      )  : (<p></p>)}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-start">
                    <span className="currencyinput-search">$</span>
                      <Input
                        min="0" 
                        className="input-component space-input"
                        onChange={(event) => addFinancialDecision(event.target.value, 'package')}
                        defaultValue ={ collegeFinancialPackage.financial_aid_package.financial_aid_package_amount != undefined ? 
                        collegeFinancialPackage.financial_aid_package.financial_aid_package_amount  : '' }
                        type="number"
                        placeholder="0.00"
                        width="100"
                       /> 
                     </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 '>
              <div className="row page--font-color pl-0" style={{marginBottom:'20px'}}>
                <div className="col-lg-12 pl-0">
                  <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0'>
                    <div className='col-lg-10 col-md-10 col-sm-8 col-xs-8 pl-0'>
                      <label> 2. Did you earn merit scholarship from your school of choice?</label>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-xs-4 pl-0'>
                      <select 
                        onChange={(event) => financialAidEvent(event.target.value, 'earn_merit_scholarship' )} 
                        value={ ( collegeFinancialPackage.college_decision.earn_merit_scholarship != undefined ? 
                        collegeFinancialPackage.college_decision.earn_merit_scholarship : false )}>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
              <div className="row" id="earn_merit_scholarship"  
              style={{display: collegeFinancialPackage.college_decision.earn_merit_scholarship ? 'block' : 'none'  }}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0">
                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <p> Merit Scholarship Amount Per Year</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-start">
                    <span className="currencyinput-search">$</span>
                    <Input
                        type="number"
                        min="0"
                        className="input-component space-input"
                        onChange={(event) => addFinancialDecision(event.target.value, 'merit')}
                        defaultValue={ collegeFinancialPackage.college_decision.merit_scholarship_amount != undefined ? collegeFinancialPackage.college_decision.merit_scholarship_amount  : '' }
                        placeholder="0.00"
                        width="100%"
                      />
                     </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className="row page--font-color pl-0" style={{marginBottom:'20px'}}>
                <div className="col-lg-12 pl-0">
                  <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0'>
                    <div className='col-lg-10 col-md-10 col-sm-8 col-xs-8 pl-0'>
                      <label>3. Did you earn private scholarships?</label>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-xs-4 pl-0'>
                      <select 
                        onChange={(event) => financialAidEvent(event.target.value, 'earn_private_scholarship' )} 
                        value={ collegeFinancialPackage.college_decision.earn_private_scholarship != undefined ? collegeFinancialPackage.college_decision.earn_private_scholarship : false  }>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
              <div className="row" id="earn_private_scholarship"  
                style={{display: ( collegeFinancialPackage.college_decision.earn_private_scholarship != undefined ? 
                collegeFinancialPackage.college_decision.earn_private_scholarship: false ) ? 'block' : 'none'  }}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0">
                  <div className="row col-lg-8 col-md-8 col-sm-8 col-xs-8">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <p> Number of private scholarship</p>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <Input
                        min="0"
                        className="input-component space-input"
                        onChange={(event) => addFinancialDecision(event.target.value, 'number')}
                        defaultValue={ collegeFinancialPackage.college_decision.private_scholarships_number != null ? 
                        collegeFinancialPackage.college_decision.private_scholarships_number  : '' }
                        type="number"
                        placeholder="0"
                        width="100%"
                      />
                    </div>
                    </div><div className="row col-lg-8 col-md-8 col-sm-8 col-xs-8">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <p> Private Scholarship Amount Per Year</p>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <span className="currencyinput-search">$</span> 
                      <Input
                        min="0" 
                        className="input-component space-input"
                        onChange={(event) => addFinancialDecision(event.target.value, 'private')}
                        defaultValue ={ collegeFinancialPackage.college_decision.private_scholarship_amount != undefined ? collegeFinancialPackage.college_decision.private_scholarship_amount  : '' }
                        type="number"
                        placeholder="0.00"
                        pattern="[0-9*]"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
          </div>
          <div className="row">
            <div className="col-lg-8"></div>
            <div className="col-lg-4 pull-right">
              <button className="button-default space-btw" onClick={() => backTo()}> BACK </button>
              <button className="button--primary-blue"  onClick={() => saveCollegeDecision()}>SAVE</button>
            </div>
          </div>
          </div>

          )
      }
      else{

      }
      })()
      }
    </div>
  );
}
export default FinancialCollegeDecision