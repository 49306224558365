import axios from "axios"
import { generatePath } from "react-router"
import {
  FILTERMILESTONEDDATA
} from "../apiConstants"

export const filterDataBy = (store, params) => {
  const hostUrl = generatePath(FILTERMILESTONEDDATA, { filter_data_params: JSON.stringify(params) })

  return axios.get(hostUrl, {headers: {
    'Accept': 'application/json'
  }})
}


export const handleStoreChange = (store, key, value) => {
    store.setState({
      milestoneFilters: {
        ...store.state.milestoneFilters,
        [key]: value
      }
    })
}