import React from "react"
import {
  Col,
  Row,
} from "reactstrap"
import { generatePath } from "react-router"
import { COLLEGEPAGE } from "../../../hooks/apiConstants"

const Suggestions = ({ value, showSuggestions, suggestions }) => {
  return (
    <Row>
      <Col lg={9} className="no-padding">
        {
          !!value &&
          value.length >= 3 &&
          showSuggestions &&
          suggestions.length > 0 &&
          <div className="d-flex">
            <ul className="suggestions-container">
              {
                suggestions.map((college, index) => {
                  const showCollegeUrl = generatePath(COLLEGEPAGE, { id: college.id })
                  return (
                    <li key={index} className="option" onMouseDown={() => (window.sessionStorage.setItem('isCollegeSearchComponent', true), window.location = showCollegeUrl)}>
                      <div>
                        <span>{college.name}</span>
                        <br />
                        <span>
                          {
                            !!college.details &&
                            !!college.details.city &&
                            !!college.details.state &&
                            college.details.city + ", " + college.details.state
                          }
                        </span>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        }
      </Col>
    </Row>
  )
}
export default Suggestions
