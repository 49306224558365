import React, { useState, useEffect } from "react";
import useGlobal from "../../../hooks/store";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";

function ClearFiltersSection({
  handleFilteredStates,
  handleFilteredData,
  section,
  employee,
}) {
  const [globalState, globalActions] = useGlobal();
  const { pagination } = globalState.dashboard;
  const { handleStoreChange } = globalActions.dashboardFilters;
  const { handleStoreChangeDashboard } = globalActions.dashboard;
  const filterData = (options, filterType) => {
    globalActions.dashboard
      .filterDataBy({
        ...options,
        section: section,
        page: pagination.page,
        per_page: pagination.amountPerPage,
      })
      .then((response) => {
        handleFilteredData(response.data.records, filterType);
        handleStoreChangeDashboard("pagination", {
          page: response.data.pagy.page,
          amountPerPage: pagination.amountPerPage,
          next: response.data.pagy.next,
          prev: response.data.pagy.prev,
          totalPages: response.data.pagy.pages,
          totalData: response.data.pagy.count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const clearFilters = () => {
    handleStoreChange("searchValue", "");
    handleStoreChange("gradYear", "");
    handleStoreChange("haveSetCurrentEmployee", true);
    handleStoreChange("counselorId", employee);
    handleStoreChange("programNames", "");
    handleStoreChange("daysSinceLastMeeting", "");
    handleStoreChange("nextContactDate", "");
    handleStoreChange("nextMeetingDate", "");
    handleStoreChange("selectedButton", "");
    handleStoreChange("filterSelected", false);
    handleStoreChange("dropdownOptionSelected", "");
    handleStoreChange("nextBookedWorkshopName", "");
    handleStoreChange("nextBookedWorkshopDate", "");
    handleStoreChange("programSelect", "");
    filterData(
      {
        filter_by_counselor: employee,
        search_by_text: "",
        filter_by_grad_year: "",
        filter_by_last_meeting: "",
        filter_by_next_contact_date: "",
        filter_by_next_meeting: "",
        filter_by_next_booked_workshop_name: "",
        filter_by_next_booked_workshop_date: "",
        filter_by_program: "",
        filter_by_programs: "",
      },
      ""
    );
  };

  return (
    <>
      <div className="left-menu-button">
        <button className="clear-filters-link-buttons" onClick={clearFilters}>
          Reset Filters
        </button>
      </div>
    </>
  );
}

export default ClearFiltersSection;
