import React, { useState, useEffect } from 'react';
import {
  ButtonDropdown ,
} from "reactstrap"
import useGlobal from "../../../hooks/store/index"

const CollegeDropdown = () => {
  const [globalState, globalActions] = useGlobal()
  const { collegesList, collegesToCompare } = globalState.student
  const [currentList, setCurrentList] = useState([])
  

  useEffect(() => {
    setCurrentList(collegesList.filter(collegeList => (collegeList.list_type === 'my_colleges' ? collegeList : null)))
  }, [collegesList])

  const addCollegeToCompareList = (college) => {
    if(collegesToCompare.length < 6) {
      globalActions.student.addCollegeToCompareList(college);
    } else {
      alert("You have reached the maximun amount of colleges allowed!!");
    }
  }

  return (
    <div className="dropdown" style={{height:50}}>
      <ButtonDropdown 
        className="btn dropdown-toggle" 
        type="button" 
        id="dropdownMenu1" 
        data-toggle="dropdown"
        style={{
          textAlign: 'left',
          textTransform: 'none',
          width: '100%',
          backgroundColor: "white",
          color: "#aeadb0",
          borderColor: "#d1d1d1",
          borderRadius: 0,
          boxShadow: "0px 3px 6px 1px #d1d1d1"
        }}
      >
        Choose from your list
        <span className="caret" style={{marginLeft: '60%', color:"black"}}></span>
      </ButtonDropdown>
      <ul className="dropdown-menu suggestions-container" role="menu" aria-labelledby="dropdownMenu1">
        {
          currentList.map((collegeList, index) => {
            const college = collegeList.college
            return (
              <li key={index} className="option" onMouseDown={() => (addCollegeToCompareList(college))}>
                <div>
                  <span>{college.name}</span>
                  <br />
                  <span>
                    {
                      !!college.city_and_state &&
                      college.city_and_state
                    }
                  </span>
                </div>
              </li>
            )
          })
        }
      </ul>
    </div>
  );
}

export default CollegeDropdown;