import React from 'react';
import Badge from './Badge';

const TopColleges = ({ data, student, studentCollegeListIndexPath, searchStudentCollegeListIndexPath }) => {
  return (
    <div className='card_student_dashboard'>
      <div className="card__header_sidebar">
        <h2 className="title_student_dashboard">Top Colleges</h2>
        <a href={studentCollegeListIndexPath}>See all</a>
      </div>

      {data && data.length > 0 ? (
        data.map((college, index) => (
          <div className="top_college_item">
            <Badge text={index+1} color="rgb(188, 163, 238)" />
            <div>
              <h3 className="subtitle_student_dashboard">{college.college.name}</h3>
              <h5>{college.college.city_and_state}</h5>
            </div>
          </div>
        ))
      ) : (
        <div className="card__footer text-center">
          <p className="college_info">There are no colleges on your list</p>
          <a href={searchStudentCollegeListIndexPath}>Add Colleges to your List</a>
        </div>
      )}
    </div>
  );
}

export default TopColleges;

