import React, { useState } from "react";
import EmailModal from "./EmailModal";
const EmailModalWrapper = (props) => {
  const {
    globalActions,
    totalData,
    filters,
    section,
    setShowSuccessMessage,
    setShowErrorMessage,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [massEmailStudents, setMassEmailStudents] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const openModal = () => setIsModalOpen(!isModalOpen);
  const sendEmail = async (selectedStudents) => {
    const sanitizedSubject = subject.endsWith(".")
      ? subject.slice(0, -1)
      : subject;
    const sanitizedBody = body.endsWith(".") ? body.slice(0, -1) : body;
    try {
      await globalActions.dashboard.sendMassEmailStudents({
        student_ids: selectedStudents,
        subject: sanitizedSubject,
        body: sanitizedBody,
      });
      setMassEmailStudents(selectedStudents);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
      console.error("There was an error sending the e-mails", error);
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 3000);
    }
  };
  return (
    <>
      <EmailModal
        ariaHideApp={false}
        openModal={openModal}
        isOpen={isModalOpen}
        onRequestClose={openModal}
        subject={subject}
        setSubject={setSubject}
        body={body}
        setBody={setBody}
        sendEmail={sendEmail}
        totalData={totalData}
        filters={filters}
        section={section}
      />
    </>
  );
};
export default EmailModalWrapper;
