export function getData(currentList, collegeDetails) {
    const collegeList = [];
    const linksCollegesPFA = {};
    const DASH = "-";
    const NOT_APPLICABLE = "n/a";
    const NA = "X";

    const parseDate = (date) => !isNaN(Date.parse(date)) ? (new Date(date)).toLocaleDateString('en-US') : DASH;

    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        useGrouping: true,
    });
    const formatCurrency = ({ cost }) => cost > 0 ? dollarUS.format(cost) : DASH;

    const verifyData = (sentence) => sentence ? sentence : DASH;

    const formatValue = (value) => {
        if (value === '' || value === null  || value === undefined) {
            return '';
        } else {
            return value;
        }
    }

    const validateDeadline = (college) => {
        switch (college.deadline) {
            case 'early_decision':
                return college.ed_deadline ? college.ed_deadline : DASH;
            case 'early_action':
                return college.ea_deadline ? college.ea_deadline : DASH;
            case 'regular_decision':
                return college.regular_admission_deadline ? college.regular_admission_deadline : DASH;
            default:
                return DASH;
        }
    };

    const sortJSON = (data, key, orden) => {
        return data.sort(function (a, b) {
            var x = a[key],
                y = b[key];
            if (orden === "asc") {
                return x < y ? -1 : x > y ? 1 : 0;
            }
            if (orden === "desc") {
                return x > y ? -1 : x < y ? 1 : 0;
            }
        });
    };

    const collegeMap = collegeDetails.reduce((acc, detail) => {
        acc[detail.id] = detail;
        return acc;
    }, {});

    let rankCounter = 1;
    const formattedCollegeList = currentList.map(({ priority_type, college }) => {

        const {
            id,
            ranking,
            name,
            city_and_state,
            num_undergrads,
            act_low,
            act_high,
            sat_low,
            sat_high,
            acceptance_rate,
            test_optional,
            regular_admission_deadline,
            ea_deadline,
            in_state_cost,
            out_of_state_cost
        } = college;

        const collegeDetail = collegeMap[id];

        const actSat = `
            ${act_low ? act_low.toLocaleString("en-US") : "0"}/${act_high ? act_high.toLocaleString("en-US") : NA}
            ${sat_low ? sat_low.toLocaleString("en-US") : "0"}/${sat_high ? sat_high.toLocaleString("en-US") : NA}
        `;

        const detail = collegeDetail.application_methods.length ? collegeDetail.application_methods.join('\n') : DASH;
        const formattedDeadlines = collegeDetail.deadlines.join("\n");

        const formattedRanking = ranking ? verifyData(ranking) : (rankCounter++).toString();

        return [
            formattedRanking,
            verifyData(name),
            verifyData(city_and_state),
            "",
            "",
            verifyData(num_undergrads),
            verifyData(priority_type),
            actSat,
            typeof acceptance_rate === 'number' ? acceptance_rate + "%" : NOT_APPLICABLE,
            detail,
            formattedDeadlines,
            parseDate(college.target_submission),
            verifyData(collegeDetail.num_essays_est),
            formatValue(collegeDetail.letters_of_recommendation),
            "",
            verifyData(test_optional),
            formatValue(collegeDetail.superscore),
            "",
            verifyData(collegeDetail.test_scores_preference),
            `\n${verifyData(regular_admission_deadline)} (Regular)\n${verifyData(ea_deadline)} (Priority)\n`,
            formatValue(collegeDetail.css_profile_required),
            formatCurrency({ cost: in_state_cost }),
            formatCurrency({ cost: out_of_state_cost })
        ];
    });

    formattedCollegeList.forEach((collegeData) => {
        const collegeId = collegeData[0];
        const collegeName = collegeData[1];
        if (collegeId && collegeName) {
            linksCollegesPFA[collegeName] = collegeId;
        }
    });

    return {
        getD: sortJSON(formattedCollegeList, "ranking", "asc"),
        linksCollegesPFA
    };

}
