// ==== STUDENT ACTIONS ===
// COLLEGES ACTIONS
export const COLLEGEPAGE = "/college_list/:id";
export const UPDATEPRIORITYTYPE =
  "/college_list/update_priority_type.json?id=:id&name=:name";
export const UPDATELISTTYPE =
  "/college_list/update_list_type.json?id=:id&name=:name";
export const REMOVECOLLEGEFROMLIST =
  "/college_list/remove_from_list.json?id=:id";
export const SEARCHBYNAME =
  "/college_list/filter.json?search_value=:value&page=:page";
export const FILTERCOLLEGES =
  "/college_list/filter.json?states=:states&range=:range&education=:education&gpa=:gpa&acceptance=:acceptance&retention=:retention&needmet=:needmet&page=:page&include2yr=:include2yr&duration=:duration";
export const COLLEGESUGGESTIONS =
  "/college_list/search_suggestions.json?search_value=:name";
export const ADDCOLLEGETOLIST =
  "/college_list/add_to_list.json?id=:id&name=:name";
export const ADDCOLLEGETOCOMPARISON =
  "/college_list/add_to_comparison.json?college_id=:college_id";
export const ADDCOLLEGETOFINALDECISION =
  "/add_college_to_final_decision/:student_id/:college_id";
export const COLLEGEDECISIONLIST = "/colleges/college_decision_list";
export const COLLEGEFINALDECISION = "/student_final_decision/";
export const COLLEGEFINANCIALDECISION = "/student_financial_decision/";
export const ADDACCEPTEDCOLLEGES = "/add_accepted_colleges/:accepted_colleges";
export const ACCEPTEDCOLLEGESLIST = "/accepted_colleges_list/";
export const ADDAREJECTEDCOLLEGES = "/add_rejected_colleges/:rejected_colleges";
export const REJECTEDCOLLEGESLIST = "/rejected_colleges_list/";
export const ADDWAITLISTEDCOLLEGES =
  "/add_waitlisted_colleges/:waitlisted_colleges";
export const WAITLISTEDCOLLEGESLIST = "/waitlisted_colleges_list/";
export const ADDCOLLEGETOFINANCIALPACKAGE =
  "/add_college_to_financial_package/:student_id/:college_id";
export const COLLEGEFINANCIALPACKAGE = "/college_financial_package/";
export const REMOVEACCEPTEDCOLLEGE =
  "/remove_accepted_colleges/:accepted_college";
export const REMOVEREJECTEDCOLLEGE =
  "/remove_rejected_colleges/:rejected_college";
export const REMOVEWAITLISTEDCOLLEGE =
  "/remove_waitlisted_colleges/:waitlisted_college";
export const ADDFINANCIALDECISION = "/add_financial_aid/:student_params";
export const UPDATERANKINGCOLLEGELIST =
  "/college_list/set_ranking_to_colleges.json?new_rankings=:new_rankings*";
export const ADDTARGETSUBMISSIONDATETOCOLLEGE =
  "/college_list/set_target_submission_date.json?id=:id&date=:date?";
export const UPDATEAPPMODEPREFERENCE =
  "/college_list/set_app_mode_preference.json?appMode=:appMode?";
export const UPDATEDEADLINECOLLEGELIST =
  "/college_list/set_deadline.json?id=:id&deadline=:deadline?";
export const FILTERDASHBOARDDATA =
  "/backend/dashboard/filter_data/:filter_data_params";
export const STUDENTDASHBOARDDATA =
  "/backend/dashboard/student_information/:get_data_student";
export const STUDENTMASSEMAIL = "/send_student_emails";
export const FILTERMILESTONEDDATA =
  "/backend/milestone_report/filter_data/:filter_data_params";
export const FILTERESSAYCOACHPERFORMANCEDATA =
  "/backend/essay_coach_performance_report/filter_data/:filter_data_params";
export const FILTERTUTORREPORTDATA =
  "/backend/tutor_manager_report/tutor_information";
export const FILTERCOUNSELORPERFORMANCEDATA =
  "/backend/counselor_performance_report/filter_data/:filter_data_params";
  export const FILTERHEALTHYCONTACT =
  "/backend/client_load_and_healthy_contact_report/get_data/:filter_data_params";
export const COLLEGEFINALDECISIONADMINVIEW =
  "/student_final_decision_admin_view/:id";
export const COLLEGEFINANCIALDECISIONADMINVIEW =
  "/student_financial_decision_admin_view/:id";
export const ACCEPTEDCOLLEGESLISTADMINVIEW =
  "/accepted_colleges_list_admin_view/:id";
export const WAITLISTEDCOLLEGESLISTADMINVIEW =
  "/waitlisted_colleges_list_admin_view/:id";
export const REJECTEDCOLLEGESLISTADMINVIEW =
  "/rejected_colleges_list_admin_view/:id";
export const COLLEGEFINANCIALPACKAGEADMINVIEW =
  "/college_financial_package_admin_view/:id/:college_id";
export const REMOVEACCEPTEDCOLLEGEADMINVIEW =
  "/remove_accepted_colleges_admin_view/:accepted_college/:id";
export const REMOVEWAITLISTEDCOLLEGEADMINVIEW =
  "/remove_waitlisted_colleges_admin_view/:waitlisted_colleges/:id";
export const REMOVEREJECTEDCOLLEGEADMINVIEW =
  "/remove_rejected_colleges_admin_view/:rejected_colleges/:id";
export const ADDCOLLEGETOFINALDECISIONADMINVIEW =
  "/add_college_to_final_decision_admin_view/:id/:college_id";
export const ADDACCEPTEDCOLLEGESADMINVIEW =
  "/add_accepted_colleges_admin_view/:accepted_colleges/:id";
export const ADDWAITLISTEDCOLLEGESADMINVIEW =
  "/add_waitlisted_colleges_admin_view/:waitlisted_colleges/:id";
export const ADDREJECTEDCOLLEGESADMINVIEW =
  "/add_rejected_colleges_admin_view/:rejected_colleges/:id";
export const ADDCOLLEGETOFINANCIALPACKAGEADMINVIEW =
  "/add_college_to_financial_package_admin_view/:id/:college_id";
export const ADDFINANCIALDECISIONADMINVIEW =
  "/add_financial_aid_admin_view/:student_params/:id";
export const COLLEGESUGGESTIONSADMINVIEW =
  "/search_suggestions_admin_view.json?search_value=:name?&student_id=:student_id";
export const SAVEINFORMATION = "/save_information_admin_view";
