import React from "react";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import GeneralInformationTable from "./GeneralInformationTable";
function GeneralInformation({
  generalInfoStudents,
  counselor_list,
  grad_year_list,
  next_booked_workshop_name_list,
  employee,
  paginationData,
  program_list,
}) {
  return (
    <div>
      <h1 className="dashboard-title">Counselor Dashboard</h1>
      <GeneralInformationTable
        studentData={generalInfoStudents}
        counselor_list={counselor_list}
        grad_year_list={grad_year_list}
        next_booked_workshop_name_list={next_booked_workshop_name_list}
        employee={employee}
        paginationData={paginationData}
        program_list={program_list}
      />
    </div>
  );
}

export default GeneralInformation;
