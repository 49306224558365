import React from "react";
import { Row, Col, Table } from "reactstrap";


const ShowColumnData = (obj, columnsLenght, header = false) => {

    return <div>
        <Row className={`mx-1 mt-2 ${header ? "bg-light" : ""}`}>
            {
                obj.map((element, i) => {
                    return <Col key={i} className="d-inline-block" sm={columnsLenght[i]} md={columnsLenght[i]} lg={columnsLenght[i]}>
                        <p id="column-row" className={`${i == 0 ? "text-left college-profile-space-tr" : "text-center college-profile-space-tr"} ${i != 0 && !header ? "props-font" : ""}`} >{element}</p>
                    </Col>
                })
            }
        </Row>
        {!header ? <hr className="mt-0"></hr> : <></>}
    </div>

}


const CollegeTableShow = ({ title, headers, columns, columnsLenght }) => {
    return <>
        <div className="safe-subtitle-body">
            <div className="mx-2">
                <label className="control-label">
                    {title}
                </label>
                <div className="table-size">
                    <div className="scroll-horizontal">
                        {
                            ShowColumnData(headers, columnsLenght, true)
                        }
                        {
                            columns.map((list, i) => {

                                return (
                                    <div key={i}>
                                        {ShowColumnData(list, columnsLenght)}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}


export default CollegeTableShow;