import React, { useState, useEffect } from "react"
import Category from "./Category"
import useGlobal from "../../../hooks/store"

const ListTypeMenu = ({ collegeId, arrayCategoriesType, title, collegeIsInList }) => {
  const [globalState, globalActions] = useGlobal()
  const { collegesList } = globalState.student

  const [showInfo, setShowInfo] = useState(false)
  const [inList, setInList] = useState(collegeIsInList)

  useEffect(() => {
    collegesList === null ? setShowInfo(false) : setShowInfo(true) 
  }, [collegesList])

  const updateListType = (type) => {
    if (showInfo) {
      if (inList) {
        globalActions.student.updateListTypeToCollege(type, collegeId)
      } else {
        globalActions.student.addCollegeToList(type, collegeId)
        setInList(true)
      }
    }
  }

  const isSelected = (type) => {
    let exists = false
    collegesList.map(collegeList => {
      if (collegeList.college.id === collegeId && collegeList.list_type === type) {
        exists = true
      }
    })
    return exists
  }

  return (
    <div className="js--adminDropdownMenu" data-college={collegeId}>
      <li className="col-xs-12 text-center">
        <strong className="font--college-list font--Maxx-bold">{title}</strong>
      </li>
      {
        arrayCategoriesType.map((type, index) => {
          return (
            <Category
              key={index}
              collegeId={collegeId}
              description={type.name}
              checked={isSelected(type.name)}
              label={type.label}
              handleClick={() => updateListType(type.name)}
            />
          )
        })
      }
    </div>
  )
}

export default ListTypeMenu
