import React, { useEffect, useState } from "react";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import {
  TAB_GENERAL_INFORMATION,
  TAB_TASKS,
  TAB_WORKSHOPS,
  TAB_TUTOR,
  TAB_ESSAY_COACH,
} from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import { faCompass } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { StatusIcon, StudentStatusIcon } from "./StatusIcon";

function Menu({ handleShowGeneralInfo, section }) {
  const [activeButton, setActiveButton] = useState();

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };

  const buttonStyle = {
    backgroundColor: "white",
  };

  const buttonStyleActive = {
    backgroundColor: "#f0f0f0",
  };

  useEffect(() => {
    // Set the state based on the value of 'section'
    if (section === "general_info") {
      setActiveButton(TAB_GENERAL_INFORMATION);
    } else if (section === "tutor") {
      setActiveButton(TAB_TUTOR);
    } else if (section === "essay_coach") {
      setActiveButton(TAB_ESSAY_COACH);
    }
  }, [section]);

  return (
    <div className="menuDashboard">
      <button
        className="left-menu-button"
        style={
          activeButton === TAB_GENERAL_INFORMATION
            ? buttonStyleActive
            : buttonStyle
        }
        onClick={() => {
          handleShowGeneralInfo(TAB_GENERAL_INFORMATION);
          handleButtonClick(TAB_GENERAL_INFORMATION);
        }}
      >
        <FontAwesomeIcon icon={faFileAlt} className="icon-button" />
        Counselor Dashboard
      </button>
      <button
        className="left-menu-button"
        style={activeButton === TAB_TASKS ? buttonStyleActive : buttonStyle}
        onClick={() => {
          handleShowGeneralInfo(TAB_TASKS);
          handleButtonClick(TAB_TASKS);
        }}
      >
        <FontAwesomeIcon icon={faLightbulb} className="icon-button" />
        Tasks
      </button>
      <button
        className="left-menu-button"
        style={activeButton === TAB_WORKSHOPS ? buttonStyleActive : buttonStyle}
        onClick={() => {
          handleShowGeneralInfo(TAB_WORKSHOPS);
          handleButtonClick(TAB_WORKSHOPS);
        }}
      >
        <FontAwesomeIcon icon={faListAlt} className="icon-button" />
        Workshops
      </button>
      <button
        className="left-menu-button"
        style={
          activeButton === TAB_ESSAY_COACH ? buttonStyleActive : buttonStyle
        }
        onClick={() => {
          handleShowGeneralInfo(TAB_ESSAY_COACH);
          handleButtonClick(TAB_ESSAY_COACH);
        }}
      >
        <FontAwesomeIcon icon={faClipboard} className="icon-button" />
        Essay Coach
      </button>
      <button
        className="left-menu-button"
        style={activeButton === TAB_TUTOR ? buttonStyleActive : buttonStyle}
        onClick={() => {
          handleShowGeneralInfo(TAB_TUTOR);
          handleButtonClick(TAB_TUTOR);
        }}
      >
        <FontAwesomeIcon icon={faCompass} className="icon-button" />
        Tutor
      </button>
      <hr className="left-menu-divider" />

      <StudentStatusIcon className="left-menu-button" />

      <hr className="left-menu-divider" />

      {(activeButton === TAB_TASKS || activeButton === TAB_WORKSHOPS) && (
        <StatusIcon />
      )}
    </div>
  );
}
export default Menu;
