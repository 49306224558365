import React, { useState, useEffect } from "react";
import { Input, Row } from "reactstrap";
import useGlobal from "../../../../hooks/store/index";
import SuggestionsDecision from "./SuggestionsDecision";
import CollegeDecision from "./CollegeDecision";
import { fetchAcceptedColleges, fetchRejectedColleges, fetchCollegeFinalDecision, fetchCollegeFinancialDecision, fetchCollegeFinancialPackage, fetchWaitedColleges } from "../../../../hooks/actions/collegeDecision";

const CollegeDecisionInfo = ({ student, option }) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const { suggestions } = globalState.college;
  const [collegeName, setCollegeName] = useState("");
  const { setAcceptedColleges, setRejectedColleges, setWaitlistedColleges, setCollegeFinancialPackage, handleStoreChange, setClearInput } = globalActions.collegeDecision;
  const { collegeFinancialPackage, clearInput } = globalState.collegeDecision;

  useEffect(() => {
    if (clearInput) {
      setCollegeName("");
    }
  }, [clearInput]);

  useEffect(() => {
    if (option === "Accepted Colleges") {
      fetchAcceptedColleges(student.id, setAcceptedColleges);
      fetchCollegeFinalDecision(student.id, globalActions.collegeDecision.setFinalDecision);
      fetchCollegeFinancialDecision(student.id, globalActions.collegeDecision.setFinancialDecision);
      fetchCollegeFinancialPackage(student.id, collegeFinancialPackage.college, setCollegeFinancialPackage);
    } else if (option == 'Waitlisted Colleges'){
      fetchWaitedColleges(student.id, setWaitlistedColleges);
    } else if (option == 'Rejected Colleges'){
      fetchRejectedColleges(student.id, setRejectedColleges);
    }
  }, []);

  const handleChange = (value) => {
    setClearInput(false);
    setCollegeName(value);
    if (value.length >= 3) {
      setTimeout(() => {
        globalActions.college.getSuggestionsAdminView({ name: value }, { student_id: student.id });
      }, 1000);
    }
  };

  return (
    <div>
      <Row className="pb-15">
        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 pl-0">
          <Input
            id="search-colleges"
            className="no-border-radius no-box-shadow mb-0"
            placeholder="Search college names"
            value={collegeName}
            onChange={(event) => handleChange(event.target.value)}
            onFocus={() => setShowSuggestions(true)}
            onBlur={() => setShowSuggestions(false)}
          />
        </div>
      </Row>
      <SuggestionsDecision value={collegeName} showSuggestions={showSuggestions} suggestions={suggestions} option={option} student={student} />
      <div>
        <CollegeDecision student={student} option={option} />
      </div>
    </div>
  );
};

export default CollegeDecisionInfo;
