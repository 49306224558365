import React, { useState } from "react"
import MainMenu from "./college-filter-menu/MainMenu"
import StateMenu from "./college-filter-menu/StateMenu"
import UndergraduatesRangeMenu from "./college-filter-menu/UndergraduatesRangeMenu"
import EducationTypeMenu from "./college-filter-menu/EducationTypeMenu"
import useGlobal from "../../../hooks/store/index"
import AverageGPAMenu from "./college-filter-menu/AverageGPAMenu"
import AcceptanceRateMenu from "./college-filter-menu/AcceptanceRateMenu"
import RetentionMenu from "./college-filter-menu/RetentionMenu"
import NeedMetMenu from "./college-filter-menu/NeedMetMenu"
import DurationMenu from "./college-filter-menu/DurationMenu"

const CollegeFilter = ({ statesList }) => {
  const [filter, setFilter] = useState("")
  const [globalState, globalActions] = useGlobal()
  const { states, range, education, unweightedGPA, acceptanceRate, retention, needMet, duration } = globalState.college.filters

  const clearFilters = () => {
    globalActions.college.handleStoreChange("filters", {
      states: [],
      range: [],
      education: "",
      unweightedGPA: [],
      acceptanceRate: [],
      retention: [],
      needMet: [],
      duration: []
    })
  }

  const handleChange = (key, value) => {
    globalActions.college.handleStoreChange("filters", { ...globalState.college.filters, [key]: value })
  }

  const showFilterMenu = () => {
    $(".dropdown-options").toggle()
  }

  return (
    <div className="dropdown">
      <button className="btn filter--college-button" type="button" id="dropdownMenu1" aria-haspopup="true" aria-expanded="true" onClick={() => showFilterMenu()}>
        <span className='glyphicon glyphicon-filter'></span>
        <span>FILTERS</span>
      </button>
      <ul className="dropdown-menu dropdown-options" aria-labelledby="dropdownMenu1">
        {
          (filter == "" && <MainMenu clearFilters={() => clearFilters()} setFilter={(filter) => setFilter(filter)} />) ||
          (filter == "state" && <StateMenu allStates={statesList} currentStates={states} setStates={(states) => handleChange("states", states)} setFilter={(filter) => setFilter(filter)} />) ||
          (filter == "undergraduate" && <UndergraduatesRangeMenu currentUndergraduateRange={range} setUndergraduatesRange={(range) => handleChange("range", range)} setFilter={(filter) => setFilter(filter)} />) ||
          (filter == "education" && <EducationTypeMenu currentEducationType={education} setEducationType={(education) => handleChange("education", education)} setFilter={(filter) => setFilter(filter)} />) ||
          (filter == "gpa" && <AverageGPAMenu currentAverageGPARange={unweightedGPA} setAverageGPARange={(unweightedGPA) => handleChange(unweightedGPA)} setFilter={(filter) => setFilter(filter)} />) ||
          (filter == "acceptance" && <AcceptanceRateMenu currentAcceptanceRate={acceptanceRate} setAcceptanceRate={(acceptanceRate) => handleChange(acceptanceRate)} setFilter={(filter) => setFilter(filter)} />) ||
          (filter == "retention" && <RetentionMenu currentRetentionRate={retention} setRetentionRate={(retention) => handleChange(retention)} setFilter={(filter) => setFilter(filter)} />) ||
          (filter == "needmet" && <NeedMetMenu currentNeedMetRate={needMet} setNeedMetRate={(needMet) => handleChange(needMet)} setFilter={(filter) => setFilter(filter)} />) ||
          (filter == "duration" && <DurationMenu currentDuration={duration} setDuration={(duration) => handleChange(duration)} setFilter={(filter) => setFilter(filter)} />)
        }
      </ul>
    </div>
  )
}
export default CollegeFilter
