import React from "react"

const CollegeTableColumns = ({ applicationMode }) => {
  const applicationModeOnColumns = () => {
    return (
      <>
        <th className="college-table-columns">Deadline</th>
        <th className="college-table-columns">Target<br />Submission<br />Date</th>
        <th className="college-table-columns hidden-xs hidden-sm">Acceptance<br />Rate</th>
        <th className="college-table-columns hidden-xs hidden-sm">Test Optional</th>
        <th className="college-table-columns hidden-xs hidden-sm hidden">Requirements</th>
        <th className="college-table-columns hidden-xs hidden-sm">Applications</th>
        <th className="college-table-columns">Financial<br />Deadlines</th>
        <th className="college-table-columns"> </th>
      </>
    )
  }

  const applicationModeOffColumns = () => {
    return (
      <>
        <th className="college-table-columns hidden-xs hidden-sm">Undergrad Enrollment</th>
        <th className="college-table-columns hidden-xs hidden-sm">ACT/SAT 25/75%</th>
        <th className="college-table-columns hidden-xs hidden-sm">In State/Out of State Cost</th>
        <th className="college-table-columns"> </th>
      </>
    )
  }

  return (
    <>
      <th className="college-table-columns">Rank</th>
      <th className="college-table-columns">College</th>
      {applicationMode ? applicationModeOnColumns() : applicationModeOffColumns()}
      <th className="college-table-columns">Counselor</th>
    </>
  )
}

export default CollegeTableColumns
