import React, { useEffect, useState } from "react";
import Menu from "./Menu";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import GeneralInformation from "./GeneralInformation";
import EssayCoach from "./EssayCoach";
import Tasks from "./Tasks";
import {
  TAB_GENERAL_INFORMATION,
  TAB_TASKS,
  TAB_ESSAY_COACH,
  TAB_TUTOR,
} from "./utils";
import { Row, Col } from "reactstrap";
import Workshops from "./Workshops";
import Tutor from "./Tutor";

function DashboardTable({
  studentDataGeneralInfo,
  counselor_list,
  tutor_list,
  essay_coach_list,
  grad_year_list,
  next_booked_workshop_name_list,
  employee,
  pagination_data,
  program_list,
  section
}) {
  const [showGeneralInfo, setShowGeneralInfo] = useState(true);
  const [showTasks, setShowTask] = useState(false);
  const [showTutor, setShowTutor] = useState(false);
  const [showEssayCoach, setShowEssayCoach] = useState(false);

  function handleShowGeneralInfo(tab) {
    setShowGeneralInfo(TAB_GENERAL_INFORMATION == tab ? true : false);
    setShowTask(TAB_TASKS == tab ? true : false);
    setShowTutor(TAB_TUTOR == tab ? true : false);
    setShowEssayCoach(TAB_ESSAY_COACH == tab ? true : false);
  }

  function showSection(section) {
    setShowGeneralInfo(false);
    setShowTask(false);
    setShowTutor(false);
    setShowEssayCoach(false);

    switch (section) {
      case "general_info":
        setShowGeneralInfo(true);
        break;
      case "tasks":
        setShowTask(true);
        break;
      case "essay_coach":
        setShowEssayCoach(true);
        break;
      case "tutor":
          setShowTutor(true);
          break;
      default:
        break;
    }
  }
  
  useEffect(() => {
    showSection(section)
  }, [section]);

  return (
    <Row>
      <Col md={2} lg={2}>
        <Menu handleShowGeneralInfo={handleShowGeneralInfo} section={section} />
      </Col>
      <Col md={10} lg={10} className="dashboard-menu_title">
        {showGeneralInfo ? (
          <GeneralInformation
            generalInfoStudents={studentDataGeneralInfo}
            counselor_list={counselor_list}
            grad_year_list={grad_year_list}
            next_booked_workshop_name_list={next_booked_workshop_name_list}
            employee={employee}
            paginationData={pagination_data}
            program_list={program_list}
          />
        ) : showTutor ? (
          <Tutor
            counselor_list={tutor_list}
            grad_year_list={grad_year_list}
            employee={employee}
            program_list={program_list}
          />
        ) : showEssayCoach ? (
          <EssayCoach
            essayInfoStudents={studentDataGeneralInfo}
            counselor_list={essay_coach_list}
            grad_year_list={grad_year_list}
            employee={employee}
            program_list={program_list}
          />
        ) : showTasks ? (
          <Tasks
            counselor_list={counselor_list}
            grad_year_list={grad_year_list}
            employee={employee}
            program_list={program_list}
          />
        ) : (
          <Workshops
            counselor_list={counselor_list}
            grad_year_list={grad_year_list}
            employee={employee}
            program_list={program_list}
          />
        )}
      </Col>
    </Row>
  );
}

export default DashboardTable;
