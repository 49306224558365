import React, { useState, useEffect, useRef } from "react";

const healthyContact = (counselorData) => {
    const columns = [
      {
        Header: "Healthy Contact",
        accessor: "first_column",
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: "Total Students",
        accessor: "total",
        Cell: ({ value }) => <span>{ value || "-"}</span>,
      },
    ];
  
    counselorData.forEach((counselor) => {
      columns.push({
        Header: counselor.counselor_name,
        accessor: `${counselor.counselor_id}`,
        Cell: ({ value }) => <span>{value !== undefined ? value : "-"}</span>,
      });
    });
  
    return columns;
  }

export { healthyContact };
