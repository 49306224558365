import React, { useState, useEffect } from "react";
import TaskRow from "./TaskRow"; // Importa el componente TaskRow desde el archivo TaskRow.js en la misma carpeta

const Dashboard = ({ taskList }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [upcomingList, setUpcomingList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [springList, setSpringList] = useState([]);
  const [summerList, setSummerList] = useState([]);
  const [fallList, setFallList] = useState([]);
  const [winterList, setWinterList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [noSeasonList, setNoSeasonList] = useState([]);
  const [noSeasonFlag, setNoSeasonFlag] = useState(true);
  const [currentSeason, setCurrentSeason] = useState("");
  const currentYear = new Date().getFullYear();
  const sortTasksByDate = (tasks) => {
    return tasks.sort((a, b) => {
      return new Date(a.dueDate) - new Date(b.dueDate);
    });
  };

  const sortTasksByMoreRecent = (tasks) => {
    return tasks.sort((a, b) => {
      return new Date(b.dueDate) - new Date(a.dueDate);
    });
  };

  const sortYears = (years) => {
    return years.sort((a, b) => {
      return a - b;
    });
  };

  const getMonth = (dueDate) => {
    const date = new Date(dueDate);
    const month = date.getMonth() + 1;
    return month;
  };

  const getFormatedDate = (dateStr) => {
    if (dateStr.includes("T")) {
      const date = new Date(dateStr);
      return date;
    } else {
      const dateComponents = dateStr.split("-");
      const year = parseInt(dateComponents[0]);
      const month = parseInt(dateComponents[1]) - 1;
      const day = parseInt(dateComponents[2]);
      const date = new Date(year, month, day, 12, 0, 0);
      return date;
    }
  };

  const getCurrentSeason = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    if (month >= 2 && month <= 4) {
      return "Spring";
    } else if (month >= 5 && month <= 7) {
      return "Summer";
    } else if (month >= 8 && month <= 10) {
      return "Fall";
    } else {
      return "Winter";
    }
  };

  useEffect(() => {
    const tasksWithNoDate = [];
    const tasksWithDate = [];
    const history = [];
    let springTasks = [];
    let summerTasks = [];
    let fallTasks = [];
    let winterTasks = [];
    const years = [];
    const noSeason = [];

    const current_date = new Date();
    const current_year = current_date.getFullYear();
    const current_month = current_date.getMonth() + 1;

    taskList.forEach((task) => {
      if (
        task.type === "ENROLLMENT" ||
        task.type === "CLOG" ||
        (task.type === "TASK" && task.status === "Completed")
      ) {
        history.push(task);
      } else {
        if (task.dueDate == null) {
          if (task.status === "Ongoing" || task.status === "In progress") {
            tasksWithNoDate.unshift(task);
          } else {
            tasksWithNoDate.push(task);
          }
        } else {
          const date = getFormatedDate(task.dueDate);
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          if (date >= current_date) {
            if (month >= 2 && month <= 4) {
              springTasks.push(task);
            } else if (month >= 5 && month <= 7) {
              summerTasks.push(task);
            } else if (month >= 8 && month <= 10) {
              fallTasks.push(task);
            } else {
              winterTasks.push(task);
            }
          } else {
            noSeason.push(task);
          }
          tasksWithDate.push(task);
          if (year >= current_year && !years.includes(year)) {
            years.push(year);
          }
        }
      }
    });

    setUpcomingList(sortTasksByDate(tasksWithDate).concat(tasksWithNoDate));
    setHistoryList(sortTasksByMoreRecent(history));
    setSpringList(sortTasksByDate(springTasks));
    setSummerList(sortTasksByDate(summerTasks));
    setFallList(sortTasksByDate(fallTasks));
    setWinterList(sortTasksByDate(winterTasks));
    setYearList(sortYears(years));
    setNoSeasonList(sortTasksByDate(noSeason));
    const current_season = getCurrentSeason();
    setCurrentSeason(current_season);
  }, []);

  const getYear = (dueDate) => {
    const date = new Date(dueDate);
    const year = date.getFullYear();
    return year;
  };

  function areTasksToShow(year, season) {
    if (season === currentSeason && noSeasonFlag) {
      return noSeasonList.length > 0;
    } else {
      switch (season) {
        case "Spring":
          return checkTasksToShow(year, springList);
        case "Fall":
          return checkTasksToShow(year, fallList);
        case "Winter":
          return checkWinterTasksToShow(year);
        default:
          return checkTasksToShow(year, summerList);
      }
    }
  }

  function checkTasksToShow(year, list) {
    return list.some((task) => getYear(task.dueDate) === year);
  }

  function checkWinterTasksToShow(year) {
    return winterList.some((task) => {
      const date = getFormatedDate(task.dueDate);
      const month = date.getMonth();
      const yearTask = date.getFullYear();
      return (
        (month === 10 && yearTask === year) || // Nov (month 10) current year
        (month === 11 && yearTask === year) || // Dec (month 11) current year
        (month === 0 && yearTask === year + 1) // Jan (month 0) next year
      );
    });
  }

  const filterTasksByYear = (year, season) => {
    const filterTasksForSeason = (list) => {
      if (season === currentSeason && year <= currentYear) {
        let noSeason = [...noSeasonList];
        list.forEach((task) => {
          if (season === "Winter") {
            const date = getFormatedDate(task.dueDate);
            const month = date.getMonth();
            const yearTask = date.getFullYear();
            if (
              (month === 10 && yearTask === year) || // Nov (month 10) current year
              (month === 11 && yearTask === year) || // Dec (month 11) current year
              (month === 0 && yearTask === year + 1) // Jan (month 0) next year
            ) {
              noSeason.push(task);
            }
          } else {
            if (getYear(task.dueDate) === year) {
              noSeason.push(task);
            }
          }
        });
        return noSeason;
      } else {
        if (season === "Winter") {
          return list.filter((task) => {
            const date = getFormatedDate(task.dueDate);
            const month = date.getMonth();
            const yearTask = date.getFullYear();

            return (
              (month === 10 && yearTask === year) || // Nov (month 10) current year
              (month === 11 && yearTask === year) || // Dec (month 11) current year
              (month === 0 && yearTask === year + 1) // Jan (month 0) next year
            );
          });
        }
        return list.filter((task) => getYear(task.dueDate) === year);
      }
    };
    switch (season) {
      case "Spring":
        return filterTasksForSeason(springList);
      case "Fall":
        return filterTasksForSeason(fallList);
      case "Winter":
        return filterTasksForSeason(winterList);
      default:
        return filterTasksForSeason(summerList);
    }
  };

  return (
    <div>
      <h2 className="title_student_dashboard extra_margin">Activity</h2>
      <div style={{ display: "flex" }}>
        <div
          className={selectedTab === 0 ? "selected_tab" : "unselected_tab"}
          onClick={() => setSelectedTab(0)}
          id="upcoming_tab"
        >
          Upcoming
        </div>
        <div
          className={selectedTab === 1 ? "selected_tab" : "unselected_tab"}
          onClick={() => setSelectedTab(1)}
          id="history_tab"
        >
          History
        </div>
      </div>
      <div
        className="card_student_dashboard"
        style={{
          borderRadius: selectedTab === 0 ? "0px 10px 10px 10px" : "10px",
        }}
      >
        {selectedTab === 0 &&
          yearList.map((year) => (
            <div key={year}>
              {areTasksToShow(year, "Spring") && (
                <h3 className="subtitle_student_dashboard">Spring {year}</h3>
              )}
              {filterTasksByYear(year, "Spring").sort((a, b) => (a.type === "YCBM" ? -1 : b.type === "YCBM" ? 1 : 0)).map((task) => (
                <div key={task.id}>
                  <TaskRow
                    id={task.id}
                    name={task.name}
                    dueDate={task.dueDate}
                    link={task.link}
                    rescheduleLink={task.rescheduleLink}
                    cancelLink={task.cancelLink}
                    type={task.type}
                    tasktype={task.tasktype}
                    conversationLog={task.conversationLog}
                    status={task.status}
                    isHistory={false}
                  />
                </div>
              ))}
              {areTasksToShow(year, "Summer") && (
                <h3 className="subtitle_student_dashboard">Summer {year}</h3>
              )}
              {filterTasksByYear(year, "Summer").sort((a, b) => (a.type === "YCBM" ? -1 : b.type === "YCBM" ? 1 : 0)).map((task) => (
                <div key={task.id}>
                  <TaskRow
                    id={task.id}
                    name={task.name}
                    dueDate={task.dueDate}
                    link={task.link}
                    rescheduleLink={task.rescheduleLink}
                    cancelLink={task.cancelLink}
                    type={task.type}
                    tasktype={task.tasktype}
                    conversationLog={task.conversationLog}
                    status={task.status}
                    isHistory={false}
                  />
                </div>
              ))}
              {areTasksToShow(year, "Fall") && (
                <h3 className="subtitle_student_dashboard">Fall {year}</h3>
              )}
              {filterTasksByYear(year, "Fall").sort((a, b) => (a.type === "YCBM" ? -1 : b.type === "YCBM" ? 1 : 0)).map((task) => (
                <div key={task.id}>
                  <TaskRow
                    id={task.id}
                    name={task.name}
                    dueDate={task.dueDate}
                    link={task.link}
                    rescheduleLink={task.rescheduleLink}
                    cancelLink={task.cancelLink}
                    type={task.type}
                    tasktype={task.tasktype}
                    conversationLog={task.conversationLog}
                    status={task.status}
                    isHistory={false}
                  />
                </div>
              ))}
              {checkWinterTasksToShow(year) && (
                <h3 className="subtitle_student_dashboard">Winter {year}</h3>
              )}
              {filterTasksByYear(year, "Winter").sort((a, b) => (a.type === "YCBM" ? -1 : b.type === "YCBM" ? 1 : 0)).map((task) => (
                <div key={task.id}>
                  <TaskRow
                    id={task.id}
                    name={task.name}
                    dueDate={task.dueDate}
                    link={task.link}
                    rescheduleLink={task.rescheduleLink}
                    cancelLink={task.cancelLink}
                    type={task.type}
                    tasktype={task.tasktype}
                    conversationLog={task.conversationLog}
                    status={task.status}
                    isHistory={false}
                  />
                </div>
              ))}
            </div>
          ))}
        {selectedTab === 1 &&
          historyList.map((task) => (
            <div key={task.id}>
              <TaskRow
                id={task.id}
                name={task.name}
                dueDate={task.dueDate}
                link={task.link}
                rescheduleLink={task.rescheduleLink}
                cancelLink={task.cancelLink}
                type={task.type}
                tasktype={task.tasktype}
                conversationLog={task.conversationLog}
                conversationLogSubject={task.conversationLogSubject}
                conversationLogHour={task.conversationLogHour}
                status={task.status}
                isHistory={true}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Dashboard;
