import React, { useEffect, useState } from 'react';
import MilestoneTable from './MilestoneReportTable';
import {milestonecolumns} from './table_columns/milestone_table_columns';
import { Filters } from './Filters';
import useGlobal from '../../../hooks/store';
import "../../../../assets/stylesheets/backend/milestone_report/milestone_report.scss";

function MilestoneReport({counselor_list, grad_year_list, counselor_id}) {

  const [loading, setLoading] = useState(false);
  const [dataReport, setDataReport] = useState([]);
  const [globalState, globalActions] = useGlobal();
  const { handleStoreChange } = globalActions.milestoneFilters;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filterParams = filterDataParams(
          {
            filter_by_counselor: counselor_id,
            filter_by_grad_year: "",
          }
        );
        filterData(filterParams, "counselor");
        handleStoreChange("counselorId", counselor_id);
      
      } catch (error) {
        console.error('Error fetching data:', error);
      
      }
    };
    fetchData();
  }, []); 

  const filterDataParams = (
    commonFilters
  ) => {
    let filters = { ...commonFilters};
    return filters;
}

  const filterData = (options, filterType) => {
    setLoading(true);
    setDataReport([]);
    globalActions.milestoneFilters
      .filterDataBy({ ...options})
      .then((response) => {
        setDataReport(response.data);
        handleStoreChange("filterType", filterType);
      })
      .catch((error) => {
        console.log(error);
      }) 
      .finally(() => {
        setLoading(false);
      });;
  };
  
  return (
    <div>
      <h1 className='report-title'>Milestone Report</h1>
      <Filters counselor_list={counselor_list} grad_year_list={grad_year_list} filterData={filterData}/>
      <MilestoneTable milestonecolumns={milestonecolumns} data={dataReport} loading={loading}/>
    </div>
  );
  
}

export default MilestoneReport;
