import React, { useState, useEffect } from "react";
import useGlobal from "../../../hooks/store";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";

function NextBookedWorkshopFilters({ handleFilteredData, section, handleFilteredStates, next_booked_workshop_name_list}) {
  const [globalState, globalActions] = useGlobal();
  const {
    nextContactDate,
    nextMeetingDate,
    counselorId,
    searchValue,
    gradYear,
    daysSinceLastMeeting,
    nextBookedWorkshopName,
    nextBookedWorkshopDate,
    programSelect,
  } = globalState.dashboardFilters;
  const {
    pagination, sort
} = globalState.dashboard;
  const { handleStoreChange } = globalActions.dashboardFilters;
  const { handleStoreChangeDashboard, sortBySection } = globalActions.dashboard;
  const setNextBookedWorkshop = (value) => {
    handleStoreChange("nextBookedWorkshopName", value);
    if(value  === ""){
      handleStoreChange("nextBookedWorkshopName", "");
      handleStoreChange("nextBookedWorkshopDate", "");
      filterData(
        build_filters(
          {
            filter_by_next_booked_workshop_name: "",
            filter_by_next_booked_workshop_date: "",
          }
        ),
        ""
      );
    }
  };

  const handleButtonClick = (value) => {
    if(nextBookedWorkshopName != ""){
      handleStoreChange("nextBookedWorkshopDate", value);
      if (value === "Today") {
        filterData(
          build_filters(
            {
              filter_by_next_booked_workshop_name: nextBookedWorkshopName,
              filter_by_next_booked_workshop_date: "Today",
            }
          ),
          "nextBookedWorkshopDate"
        );
      } else if (value === "7 days") {
        filterData(
          build_filters(
            {
              filter_by_next_booked_workshop_name: nextBookedWorkshopName,
              filter_by_next_booked_workshop_date: "7 days",
            }
          ),
          "nextBookedWorkshopDate"
        );
      } else if (value === "30 days") {
        filterData(
          build_filters(
            {
              filter_by_next_booked_workshop_name: nextBookedWorkshopName,
              filter_by_next_booked_workshop_date: "30 days",
            }
          ),
          "nextBookedWorkshopDate"
        );
      } else {
        filterData(
          build_filters({}),
          "nextBookedWorkshopDate"
        );
      }
    }
  }

  const filterData = (options, filterType) => {
    globalActions.dashboard
      .filterDataBy({ ...options, section: section, page: pagination.page, per_page: pagination.amountPerPage, sort_by: sortBySection(section, sort), sort_dir: sort.sortDir })
      .then((response) => {
        handleFilteredData(response.data.records, filterType);
        handleStoreChangeDashboard("pagination", {page: response.data.pagy.page, amountPerPage: pagination.amountPerPage, next: response.data.pagy.next, prev: response.data.pagy.prev, totalPages: response.data.pagy.pages, totalData:response.data.pagy.count });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const build_filters = (new_filters) => {
    return {
      ...new_filters,
      filter_by_counselor: counselorId,
      search_by_text: searchValue,
      filter_by_grad_year: gradYear,
      filter_by_next_contact_date: nextContactDate,
      filter_by_last_meeting: daysSinceLastMeeting,
      filter_by_next_meeting: nextMeetingDate, 
      filter_by_program:  programSelect,  
    }
  };

  return (
    <div className="left-menu-button">
      <h6 className="date-title-filters">Next Booked Workshop</h6>
      <select
        className="dropdown-style no-shadow"
        value={nextBookedWorkshopName}
        onChange={(e) => setNextBookedWorkshop(e.target.value)}
      >
        <option value="">Select a filter</option>
        {next_booked_workshop_name_list.map((next_booked_workshop_name, index) => (
            <option key={index} value={next_booked_workshop_name}>
              {next_booked_workshop_name}
            </option>
        ))}
      </select>
      <button
        className={`date-filter-button ${
          nextBookedWorkshopDate === "Today" ? "date-filter-selected" : ""
        }`}
        value="Today"
        onClick={() => handleButtonClick("Today")}
      >
        Today
      </button>
      <button
        className={`date-filter-button ${
          nextBookedWorkshopDate === "7 days"  ? "date-filter-selected" : ""
        }`}
        value="7 days"
        onClick={() => handleButtonClick("7 days")}
      >
        7 days
      </button>
      <button
        className={`date-filter-button ${
          nextBookedWorkshopDate === "30 days" ? "date-filter-selected" : ""
        }`}
        value="30 days"
        onClick={() => handleButtonClick("30 days")}
      >
        30 days
      </button>
    </div>
  );
}

export default NextBookedWorkshopFilters;
