import axios from "axios"
import { generatePath } from "react-router"
import {
  SEARCHBYNAME,
  FILTERCOLLEGES,
  COLLEGESUGGESTIONS,
  COLLEGESUGGESTIONSADMINVIEW
} from "../apiConstants"

export const searchByName = (store) => {
  const { searchName, currentPage } = store.state.college
  store.setState({
    college: {
      ...store.state.college,
      requesting: true
    }
  })
  

  const hostUrl = generatePath(SEARCHBYNAME, { value: searchName, page: currentPage })
  axios.get(hostUrl).then(response => {
    if(currentPage > 1) {
      store.setState({
        college: {
          ...store.state.college,
          colleges: [...store.state.college.colleges, ...response.data.colleges],
          totalPages: response.data.meta.total_pages,
          total_colleges: response.data.meta.total_colleges,
          requesting: false
        }
      })
    } else {
      store.setState({
        college: {
          ...store.state.college,
          colleges: response.data.colleges,
          totalPages: response.data.meta.total_pages,
          total_colleges: response.data.meta.total_colleges,
          requesting: false
        }
      })
    }
  }).catch(error => {
    store.setState({
      college: {
        ...store.state.college,
        error: error.response.data.error,
        requesting: false
      }
    })
  })

  
}

export const filterColleges = (store) => {
  const { currentPage, filters } = store.state.college
  const { states, range, education, unweightedGPA, acceptanceRate, retention, needMet, include2YrColleges, duration } = filters
  store.setState({
    college: {
      ...store.state.college,
      requesting: true
    }
  })

  const searchStates = states.length > 0 ? states.toString() : "All"
  const searchRange = range.length > 0 ? range.toString() : "All"
  const searchEducation = education.length > 0 ? education : "All"
  const searchGPA = unweightedGPA.length > 0 ? unweightedGPA.toString() : "All"
  const searchAcceptance = acceptanceRate.length > 0 ? acceptanceRate.toString() : "All"
  const searchRetention = retention.length > 0 ? retention.toString() : "All"
  const searchNeedMet = needMet.length > 0 ? needMet.toString() : "All"
  const searchDuration = duration.length > 0 ? duration.toString() : "All"
  const hostUrl = generatePath(
    FILTERCOLLEGES,
    {
      states: searchStates,
      range: searchRange,
      education: searchEducation,
      gpa: searchGPA,
      acceptance: searchAcceptance,
      retention: searchRetention,
      needmet: searchNeedMet,
      include2yr: include2YrColleges,
      duration: searchDuration,
      page: currentPage
    }
  )

  axios.get(hostUrl).then(response => {
    if(currentPage > 1) {
      store.setState({
        college: {
          ...store.state.college,
          colleges: [...store.state.college.colleges, ...response.data.colleges],
          totalPages: response.data.meta.total_pages,
          total_colleges: response.data.meta.total_colleges,
          requesting: false
        }
      })
    } else {
      store.setState({
        college: {
          ...store.state.college,
          colleges: response.data.colleges,
          totalPages: response.data.meta.total_pages,
          total_colleges: response.data.meta.total_colleges,
          requesting: false
        }
      })
    }
  }).catch(error => {
    store.setState({
      college: {
        ...store.state.college,
        error: error.response.data.error,
        requesting: false
      }
    })
  })
}

export const getSuggestions = (store, search) => {
  const { name } = search

  const hostUrl = generatePath(COLLEGESUGGESTIONS, { name: name })

  axios.get(hostUrl).then(response => {
    store.setState({
      college: {
        ...store.state.college,
        suggestions: response.data,
        requesting: false
      }
    })
  }).catch(error => {
    store.setState({
      college: {
        ...store.state.college,
        error: error.response.data.error,
        requesting: false
      }
    })
  })
}

export const getSuggestionsAdminView = (store, search, student) => {
  const { name } = search
  const { student_id } = student

  const hostUrl = generatePath(COLLEGESUGGESTIONSADMINVIEW, {name: name, student_id: student_id})
  axios.get(hostUrl).then(response => {
    store.setState({
      college: {
        ...store.state.college,
        suggestions: response.data,
        requesting: false
      }
    })
  }).catch(error => {
    store.setState({
      college: {
        ...store.state.college,
        error: error.response.data.error,
        requesting: false
      }
    })
  })
}

export const handleStoreChange = (store, key, value) => {
  store.setState({
    college: {
      ...store.state.college,
      [key]: value
    }
  })
}
