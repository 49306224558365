import React from "react";
import { Row, Col } from "reactstrap";
import startCase from 'lodash/startCase';
import CollegeTableShow from "./CollegeTableShow";

const CollegeApplicationDeadlines = ({ title, props, customeref }) => {
    return <>
    <div id="application-deadlines">
        <label ref={customeref} className="College-Font">{title}</label>

        <div id="app_fee" className="safe-subtitle-body">
            <Row>
                <Col sm={12} md={6} lg={6}>
                    <Row>
                        <Col sm={4} md={8} lg={8}>
                            <p className="control-label">
                                {startCase(Object.keys(props)[0])}
                            </p>
                        </Col>
                        <Col sm={2} md={4} lg={4}>
                            <p className="props-font text-start">
                                {props.ApplicationFee}
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
        <div id="application-methods" className="safe-subtitle-body">
            <Row>
                <Col sm={12} md={6} lg={6}>
                    <Row>
                        <Col sm={4} md={8} lg={8}>
                            <p className="control-label">
                                {startCase(Object.keys(props)[1])}
                            </p>
                        </Col>
                        <Col sm={8} md={4} lg={4}>
                            <div className="props-font text-start">
                                {props.ApplicationMethod.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    props.ApplicationMethod.map((method, index) => (
                                        <div key={index}>{method}</div>
                                    ))
                                )}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
        <div id="counselor_lor" className="safe-subtitle-body">
            <Row>
                <Col sm={12} md={6} lg={6}>
                <Row>
                    <Col sm={4} md={8} lg={8}>
                    <p className="control-label">Counselor LOR</p>
                    </Col>
                    <Col sm={2} md={4} lg={4}>
                    <p className="props-font text-start">{props.CounselorLOR || 'n/a'}</p>
                    </Col>
                </Row>
                </Col>
            </Row>
        </div>
        <div id="teacher_lor" className="safe-subtitle-body">
            <Row>
                <Col sm={12} md={6} lg={6}>
                <Row>
                    <Col sm={4} md={8} lg={8}>
                    <p className="control-label">Teacher LOR</p>
                    </Col>
                    <Col sm={2} md={4} lg={4}>
                    <p className="props-font text-start">{props.TeacherLOR || 'n/a'}</p>
                    </Col>
                </Row>
                </Col>
            </Row>
        </div>
        <div id="num_essays_est" className="safe-subtitle-body">
            <Row>
                <Col sm={12} md={6} lg={6}>
                <Row>
                    <Col sm={4} md={8} lg={8}>
                    <p className="control-label">Number of Essays (Est.)</p>
                    </Col>
                    <Col sm={2} md={4} lg={4}>
                    <p className="props-font text-start">{props.NumEssaysEst || 'n/a'}</p>
                    </Col>
                </Row>
                </Col>
            </Row>
        </div>
        <div id="test_scores_preference" className="safe-subtitle-body">
            <Row>
                <Col sm={12} md={6} lg={6}>
                <Row>
                    <Col sm={4} md={8} lg={8}>
                    <p className="control-label">Self-Report or Send Official Test Scores?</p>
                    </Col>
                    <Col sm={2} md={4} lg={4}>
                    <p className="props-font text-start">{props.TestScoresPreference || 'n/a'}</p>
                    </Col>
                </Row>
                </Col>
            </Row>
        </div>
        <div id="counselor_lor" className="safe-subtitle-body">
            <Row>
                <Col sm={12} md={6} lg={6}>
                <Row>
                    <Col sm={4} md={8} lg={8}>
                    <p className="control-label">Superscore</p>
                    </Col>
                    <Col sm={2} md={4} lg={4}>
                    <p className="props-font text-start">{props.Superscore || 'n/a'}</p>
                    </Col>
                </Row>
                </Col>
            </Row>
        </div>
        <div id="counselor_lor" className="safe-subtitle-body">
            <Row>
                <Col sm={12} md={6} lg={6}>
                <Row>
                    <Col sm={4} md={8} lg={8}>
                    <p className="control-label">CSS Profile Required</p>
                    </Col>
                    <Col sm={2} md={4} lg={4}>
                    <p className="props-font text-start">{props.CssProfileRequired || 'n/a'}</p>
                    </Col>
                </Row>
                </Col>
            </Row>
        </div>
        <div id="deadlines_notifications">
            <CollegeTableShow key={props.DeadlinesNotifications.title}
                title={props.DeadlinesNotifications.title}
                headers={props.DeadlinesNotifications.headers}
                columns={props.DeadlinesNotifications.columns}
                columnsLenght={props.DeadlinesNotifications.columnsLenght}
            >
            </CollegeTableShow>
        </div>
    </div>
    </>
}

export default CollegeApplicationDeadlines;