import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons'

const DND_ITEM_TYPE = 'row'

const CollegeTableRow = ({ index, moveRow, updateRankings, children}) => {
  const dropRef = useRef(null)
  const dragRef = useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveRow(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
    drop() {
      updateRankings()
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { index },
    type: DND_ITEM_TYPE,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <tr ref={dropRef} style={{ opacity }}>
      <td ref={dragRef} className="draggable college-list-data">
        <FontAwesomeIcon className="draggable-icon" icon={faGripHorizontal} />
        <span>{index + 1}</span>
      </td>
      {children}
    </tr>
  )
}

export default CollegeTableRow