import React from "react"
import { Breadcrumb, BreadcrumbItem } from "reactstrap"
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);
dom.watch();
const CollegeProfileBreadcrumb = ({ title, url, collegeName }) => {
  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <a href={url} className="breadcrumb-option">
          {title}
        </a>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <span className="breadcrumb-active-option">
          {collegeName}
        </span>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}
export default CollegeProfileBreadcrumb
