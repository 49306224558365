import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Table,
  Alert,
} from "reactstrap"
import CollegeCompareSearchBar from "./CollegeCompareSearchBar"
import CollegesDropdown from "./CollegesDropdown"
import CollegeMenu from "./CollegeMenu"
import * as Constants from "./constants"
import useGlobal from "../../../hooks/store/index"

const CollegeCompare = ({ adminImpersonating, studentCollegesList, arrayCollegesType, arrayCollegePriority }) => {
  const [globalState, globalActions] = useGlobal()
  const { collegesToCompare, collegesList } = globalState.student
  const [collegesWidth, setCollegesWidth] = useState(0)
  const [tableVisibility, setTableVisibility] = useState('visible')
  const [show, setShow] = useState(false)

  const removeCollegeToCompareList = (college) =>{
    globalActions.student.removeCollegeToCompareList(college);
  }

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false)
    }, 3000)

  }, [show]);

  useEffect(() => {
    if(collegesList !== studentCollegesList) {
      globalActions.student.handleStoreChange('collegesList', studentCollegesList)
    }
  }, [studentCollegesList])

  useEffect(() => {
    setCollegesWidth('65%');
    const visibility = collegesToCompare.length > 0 ? 'visible' : 'hidden';
    setTableVisibility(visibility);
    setShow(visibility=='visible' ? true : false);
  }, [collegesToCompare])

  const getCollege = (collegeId) => collegesList.find(({college}) => college.id === collegeId)
  const getCollegesInStudentList = (collegeId) => collegesList.filter(({college}) => college.id === collegeId)

  return (
    <div className="margin-h-100">
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <CollegeCompareSearchBar />
        </Col>
        <Col lg={1} md={1} sm={2} xs={2} className="horizontally-and-vertically-centered">
          <h4 className="header-fourth-level-dashboard">OR</h4>
        </Col>
        <Col lg={5} md={5} sm={10} xs={10} style={{padding:0}}>
          <CollegesDropdown />
        </Col>
      </Row>
      <div style={{marginBottom:'3%', marginTop:'2%'}} >
        <p>Compare up to {collegesToCompare.length} colleges</p>
      </div>
      <Alert color="info" isOpen={show} fade={false}>
        College added
      </Alert>
      <Table bordered responsive="sm-md" style={{visibility: tableVisibility}}>
        <thead className="sticky-header">
          <tr>
            <th className="w-15 table-row-light"></th>
            {
              collegesToCompare.map((college, index) => {
                const collegesInStudentList = getCollegesInStudentList(college.id)
                const collegeInformation = getCollege(college.id)
                return(
                  <th key={index} className="table-row-light">
                    <div className="comparison-icon-menu comparison-college-height padding-1">
                      <div className="comparison-button">
                        <CollegeMenu
                          collegeId={college.id}
                          adminImpersonating={adminImpersonating}
                          arrayCollegesType={arrayCollegesType}
                          arrayCollegePriority={arrayCollegePriority}
                          showPriorities={true}
                          isInMyColleges={!!collegesInStudentList.find(({list_type}) => list_type === "my_colleges")}
                          collegeIsInList={!!collegesInStudentList}
                          priorityType={collegeInformation ? collegeInformation.priority_type : null }
                        />
                      </div>            
                      <div className="comparison-icon-menu" style={{alignItems: "flex-start"}}>
                        <span style={{minHeight: 90}}>
                          <h4>{college.name}</h4>
                          <h5>
                            { 
                              !!college.details &&
                              !!college.details.city &&
                              !!college.details.state &&
                              college.details.city_and_state
                            }
                          </h5>
                        </span>
                        <button 
                          type="button"
                          className="btn btn-link"
                          onClick={() => (removeCollegeToCompareList(college))}
                          style={{padding: 0, textTransform: 'none'}}
                        >
                          Remove
                        </button>
                      </div>
                    </div>       
                  </th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
        {Constants.detailValues.map((data, key) => {
          return (
            <tr key={key}>
              <td className={"w-15 table-row-" + (data.value == 'division' ? 'dark' : 'light')}>{data.name}</td>
              {
                collegesToCompare.map((college, index) => {
                  if (data.isUrl){
                    return(
                      <td key={index} className={"w-15 word-break table-row-" + (data.value == 'division' ? 'dark' : 'light')}>
                        <a href={college.details[data.value]} target="_blank">
                          {college.details[data.value]}
                        </a>
                      </td>
                    )
                  }
                  return(
                    <td key={index} className={"w-15 word-break table-row-" + (data.value == 'division' ? 'dark' : 'light')}>
                      {college.details[data.value]}
                    </td>
                  )
                })
              }
            </tr>
          );
        })}
        </tbody>
      </Table>
    </div>
  )
}

export default CollegeCompare
