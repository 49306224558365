import React, { useState, useEffect } from "react"
import Category from "./Category"
import useGlobal from "../../../hooks/store"

const PriorityTypeMenu = ({ collegeId, arrayCategoriesType, selectedOption, title, setPriorityLocalType, setCurrentPriorityTypeGeneralInformation}) => {
  const [globalState, globalActions] = useGlobal()
  const [selectedLocalOption, setSelectedLocalOption] = useState(null)

  const updatePriorityType = (type) => {
    if(selectedOption != null){
      selectedOption = selectedOption.toLowerCase()
    }
    if(selectedLocalOption != null){
      setSelectedLocalOption(selectedLocalOption.toLowerCase())
    }
    
    if((type == selectedLocalOption) || (type == selectedOption)){
      globalActions.student.updatePriorityTypeToCollege(type, collegeId)
      setSelectedLocalOption(null)
      setPriorityLocalType(null)
      setCurrentPriorityTypeGeneralInformation ?  setCurrentPriorityTypeGeneralInformation(null) : null;
    }else{
      globalActions.student.updatePriorityTypeToCollege(type, collegeId)
      setSelectedLocalOption(type)
      setPriorityLocalType(type)
      setCurrentPriorityTypeGeneralInformation ?  setCurrentPriorityTypeGeneralInformation(type) : null;
    }
    
  }
  
  const isSelected = (type) => {
    if (selectedLocalOption != null && type != null) {
      return type.toLowerCase() === selectedLocalOption.toLowerCase()
    } else if (selectedOption != null && type != null) {
      return type.toLowerCase() === selectedOption.toLowerCase()
    } else {
      return false
    }
  }

  return (
    <div className="js--adminDropdownMenu" data-college={collegeId}>
      <li className="col-xs-12 text-center">
        <strong className="font--college-list font--Maxx-bold">{title}</strong>
      </li>
      {
        arrayCategoriesType.map((type, index) => {
          return (
            <Category
              key={index}
              collegeId={collegeId}
              description={type.name}
              checked={isSelected(type.name)}
              label={type.label}
              handleClick={() => updatePriorityType(type.name)}
            />
          )
        })
      }
    </div>
  )
}

export default PriorityTypeMenu
