import React from "react";

function FormulaNote() {
  return (
    <div>
      <ul>
        <li>
          Only students who are not hidden, suspended, or on hold are included.
        </li>
        <li>
          If a student has more than one essay coach and their end dates are not
          set or are in the future, the student will be counted in the total
          assigned students.
        </li>
        <li>
          Team members must have the "Essay Coach" role and be active (no end
          date or end date in the future).
        </li>
      </ul>
    </div>
  );
}

export default FormulaNote;
