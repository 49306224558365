import React from "react";
import { useTable, useSortBy } from "react-table";
import "../../../../assets/stylesheets/backend/milestone_report/milestone_report.scss";
import useGlobal from "../../../hooks/store";
import { emptyMessage } from "../dashboard/utils";

function EssayCoachPerformanceReportTable({
  coachperformancecolumns,
  data,
  loading,
}) {
  const [globalState] = useGlobal();
  const { filterType } = globalState.essayCoachPerformanceFilters;

  const columns = React.useMemo(
    () => coachperformancecolumns(),
    [coachperformancecolumns]
  );

  const tableInstance = useTable(
    {
      columns,
      data: data, // Provide actual data here
      initialState: {},
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const message = emptyMessage(filterType, data);

  return (
    <div className="table-container">
      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <table {...getTableProps()} className="table table-milestone">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!loading && message && (
            <div className="tasks-dashboard-title message">{message}</div>
          )}
        </>
      )}
    </div>
  );
}

export default EssayCoachPerformanceReportTable;
