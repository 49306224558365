const student = {
  requesting: false,
  successful: false,
  error: "",
  message: "",
  collegesList: [],
  collegesToCompare: [],
  currentListType: null,
  currentIdCollegeRequest: null,
  targetSubmissionDate: null
}

export default student
