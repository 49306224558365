import React, { useState, useEffect, useMemo } from "react";
import useGlobal from "../../../hooks/store";
import Select from "react-select";
import { FILTER_TYPES, NO_COUNSELOR } from "./utils";
import { DEFAULT_PROGRAM_NAMES } from "../../../hooks/store/dashboardFilters";
let timeout;
const TIME_OUT_INTERVAL = 2000;

function FilterAndSearch({
  handleFilteredData,
  counselor_list,
  section,
  grad_year_list,
  program_list,
  employee,
  setLoading,
}) {
  const [globalState, globalActions] = useGlobal();
  let {
    searchValue,
    haveSetCurrentEmployee,
    gradYear,
    counselorId,
    programNames,
    nextContactDate,
    nextMeetingDate,
    daysSinceLastMeeting,
    nextBookedWorkshopName,
    nextBookedWorkshopDate,
    programSelect,
    hoursRemaining,
  } = globalState.dashboardFilters;

  const { pagination, sort } = globalState.dashboard;

  const { handleStoreChange } = globalActions.dashboardFilters;

  const { handleStoreChangeDashboard, sortBySection } = globalActions.dashboard;

  const [isChecked, setIsChecked] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  let generalInformationFilters = {
    filter_by_next_contact_date: nextContactDate,
    filter_by_next_meeting: nextMeetingDate,
    filter_by_last_meeting: daysSinceLastMeeting,
    filter_by_next_booked_workshop_name: nextBookedWorkshopName,
    filter_by_next_booked_workshop_date: nextBookedWorkshopDate,
  };

  const filterDataParams = (section, commonFilters) => {
    let filters = {
      ...commonFilters,
      filter_by_programs: commonFilters.filter_by_programs,
    };
    if (section == "general_info") {
      filters = { ...filters, ...generalInformationFilters };
    }
    return filters;
  };

  const validateSection = (section, params) => {
    handleStoreChange("hoursRemaining", "");
    params.filter_by_hours_remaining = "";
    if (section === "tutor") {
      const updatedProgramNames = ["Tutoring"];
      handleStoreChange("programNames", updatedProgramNames);
      params.filter_by_programs = updatedProgramNames;
      handleStoreChange("hoursRemaining", false);
      params.filter_by_hours_remaining = false;
    } else if (section === "essay_coach") {
      const programNamesList = [...DEFAULT_PROGRAM_NAMES];
      programNamesList.push("Essay Editing");
      handleStoreChange("programNames", programNamesList);
      params.filter_by_programs = programNamesList;
    } else {
      handleStoreChange("programNames", DEFAULT_PROGRAM_NAMES);
      params.filter_by_programs = DEFAULT_PROGRAM_NAMES;
    }
  };

  useEffect(() => {
    if (!isInitialized) {
      const initialParams = filterDataParams(section, {
        filter_by_counselor: counselorId,
        search_by_text: searchValue,
        filter_by_grad_year: gradYear,
        filter_by_programs: programNames,
        filter_by_hours_remaining: hoursRemaining,
      });

      validateSection(section, initialParams);
      if (searchValue) {
        filterData(initialParams, FILTER_TYPES.search);
      } else if (gradYear) {
        filterData(initialParams, FILTER_TYPES.gradYear);
      } else if (employee && !haveSetCurrentEmployee) {
        handleStoreChange("haveSetCurrentEmployee", true);
        handleStoreChange("counselorId", employee);
      } else if (programNames.length) {
        filterData(initialParams, FILTER_TYPES.programs);
      } else {
        filterData(initialParams, FILTER_TYPES.counselor);
      }
      setIsInitialized(true);
    }
  }, [
    employee,
    haveSetCurrentEmployee,
    programNames,
    gradYear,
    searchValue,
    hoursRemaining,
  ]);

  useEffect(() => {
    if (isInitialized) {
      const filterParams = filterDataParams(section, {
        filter_by_counselor: counselorId,
        search_by_text: searchValue,
        filter_by_grad_year: gradYear,
        filter_by_programs: programNames,
        filter_by_hours_remaining: hoursRemaining,
      });

      filterData(filterParams, FILTER_TYPES.counselor);
    }
  }, [counselorId]);

  const setDropdownCounselor = (value) => {
    handleStoreChange("counselorId", value);
    const filterParams = filterDataParams(section, {
      filter_by_counselor: value,
      search_by_text: searchValue,
      filter_by_grad_year: gradYear,
      filter_by_programs: programNames,
      filter_by_hours_remaining: hoursRemaining,
    });
    filterData(filterParams, FILTER_TYPES.counselor);
  };

  const setSearchValue = (value) => {
    clearTimeout(timeout);
    handleStoreChange("searchValue", value);
    const filterParams = filterDataParams(section, {
      filter_by_counselor: counselorId,
      search_by_text: value,
      filter_by_grad_year: gradYear,
      filter_by_programs: programNames,
      filter_by_hours_remaining: hoursRemaining,
    });
    timeout = setTimeout(function () {
      filterData(filterParams, FILTER_TYPES.search);
    }, TIME_OUT_INTERVAL);
  };

  const setDropdownGradYear = (selectedYears) => {
    const years = selectedYears.map((option) => option.value).join(",");
    handleStoreChange("gradYear", years);
    const filterParams = filterDataParams(section, {
      filter_by_counselor: counselorId,
      search_by_text: searchValue,
      filter_by_grad_year: years,
      filter_by_programs: programNames,
      filter_by_hours_remaining: hoursRemaining,
    });
    filterData(filterParams, FILTER_TYPES.gradYear);
  };

  const setDropdownProgram = (selectedPrograms) => {
    const programs = selectedPrograms.map((option) => option.value);
    handleStoreChange("programNames", programs);
    const filterParams = filterDataParams(section, {
      filter_by_counselor: counselorId,
      search_by_text: searchValue,
      filter_by_grad_year: gradYear,
      filter_by_programs: programs,
      filter_by_hours_remaining: hoursRemaining,
    });
    filterData(filterParams, FILTER_TYPES.programs);
  };

  const filterData = (options, filterType) => {
    setLoading(true);
    globalActions.dashboard
      .filterDataBy({
        ...options,
        section: section,
        page: pagination.page,
        per_page: pagination.amountPerPage,
        sort_by: sortBySection(section, sort),
        sort_dir: sort.sortDir,
      })
      .then((response) => {
        handleStoreChangeDashboard("pagination", {
          page: response.data.pagy.page,
          amountPerPage: pagination.amountPerPage,
          next: response.data.pagy.next,
          prev: response.data.pagy.prev,
          totalPages: response.data.pagy.pages,
          totalData: response.data.pagy.count,
        });
        handleFilteredData(response.data.records, filterType);
        clearTimeout(timeout);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckboxChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);

    if (newValue) {
      handleStoreChange("hoursRemaining", true);
      const filterParams = filterDataParams(section, {
        filter_by_counselor: counselorId,
        search_by_text: searchValue,
        filter_by_grad_year: gradYear,
        filter_by_programs: programNames,
        filter_by_hours_remaining: true,
      });
      filterData(filterParams, FILTER_TYPES.hoursRemaining);
    } else {
      handleStoreChange("hoursRemaining", false);
      const filterParams = filterDataParams(section, {
        filter_by_counselor: counselorId,
        search_by_text: searchValue,
        filter_by_grad_year: gradYear,
        filter_by_programs: programNames,
        filter_by_hours_remaining: false,
      });
      filterData(filterParams, FILTER_TYPES.hoursRemaining);
    }
  };

  return (
    <>
      <div className="filters-menu">
        <input
          className="search-style"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search..."
        />
        <span className="dashboard-title-filters">
          {section === "tutor"
            ? "Tutor:"
            : section === "essay_coach"
            ? "Essay Coach:"
            : "Counselor:"}
        </span>
        <select
          className="filter-style no-shadow"
          value={counselorId}
          onChange={(e) => setDropdownCounselor(e.target.value)}
        >
          <option key={0} value="">
            {section === "tutor"
              ? "All Tutors"
              : section === "essay_coach"
              ? "All Essay Coaches"
              : "All Counselors"}
          </option>
          {section === "general_info" && (
            <option key={1} value={NO_COUNSELOR}>
              No Counselors
            </option>
          )}
          {counselor_list.map((counselor, index) => (
            <option key={index} value={counselor["counselor_id"]}>
              {counselor["counselor_name"]}
            </option>
          ))}
        </select>
        <span className="dashboard-title-filters">Grad Year:</span>
        <Select
          className="grad-year-filter-select"
          isMulti
          value={grad_year_list
            .filter((year) => gradYear.includes(year))
            .map((year) => ({ value: year, label: year }))}
          options={grad_year_list.map((year) => ({ value: year, label: year }))}
          onChange={setDropdownGradYear}
          placeholder="Select a grad year"
        />
        <span className="dashboard-title-filters">Program:</span>
        <Select
          className="program-filter-select"
          isMulti
          value={program_list
            .filter((program) => programNames.includes(program.program_name))
            .map((program) => ({
              value: program.program_name,
              label: program.program_name,
            }))}
          options={program_list.map((program) => ({
            value: program.program_name,
            label: program.program_name,
          }))}
          onChange={setDropdownProgram}
          placeholder="Select a Program"
          styles={{
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              maxHeight: "50px",
              overflowY: "scroll",
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              width: section === "tutor" ? "120%" : "145%",
            }),
          }}
        />
        {section === "tutor" && (
          <div>
            <span className="checkbox-title-filter">
              Show Students With No Hours
            </span>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { FilterAndSearch };
