document.addEventListener("DOMContentLoaded", function () {
  const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
  const csrfToken = csrfTokenMeta ? csrfTokenMeta.content : null;

  const highSchoolSelect = $(".js--pfaServiceOrderStudentHighSchool");
  const addHighSchoolForm = document.getElementById("add-highschool-form");
  const cancelAddHighSchoolBtn = document.getElementById(
    "cancel-add-highschool-btn"
  );

  function clearHighSchoolForm() {
    document.getElementById("new_highschool_name").value = "";
    document.getElementById("new_highschool_state").value = "";
  }

  if (highSchoolSelect.length) {
    highSchoolSelect.select2({
      placeholder: "Search by name or state",
      allowClear: true,
      minimumInputLength: 1,
      language: {
        noResults: function () {
          return "";
        },
        inputTooShort: function () {
          return "Please enter 1 or more characters";
        },
      },
      escapeMarkup: function (markup) {
        return markup;
      },
    });

    highSchoolSelect.on("select2:open", function () {
      $(".select2-dropdown").addClass("select2-dropdown-high-school");

      document
        .querySelectorAll(
          ".select2-results__options #add-new-highschool-option"
        )
        .forEach((el) => el.remove());

      const observer = new MutationObserver(() => {
        const inputTooShortMessage = document.querySelector(
          ".select2-results__option.select2-results__message"
        );

        if (
          !inputTooShortMessage ||
          inputTooShortMessage.textContent !==
            "Please enter 1 or more characters"
        ) {
          if (!document.querySelector("#add-new-highschool-option")) {
            $(".select2-results__options").append(
              '<li id="add-new-highschool-option" class="select2-results__option add-new-highschool-option">+ Add a new high school</li>'
            );
          }
        }
      });

      const resultsList = document.querySelector(".select2-results__options");
      if (resultsList) {
        observer.observe(resultsList, { childList: true });
      }

      $(".select2-search__field").on("keyup", function () {
        document
          .querySelectorAll("#add-new-highschool-option")
          .forEach((el) => el.remove());
      });

      highSchoolSelect.on("select2:close", function () {
        $(".select2-dropdown").removeClass("select2-dropdown-high-school");
        observer.disconnect();
      });
    });

    $(document).on("click", "#add-new-highschool-option", function () {
      highSchoolSelect.select2("close");
      addHighSchoolForm.style.display = "block";
    });
  }

  cancelAddHighSchoolBtn.addEventListener("click", function () {
    addHighSchoolForm.style.display = "none";
    clearHighSchoolForm();
  });

  document
    .getElementById("save-new-highschool-btn")
    .addEventListener("click", function () {
      const name = document.getElementById("new_highschool_name").value;
      const state = document.getElementById("new_highschool_state").value;

      fetch("/store/pfa_services/orders/create_high_school", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({ name: name, state: state }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.id) {
            const newOption = new Option(
              `${data.name}, ${data.state}`,
              data.id,
              true,
              true
            );
            highSchoolSelect.append(newOption).trigger("change");
            addHighSchoolForm.style.display = "none";
            clearHighSchoolForm();
          } else {
            alert("Failed to add high school: " + data.errors.join(", "));
          }
        })
        .catch((error) =>
          console.error("Error creating the high school:", error)
        );
    });

  const stateSelect = $(".js--state-select");
  stateSelect.select2({
    placeholder: "Select a state",
    allowClear: true,
  });
});
