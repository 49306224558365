import React from "react";
import ReactTooltip from "react-tooltip";
import "../../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import { formatShortDate } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassHalf, faTimes } from "@fortawesome/free-solid-svg-icons";

const urlRegex = /(https?:\/\/[^\s]+)/g;

const validate_status = (value) => {
  if (value && value.status) {
    switch (value.status) {
      case "Completed":
        return (
          <img src={"/images/iconos_dashboard/completed.png"} alt="Completed" />
        );
      case "In progress":
        return (
          <img
            src={"/images/iconos_dashboard/in_progress.png"}
            alt="In progress"
          />
        );
      case "Not started":
        return (
          <img
            src={"/images/iconos_dashboard/not_started.png"}
            alt="Not started"
          />
        );
      case "Registered":
        return (
          <img
            src={"/images/iconos_dashboard/registered.png"}
            alt="Registered"
          />
        );
      case "Opted out":
        return (
          <img src={"/images/iconos_dashboard/opted_out.png"} alt="Opted out" />
        );
      default:
        return (
          <img src={"/images/iconos_dashboard/opted_out.png"} alt="Opted out" />
        );
    }
  } else {
    return (
      <img src={"/images/iconos_dashboard/opted_out.png"} alt="Opted out" />
    );
  }
};

const essay_coach = () => [
  {
    Header: "Student",
    accessor: "student_name",
    id: "student_name",
    Cell: ({ row }) => {
      return (
        <div className="student-cell">
          <a
            href={`/backend/students/${row.original.student_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.values.student_name}
          </a>
          <div className="info-container">
            {row.original.on_hold && (
              <p>
                <FontAwesomeIcon
                  icon={faHourglassHalf}
                  style={{ color: "595959" }}
                  className="on-hold-icon"
                />
              </p>
            )}
            {row.original.suspended && (
              <p>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ color: "red" }}
                  className="on-hold-icon"
                />
              </p>
            )}
          </div>
        </div>
      );
    },
  },
  {
    Header: "MAJOR",
    accessor: "student_major",
    Cell: ({ row }) => row.values.student_major || "-",
  },
  {
    Header: "COUNSELOR",
    accessor: "counselor_name",
    Cell: ({ row }) => row.values.counselor_name || "-",
  },
  {
    Header: "ESSAY COACH",
    accessor: "coach_name",
    Cell: ({ row }) => row.values.coach_name || "-",
  },
  {
    Header: "LAST ESSAY MEETING",
    accessor: "last_essay_meeting",
    Cell: ({ row }) => formatShortDate(row.values.last_essay_meeting),
  },
  {
    Header: "NEXT SCHEDULE ESSAY MEETING",
    accessor: "next_schedule_essay_meeting",
    Cell: ({ row }) => formatShortDate(row.values.next_schedule_essay_meeting),
  },
  {
    Header: "TOTAL ESSAY COACH MEETINGS",
    accessor: "number_of_meetings",
    Cell: ({ row }) => row.values.number_of_meetings || "0",
  },
  {
    Header: "ESSAY COACH HOURS USED/TOTAL HOURS AVAILABLE",
    accessor: "used_hours",
    Cell: ({ row }) =>
      (row.values.used_hours || 0) + " / " + (row.values.total_hours || 0) ||
      "-",
  },
  {
    Header: "TOTAL HOURS REMAINING",
    accessor: "total_hours",
    Cell: ({ row }) => row.values.total_hours - row.values.used_hours || "0",
  },
  {
    Header: "NOTES",
    accessor: "notes_dashboard",
    Cell: ({ value }) => {
      const isUrl =
        typeof value === "string" &&
        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value);
      const overflow = isUrl
        ? isUrl
          ? "hidden"
          : "visible"
        : isUrl
        ? "visible"
        : "hidden";
      const maxWidth = isUrl ? "260px" : isUrl ? "180px" : "none";

      const urlMatch = typeof value === "string" ? value.match(urlRegex) : null;
      const displayValue = urlMatch ? urlMatch[0] : value;

      const formattedValue = (value) => {
        const words = value.split(" ");
        const divs = [];
        for (let i = 0; i < words.length; i += 10) {
          const chunk = words.slice(i, i + 10).join(" ");
          divs.push(`<div>${chunk}</div>`);
        }
        return divs.join("");
      };

      const shorterValue = (value) => {
        var truncatedValue = value?.substring(0, 50);
        if (truncatedValue.length < value.length) {
          const lastSpaceIndex = truncatedValue.lastIndexOf(" ");
          if (lastSpaceIndex !== -1) {
            truncatedValue = truncatedValue.substring(0, lastSpaceIndex + 1);
          }
        }
        return truncatedValue;
      };

      return (
        <div style={{ maxWidth, overflow, maxHeight: "20%" }}>
          {isUrl ? (
            <span style={{ color: "black", textDecoration: "none" }}>
              {displayValue}
            </span>
          ) : (
            <span>{value ? shorterValue(value) : ""}</span>
          )}
          {value?.length > 50 && (
            <span>
              <ReactTooltip
                place="left"
                effect="solid"
                html={true}
                className="text-left"
                getContent={() => formattedValue(value)}
              />
              <span data-tip class="label label-default">
                . . .
              </span>
            </span>
          )}
        </div>
      );
    },
  },
  {
    Header: "COMMON APP PERSONAL STATEMENT WORKSHOP",
    accessor: "common_app_personal_statement_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "COMMON APP PERSONAL STATEMENT TOPIC IDENTIFIED",
    accessor: "common_app_personal_statement_topic_identified",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "COMPLETE MY COMMON APP PERSONAL STATEMENT",
    accessor: "complete_my_common_app_personal_statement",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "SUPPLEMENTAL ESSAY WORKSHOP",
    accessor: "supplemental_essay_workshop",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "SUPPLEMENTAL ESSAY STRATEGY",
    accessor: "supplemental_essay_strategy",
    Cell: ({ value }) => validate_status(value),
  },
  {
    Header: "ALL ESSAYS REVIEWED",
    accessor: "all_essays_reviewed",
    Cell: ({ value }) => validate_status(value),
  },
];

export { essay_coach };
