import React from "react";

function FormulaNote({years}) {
    return (
        <div>
          <p>For the year {years[0]}: Percentage of students who had their last counselor meeting less than 75 days ago. </p>
          <p>For the year {years[1]} and beyond: Percentage of students who had their last counselor meeting less than 90 days ago. </p>
          <p>Students enrolled for less than 30 days are considered in a healthy state. </p>
          <p>Note: The "On Hold" and "Suspended" statuses are included in the numbers and are counted as healthy in the Healthy Contact Report total counts.</p>
          <p>The report now includes a graduation year column for 2029 and an "Other" graduation year option to house anyone else outside of the grad years shown.</p>
        </div>
      );
      
}

export default FormulaNote;
