// googleDriveUtils.js
import { download, createFile } from './excelUtils';
import { gapi } from 'gapi-script';

export async function folderAlreadyExist(name, accessToken) {
    const driveApiUrl = 'https://www.googleapis.com/drive/v3/files';

    const queryParams = {
      q: `name='${name}' and mimeType='application/vnd.google-apps.folder'`,
      fields: 'files(id, name)',
    };

    const url = new URL(driveApiUrl);
    url.search = new URLSearchParams(queryParams).toString();

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
}

export function createFolder(name, accessToken) {
    const folderMetadata = {
        name: name,
        mimeType: 'application/vnd.google-apps.folder',
      };
  
      return fetch('https://www.googleapis.com/drive/v3/files', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(folderMetadata),
      });
}

export async function signInWithGoogle(data, studentName) {
    try {
        const authInstance = await gapi.auth2.getAuthInstance();
        const user = await authInstance.signIn();
        const folderName = 'CollegesList';
        var accessToken = gapi.auth.getToken().access_token;
        folderAlreadyExist(folderName, accessToken)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(folders => {
            if (folders.files.length > 0) {
                createFile(data, folders.files[0].id, accessToken, studentName) ;
            } else {
                createFolder(folderName, accessToken)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(folderData => {
                        const folderId = folderData.id;
                        createFile(data, folderId, accessToken, studentName);
                    })
                    .catch(error => {
                        console.error('Error creating folder:', error);
                    });
            }
        })
        .catch(error => {
            console.error('Error checking folder:', error);
        });

  
      } catch (error) {
        console.error('Error signing in:', error);
      }
}

export async function initializeGapi() {
    const SCOPE = 'https://www.googleapis.com/auth/drive.file';
    const handleClientLoad = () => gapi.load('client:auth2', initClient);

    const initClient = () => {
      gapi.client
        .init({
          clientId:
            '553849067138-ou4ptc74v59pr65rh57q8imrvbp6plt3.apps.googleusercontent.com',
          scope: SCOPE,
        })
        .catch((error) => {
          console.error('Error: ', error);
        });
    };

    const script = document.createElement('script');

    script.src = 'https://apis.google.com/js/api.js';
    script.async = true;
    script.defer = true;
    script.onload = handleClientLoad;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
}
