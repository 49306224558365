import React from "react"
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas);
dom.watch();

const TwoYrsMenu = ({include2YrColleges, setInclude2YrColleges}) => {
  return (
    <li className='filter--element-label' onClick = {() => setInclude2YrColleges(!include2YrColleges)}>
        Include 2 Yr Colleges
        {
          include2YrColleges &&
          <FontAwesomeIcon icon="check" />
        }
    </li>
  )
}
export default TwoYrsMenu