import React, { useRef, useState } from 'react'
import startCase from 'lodash/startCase';
import ReactTooltip from "react-tooltip";
import {
  Card,
  Col,
  Row,
} from "reactstrap";
import LinkWrapper from './LinkWrapper'
import Section from './Section'
import GeneralInformation from "./GeneralInformation";
import gloveLogo from '../../../../assets/images/globe-CollegeSection.svg'
import addressLogo from '../../../../assets/images/Map-CollegeSection.svg'
import phoneLogo from '../../../../assets/images/phone-CollegeSection.svg'
import CollegeApplicationDeadlines from "./collegeApplicationDeadlinesSection";
import SidebarItem from "./SideBarCollegeSection";
import Sticky from 'react-stickynode'
import CollegeBanner from "./CollegeBanner";
import AdmissionFactors from './AdmissionFactors';
import Majors from './Majors'
import ReactHtmlParser from "react-html-parser";
import CollegeSearchBar from './CollegeSearchBar';
import CollegeProfileBreadcrumb from './CollegeProfileBreadcrumb';
import AcceptanceRates from './AcceptanceRates';


const HelloCollegeInsight = ({ title, hellocollegeinsights, customeref }) => {
    return (
      <>
        {hellocollegeinsights.helloCollege && 
        <div id="hello-college-insights">
          <label id="title" className="College-Font" ref={customeref}>{title}</label>
          <Card>
            <div className="safe-subtitle-body">
              <div id="body" className="college-decision-space">
                {ReactHtmlParser(hellocollegeinsights.helloCollege)}
              </div>
            </div>
          </Card>
        </div>}
      </>
    );   
};

// Specific for BasicInfo
const BasicInfo = ({ title, propsBasic, detailsBasic, customeref }) => {

  propsBasic.campusSetting = translate_campus_setting(propsBasic.campusSetting)

  return (
    <>
      <Card id="basic-info">
        <label ref={customeref} className="College-Font">{title}</label>
        <div className="safe-subtitle-body">
          <div className="college-decision-space">
            <div className="row page--font-color m-0 safe-subgroup">
              <Row>
                <div className={"col-lg-6 col-md-6 col-sm-12 col-xs-12"}>
                  <Col sm={12} md={12} lg={12} xs={12} className="college-title">
                    <p id="web-link" className="form-static-control cursor"><img src={gloveLogo} alt="gloveLogo" /><a href={detailsBasic.website ? detailsBasic.website : 'n/a'} target="_blank">   {detailsBasic.website ? detailsBasic.website : 'n/a'}</a></p>
                    <hr className="solid" />
                    <p id="complete-address" className="form-static-control"><img src={addressLogo} alt="addressLogo" />
                      {detailsBasic.city || detailsBasic.state || detailsBasic.street  ? (
                        <span> {detailsBasic.street} {detailsBasic.city ? detailsBasic.city + ", " : <></>} {detailsBasic.state} {detailsBasic.zipCode}</span>
                      ) : (
                        <span> n/a</span>
                      )
                      }
                    </p>
                    <hr className="solid" />
                    <p id="phone-number" className="form-static-control"><img src={phoneLogo} alt="phoneLogo" />   {detailsBasic.phone ? detailsBasic.phone : 'n/a'}</p>
                    <hr className="solid" />
                  </Col>
                </div>
                <div className={"col-lg-6 col-md-6 col-sm-12 col-xs-12"}>
                  {propsBasic ? (
                    Object.keys(propsBasic).map((keyName, colKey) => {
                      return (
                        <Col key={colKey} sm={12} md={12} lg={12} xs={12} className="college-title">
                          <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12"}>
                            <span id = "row-title" className="control-label">{startCase(keyName)}: </span>
                            <span id = "row-info" className="props-font float-right">
                              {propsBasic[keyName] ? propsBasic[keyName] : 'n/a'}
                              {keyName == 'admissionsDifficulty' ?
                                <>
                                  <span data-tip data-for="admissionsDifficultyTip" className='mg-left-sm glyphicon glyphicon-question-sign hidden-xs hidden-sm'></span>
                                  <ReactTooltip class="text-left" id="admissionsDifficultyTip" html={true} place="left" effect="solid">
                                    {admissionsDifficultyTooltip()}
                                  </ReactTooltip>
                                </>
                                : ''}
                            </span>
                            <hr className="solid " />
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

const admissionsDifficultyTooltip = () => {
  return "- Non-Competitive: <br> " +
    "Virtually all applicants accepted regardless of high school rank or test<br>scores." +
    "<br><br>- Minimally difficult: <br> " +
    "Most freshmen were not in the top 50% of their high school class and" +
    "<br> scored somewhat below 1010 on SAT or below 19 on ACT; up to 95% " +
    "<br>of applicants accepted." +
    "<br><br>- Moderately difficult: <br> " +
    "More than 75% of freshmen were in the top 50% of their high school" +
    "<br> class and scored over 1010 on SAT or over 18 on ACT; about 85% or" +
    "<br> fewer of all applicants accepted." +
    "<br><br>- Very difficult: <br> " +
    "More than 50% of all freshmen were in the top 10% of their high" +
    "<br>school class and scored over 1230 on SAT or over 26 on ACT; about " +
    "<br>60% or fewer of all applicants accepted." +
    "<br><br>- Most difficult: <br> " +
    "More than 75% freshmen were in the top 10% of their high school" +
    "<br>class and scored over 1310 on SAT or over 29 on ACT; about 30% or " +
    "<br>fewer of all applicants accepted.<br>";
}

const admissionsDifficultymodifier = (admissionsDifficulty) => {
  switch (admissionsDifficulty) {
    case 'NONC':
      return "Non-Competitive";
    case 'MIN':
      return "Minimally difficult";
    case 'MOST':
      return "Most difficult";
    case 'MOD':
      return "Moderately difficult";
    case 'VERY':
      return "Very difficult";
    default:
      return admissionsDifficulty;
  }
}

const translate_campus_setting = (campus_setting) => {

  switch (campus_setting) {
    case "Subrb":
      return "Suburban"
    case "Small":
      return "Small town"
    default:
      return campus_setting
  }
}


const completeRow = (columnHeaders, value) => {
  return columnHeaders.map(element => {
    return value == element ? 'X' : ''
  });
}

const completeRowAdmissionFactors = (columnHeaders) => {
  return columnHeaders.map(element => {
    return value === "NC" | "C" ? 'X' : ''
  })

}

let dollarUS = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  useGrouping: true,
});

const calculateTotalCost = ({ fees, room_board }, costOfAttendence, tuition_common) => {
  let totalCost = fees + room_board
  totalCost += costOfAttendence || tuition_common
  return totalCost > 0 ? dollarUS.format(totalCost) : "n/a";
}

const CollegeSection = ({ college, priorityType, collegedetails, hellocollegeinsight, address, totalstudents, adminImpersonating, arrayCollegesType, arrayCollegePriority, collegeLists, rankings, student }) => {

  const acceptanceRateKeys = [
    'acceptance_rate',
    'yield_rate',
    'ed_admit_rate',
    'ea_admit_rate',
    'acceptance_rate_business',
    'acceptance_rate_engineering',
    'acceptance_rate_computer_science',
    'acceptance_rate_nursing',
    'acceptance_rate_biology',
  ];

  const hasAnyAcceptanceRate = acceptanceRateKeys.some(key => collegedetails[key]);

  const acceptanceRatesDetails = hasAnyAcceptanceRate ? {
    title: "Acceptance Rates for Specific Majors",
    majors: [
      collegedetails.acceptance_rate_business && { title: "Business", field: "business_acceptance_rate", value: collegedetails.acceptance_rate_business + "%" },
      collegedetails.acceptance_rate_engineering && { title: "Engineering", field: "engineering_acceptance_rate", value: collegedetails.acceptance_rate_engineering + "%" },
      collegedetails.acceptance_rate_computer_science && { title: "Computer Science", field: "computer_science_acceptance_rate", value: collegedetails.acceptance_rate_computer_science + "%" },
      collegedetails.acceptance_rate_nursing && { title: "Nursing", field: "nursing_acceptance_rate", value: collegedetails.acceptance_rate_nursing + "%" },
      collegedetails.acceptance_rate_biology && { title: "Biology", field: "biology_acceptance_rate", value: collegedetails.acceptance_rate_biology + "%" }
    ].filter(Boolean)
  } : null;

  const generalInformation = {
    name: college.name,
    state: collegedetails.state,
    city: collegedetails.city,
    institution_type: collegedetails.institution_type
  }

  const sectionTitles = [
    {
      title: "Basic Info",
      detailsBasic: {
        website: (collegedetails.electronic_app ? collegedetails.electronic_app : 'n/a'),
        street: address?.line1,
        zipCode: address?.zip_code,
        city: collegedetails.city,
        state: collegedetails.state,
        phone: collegedetails.admission_phone
      },
      propsBasic: {
        admissionsDifficulty: admissionsDifficultymodifier(collegedetails.admissions_difficulty),
        // admissionsDifficultyOutOfState: admissionsDifficultymodifier(collegedetails.admissions_difficulty_out_state),
        campusSetting: collegedetails.campus_setting,
        nearestMetroArea: collegedetails.nearest_metro_area,
      }
    },
    {
      title: "HelloCollege Insights",
      hellocollegeinsights: {
        helloCollege: hellocollegeinsight
      },
    },

    {
      title: "Student Body",
      props: {
        freshmanClassSize: collegedetails.enrolled_freshmen,
        degreeSeekingUndergraduateEnrollment: collegedetails.num_undergrads,
        totalStudents: totalstudents,
      },
    },
    {
      title: "Academics",
      props: {
        "4 Year Graduation Rate": (collegedetails.four_year_graduation_rate ? collegedetails.four_year_graduation_rate + "%" : "n/a"),
        "6 Year Graduation Rate": (collegedetails.six_year_graduation_rate ? collegedetails.six_year_graduation_rate + "%" : "n/a"),
        freshmenRetention: (collegedetails.first_year_retention ? collegedetails.first_year_retention + "%" : "n/a"),
        studentFacultyRatio: collegedetails.ratio_students_faculty ? collegedetails.ratio_students_faculty + "%" : "n/a",
      },
    },
    {
      title: "Acceptance Rates",
      props: {
        acceptance_rate: collegedetails.acceptance_rate ? collegedetails.acceptance_rate + "%" : 'n/a',
        yieldRate: collegedetails.yield_rate ? collegedetails.yield_rate + "%" : 'n/a',
        EDAdmitRate: collegedetails.ed_admit_rate ? collegedetails.ed_admit_rate + "%" : 'n/a',
        EAAdmitRate: collegedetails.ea_admit_rate ? collegedetails.ea_admit_rate + "%" : 'n/a',
        additionalNotes: {
          title: "Additional Notes",
          field: "Notes",
          value:  collegedetails.acceptance_rate_note
        },
        acceptanceRatesDetails
      }
    },
    {
      title: "Applications & Deadlines",
      props: {
        // values order is important, ApplicationsFee should be the first
        ApplicationFee: (collegedetails.application_fees ? dollarUS.format(collegedetails.application_fees) : "n/a"),
        ApplicationMethod: (collegedetails.application_methods ? 
          collegedetails.application_methods.map(method => (
            <div key={method}>{method}</div>
          )) : "n/a"),
        CounselorLOR: (collegedetails.counselor_lor ? collegedetails.counselor_lor : "n/a"),
        TeacherLOR: (collegedetails.teacher_lor ? collegedetails.teacher_lor : "n/a"),
        NumEssaysEst: (collegedetails.num_essays_est ? collegedetails.num_essays_est : "n/a"),
        TestScoresPreference: (collegedetails.test_scores_preference ? collegedetails.test_scores_preference : "n/a"),
        Superscore: (collegedetails.total_superscore ? collegedetails.total_superscore : "n/a"),
        CssProfileRequired: (collegedetails.css_profile_required ? collegedetails.css_profile_required : "n/a"),
        DeadlinesNotifications: {
          title: "DEADLINES & NOTIFICATIONS",
          headers: ["", "Early Decision", "Early Action", "Regular Decision"],
          columnsLenght: [3, 3, 3, 3],
          columns: [
            ["Deadline", collegedetails.ed_deadline ? collegedetails.ed_deadline : 'n/a',
              collegedetails.ea_deadline ? collegedetails.ea_deadline : 'n/a',
              collegedetails.regular_admission_deadline ? collegedetails.regular_admission_deadline : 'n/a'],
            ["Notification", collegedetails.ed_notification ? collegedetails.ed_notification : 'n/a',
              collegedetails.ea_notification ? collegedetails.ea_notification : 'n/a',
              collegedetails.notification_by ? collegedetails.notification_by : 'n/a']
          ]
        }
      }
    },
    {
      title: "Rankings",
      props: rankings
    },
    {
      title: "Costs",
      props: {
        costOfAttendanceInState: calculateTotalCost(collegedetails, collegedetails.in_state_tuition, collegedetails.tuition_common),
        costOfAttendanceOutOfState: calculateTotalCost(collegedetails, collegedetails.out_of_state_tuition, collegedetails.tuition_common),
        inStateTuition: (collegedetails.in_state_tuition ? dollarUS.format(collegedetails.in_state_tuition) : (collegedetails.tuition_common ? dollarUS.format(collegedetails.tuition_common) : 'n/a')),
        outOfStateTuition: (collegedetails.out_of_state_tuition ? dollarUS.format(collegedetails.out_of_state_tuition) : (collegedetails.tuition_common ? dollarUS.format(collegedetails.tuition_common) : 'n/a')),
        fees: (collegedetails.fees ? dollarUS.format(collegedetails.fees) : 'n/a'),
        roomAndBoard: (collegedetails.room_board ? dollarUS.format(collegedetails.room_board) : 'n/a'),
        booksAndSupplies: (collegedetails.books ? dollarUS.format(collegedetails.books) : 'n/a')
      }

    },
    {
      title: "Financial Aid & Scolarships",
      props: {
        netPriceCalculator: ((collegedetails.net_price_calc_url) ? <LinkWrapper link={collegedetails.net_price_calc_url} text="NET PRICE" /> : "n/a"),
        financialAidWebsite: (collegedetails.finaid_website ? <LinkWrapper link={collegedetails.finaid_website} /> : "n/a"),
        avgFreshmanFinancialPackage: (dollarUS.format(collegedetails.average_freshman_financial) ? dollarUS.format(collegedetails.average_freshman_financial) : "n/a"),
        ofNeedMetForFreshmen: (collegedetails.need_met_freshman ? collegedetails.need_met_freshman + "%" : "n/a"),
        freshmenAwardedAidWithoutNeed: (collegedetails.freshman_awarded_aid_without_need ? collegedetails.freshman_awarded_aid_without_need : "n/a"),
        avgNonNeedScholarshipAwardsOffered: (collegedetails.nn_scholarship_awards_offered ? dollarUS.format(collegedetails.nn_scholarship_awards_offered) : "n/a"),
        FASFARequired: (collegedetails.fafsa_required ? collegedetails.fafsa_required : "n/a"),
        CSSRequired: (collegedetails.css_required ? collegedetails.css_required : "n/a"),
        financialAidDeadline: (collegedetails.finaid_deadline ? collegedetails.finaid_deadline : "n/a"),
        priorityFinancialAidDeadline: (collegedetails.priority_financial_deadline ? collegedetails.priority_financial_deadline : "n/a"),
        avgFreshmanNeedBasedGiftAid: (collegedetails.avg_freshman_need_based_gift_aid ? dollarUS.format(collegedetails.avg_freshman_need_based_gift_aid) : "n/a"),
        freshmanNonNeedAward: (collegedetails.avg_freshman_no_need_award ? dollarUS.format(collegedetails.avg_freshman_no_need_award) : "n/a"),
      },
    },
    {
      title: "Career Preparation",
      props: {
        graduatingClassWithFullTimeJobOffered6MonthsAfterGraduation: (collegedetails.graduating_class_job_offers ? collegedetails.graduating_class_job_offers + "%" : "n/a"),
        averageStartingSalary: collegedetails.avg_starting_salary ? dollarUS.format(collegedetails.avg_starting_salary) : "n/a",
      },
    },
    {
      title: "Admission Factors",
      props: {
        testOptional: collegedetails.test_optional === "Yes" ? "YES" : "NO",
        "Average High School GPA": collegedetails.averaged_gpa_value,
        "ACT/SAT 25th Percentile": collegedetails.act_low + " / " + collegedetails.sat_low,
        "ACT/SAT 75th Percentile": collegedetails.act_high + " / " + collegedetails.sat_high,
        "Average SAT score": collegedetails.avg_sat_score,
        "Average ACT score": collegedetails.avg_act_score,
      },
      admissionFactors: {
        title: "ADMISSION FACTORS",
        headers: ["Factor", "Very Important", "Important", "Considered", "Not Considered"],
        columnsLenght: [4, 2, 2, 2, 2],
        columns: [
          ["Extracurricular Activities",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.extra_activity)),
          ["Alumni Relation (Legacy)",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.alumni_ae_relation)),
          ["Level of Applicant's Interest",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.lvl_applicant_int)),
          ["Character/Personal Qualities",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.character_personal_quality)),
          ["Application Essay",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.application_essay)),
          ["First Generation To Attend College",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.first_gen_attend_college)),
          ["Geographical Residence",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.geographical_residence)),
          ["Academic GPA",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.academic_gpa)),
          ["Interview",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.interview)),
          ["Racial/Ethnic Status",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.racial_ethnic_status)),
          ["Class Rank",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.class_rank)),
          ["Recommendations",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.recommendation)),
          ["Religious Affiliation/Commitment",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.religious_affilition)),
          ["Rigor of Secondary School Record",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.rigor_sec_school_record)),
          ["State Residency",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.state_residency)),
          ["Talent/Ability",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.talent_ability)),
          ["Stardarized Test Scores",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.stardarized_test_score)),
          ["Volunteer Work",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.volunteer_work)),
          ["Work Experience",].concat(completeRow(["VI", "I", "C", "NC"], collegedetails.work_experience)),
        ]
      }
    },
    {
      title: "Majors",
      props: {
        "BS/MD Program Page": collegedetails.bs_md_page ? <LinkWrapper link={collegedetails.bs_md_page} text="Program Page" /> : "n/a",
        bs_md_duration: {
          title: "BS/MD Duration",
          values: collegedetails.bs_md_duration,
        }
      },
    }
  ]


  const itemsRef = Array.from({ length: sectionTitles.length }, () => useRef());

  const smoothScrollSidebar = i => {
    itemsRef[i].current.scrollIntoView({ block: "start", behavior: "smooth" })
    setIsActive(i);
  };

  const [isActive, setIsActive] = useState(0);

  const renderByCollegeSearchComponent = window.sessionStorage.getItem('isCollegeSearchComponent');
  return (
    <>
      <Row className='margin-search-bar'>
        <Col className="col-lg-2 col-sm-2 col-xs-12">
          <a href={`/students/${student.id}/college_list${renderByCollegeSearchComponent === "true" ? '/search' : ''}`}
          collegeName={generalInformation.name} className="Back-Label">
            {"<"} Back
          </a>
        </Col>
        <Col className="col-lg-10 col-sm-9 col-xs-12 search-bar--top">
          <CollegeSearchBar />
        </Col>
      </Row>
      <Row>
        <CollegeBanner collegeBanner={college.banner_url} />
        <GeneralInformation
          collegeId={college.id}
          name={generalInformation.name}
          state={generalInformation.state}
          city={generalInformation.city}
          institutionType={generalInformation.institution_type}
          avatarUrl={
            college.avatar_url != null
              ? college.avatar_url
              : "https://talent2win.com/wp-content/uploads/2021/09/LogoDefault.png"
          }
          collegeLists={collegeLists}
          priorityType={priorityType}
          adminImpersonating={adminImpersonating}
          arrayCollegesType={arrayCollegesType}
          arrayCollegePriority={arrayCollegePriority}
        />
      </Row>
      <Row>
        <Col md={3} lg={3} className="hidden-xs hidden-sm">
          <Sticky top={30}>
            {sectionTitles.map((section, index) => {
              return (
                <SidebarItem
                  key={index}
                  title={section.title}
                  onClickFuntion={() => {
                    smoothScrollSidebar(index);
                  }}
                  isActive={index == isActive}
                />
              );
            })}
          </Sticky>
        </Col>

        <Col id="info-tables" sm={12} md={9} lg={9}>
          {sectionTitles.map((section, index) => {
            switch (section.title) {
              case "Applications & Deadlines": {
                return (
                  <CollegeApplicationDeadlines
                    key={index}
                    title={section.title}
                    props={section.props}
                    customeref={itemsRef[index]}
                  />
                );
              }
              case "Basic Info": {
                return (
                  <BasicInfo
                    key={index}
                    title={section.title}
                    detailsBasic={section.detailsBasic}
                    propsBasic={section.propsBasic}
                    customeref={itemsRef[index]}
                  />
                );
              }
              case "HelloCollege Insights": {
                return (
                  <HelloCollegeInsight
                    key={index}
                    title={section.title}
                    hellocollegeinsights={section.hellocollegeinsights}
                    customeref={itemsRef[index]}
                  />
                );
              }
              case "Admission Factors": {
                return (
                  <AdmissionFactors
                    key={index}
                    props={section.props}
                    title={section.title}
                    admissionFactors={section.admissionFactors}
                    customeref={itemsRef[index]}
                  />
                );
              }
              case "Majors": {
                return (
                  <Majors
                    key={index}
                    title={section.title}
                    props={section.props}
                    customRef={itemsRef[index]}
                  />
                );
              }
              case "Acceptance Rates": {
                return (
                  <AcceptanceRates
                    key={index}
                    title={section.title}
                    props={section.props}
                    customRef={itemsRef[index]}
                  />
                );
              }
              default: {
                return (
                  <Section
                    key={index}
                    title={section.title}
                    props={section.props}
                    customeref={itemsRef[index]}
                  />
                );
              }
            }
          })}
        </Col>
      </Row>
    </>
  );
};
export default CollegeSection;
