export const DEMOGRAPHICS = {
  storageKey: "demographicsDetails",
  title: "Demographics",
  attributes: [
    { header: "Institution Type", field: "institution_type", value: null },
    { header: "State", field: "state", value: null },
    { header: "City", field: "city", value: null },
    { header: "Nearest Metro Area", field: "nearest_metro_area", value: null },
    { header: "Freshman Class Size", field: "freshman_class_size", value: null },
  ]
}

export const KEYSTATS = {
  storageKey: "keyStatsDetails",
  title: "Key Stats",
  attributes: [
    { header: "Act/sat 25th Percentile", field: "sat_low", value: null },
    { header: "Act/sat 25th Percentile", field: "act_low", value: null },
    { header: "Act/sat 75th Percentile", field: "sat_high", value: null },
    { header: "Act/sat 75th Percentile", field: "act_high", value: null },
    { header: "First Year Retention Rate", field: "first_year_retention_rate", value: null },
    { header: "Graduation Rate 4 Yr / 6 Yr", field: "six_year_graduation_rate", value: null },
    { header: "Graduation Rate 4 Yr / 6 Yr", field: "four_year_graduation_rate", value: null },
  ]
}

export const STANDARDIZEDTESTING = {
  storageKey: "standardizedTestingDetails",
  title: "Standardized Testing",
  attributes: [
    { header: "Act/sat Test Optional?", field: "test_optional", value: null },
    { header: "Use ACT Superscore? **", field: "superscore", value: null },
  ]
}

export const FINANCIAL = {
  storageKey: "financialDetails",
  title: "Financial",
  attributes: [
    { header: "NET Price Calculator", field: "net_price_calc_url", value: null },
    { header: "Average % of Need Met", field: "avg_need_met", value: null },
    { header: "Cost of Attendance: In-State", field: "in_state_total_cost", value: null },
    { header: "Cost of Attendance: Out-State", field: "out_of_state_total_cost", value: null },
  ]
}

export const CONTACT = {
  storageKey: "contactDetails",
  title: "Contact",
  attributes: [
    { header: "School Homepage", field: "homepage", value: null },
    { header: "Application Webpage", field: "electronic_app", value: null },
    { header: "Financial AID Webpage", field: "finaid_website", value: null },
    { header: "Admissions Phone Number", field: "admission_phone", value: null },
    { header: "Freshman Class Size", field: "freshman_class_size", value: null },
  ]
}

export const APPLICATION = {
  storageKey: "applicationDetails",
  title: "Application",
  attributes: [
    { header: "Application Fees", field: "application_fees", value: null },
    { header: "Common Application Used?", field: "common_app", value: null },
    { header: "Average GPA (unweighted)", field: "averaged_gpa_value", value: null },
    { header: "Essay(s) Required?", field: "essays", value: null },
    { header: "Interview", field: "interviews", value: null },
    { header: "Recommendation(s) required?", field: "letters_of_rec", value: null },
  ]
}

export const KEYDATES = {
  storageKey: "keyDatesDetails",
  title: "Key Dates",
  attributes: [
    { header: "Early Action Deadline", field: "ea_deadline", value: null },
    { header: "Early Decision Deadline", field: "ed_deadline", value: null },
    { header: "Regular Deadline", field: "regular_admission_deadline", value: null },
    { header: "Student Notification EA/ED", field: "ed_notification", value: null },
    { header: "Student Notification EA/ED", field: "ea_notification", value: null },
    { header: "Student Notification Regular", field: "notification_by", value: null },
    { header: "Financial Aid Deadline", field: "finaid_deadline", value: null },
  ]
}
