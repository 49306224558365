import moment from "moment"

export const eventLongDate = (date) => (
  moment(date).format("MMM Do, YYYY")
)

export const completeMonthName = (date) => (
  moment(date).format("MMMM")
)

export const shortTimeOnly = (time) => (
  moment(time).format("LT")
)

export const shortDayOnly = (date) => (
  moment(date).format("ddd DD")
)
