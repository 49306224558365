import React, { useState, useEffect } from "react";
import useGlobal from "../../../hooks/store";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";

function DateFilters({ handleFilteredData, section, handleFilteredStates }) {
  const [globalState, globalActions] = useGlobal();
  const {
    nextContactDate,
    nextMeetingDate,
    counselorId,
    searchValue,
    gradYear,
    daysSinceLastMeeting,
    selectedButton,
    filterSelected,
    dropdownOptionSelected,
    nextBookedWorkshopName,
    nextBookedWorkshopDate,
    programSelect,
  } = globalState.dashboardFilters;

  const {
    pagination, sort
} = globalState.dashboard;
  const { handleStoreChange } = globalActions.dashboardFilters;

  const { handleStoreChangeDashboard, sortBySection} = globalActions.dashboard;

  const handleNextFilterChange = (value) => {
    if (value === "") {
      handleStoreChange("nextContactDate", "");
      handleStoreChange("nextMeetingDate", "");
      handleStoreChange("selectedButton", "");
      handleStoreChange("filterSelected", false);
      handleStoreChange("dropdownOptionSelected", "");
      filterData(
        build_filters(
          {
            filter_by_next_contact_date: "",
            filter_by_next_meeting: "",
          }
        ),
        ""
      );
    } else if (value === "nextContactDate") {
      if (nextMeetingDate) {
        handleStoreChange("nextMeetingDate", "");
        handleStoreChange("selectedButton", "");
      }
      handleStoreChange("nextContactDate", value);
      handleStoreChange("filterSelected", true);
      handleStoreChange("dropdownOptionSelected", value);
    } else if (value === "nextMeetingDate") {
      if (nextContactDate) {
        handleStoreChange("nextContactDate", "");
        handleStoreChange("selectedButton", "");
      }
      handleStoreChange("nextMeetingDate", value);
      handleStoreChange("filterSelected", true);
      handleStoreChange("dropdownOptionSelected", value);
    }
  };

  const setNextContactDate = (value) => {
    handleStoreChange("nextContactDate", value);
    if (value === "Today") {
      filterData(
        build_filters(
          {
            filter_by_next_contact_date: "Today",
          }
        ),
        "nextContactDate"
      );
    } else if (value === "7 days") {
      filterData(
        build_filters(
          {
            filter_by_next_contact_date: "7 days",
          }
        ),
        "nextContactDate"
      );
    } else if (value === "30 days") {
      filterData(
        build_filters(
          {
            filter_by_next_contact_date: "30 days",
          }
        ),
        "nextContactDate"
      );
    } else {
      filterData(
        build_filters({}),
        "nextContactDate"
      );
    }
  };

  const setNextMeetingDate = (value) => {
    handleStoreChange("nextMeetingDate", value);

    if (value === "Today") {
      filterData(
        build_filters(
          {
            filter_by_next_meeting: "Today",
          }
        ),
        "nextMeetingDate"
      );
    } else if (value === "7 days") {
      filterData(
        build_filters(
          {
            filter_by_next_meeting: "7 days",
          }
        ),
        "nextMeetingDate"
      );
    } else if (value === "30 days") {
      filterData(
        build_filters(
          {
            filter_by_next_meeting: "30 days",
          }
        ),
        "nextMeetingDate"
      );
    } else {
      filterData(
        build_filters({}),
        "nextMeetingDate"
      );
    }
  };

  const filterData = (options, filterType) => {
    globalActions.dashboard
      .filterDataBy({ ...options, section: section, page: pagination.page, per_page: pagination.amountPerPage, sort_by: sortBySection(section, sort), sort_dir: sort.sortDir })
      .then((response) => {
        handleFilteredData(response.data.records, filterType);
        handleStoreChangeDashboard("pagination", {page: response.data.pagy.page, amountPerPage: pagination.amountPerPage, next: response.data.pagy.next, prev: response.data.pagy.prev, totalPages: response.data.pagy.pages, totalData:response.data.pagy.count });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleButtonClick = (value) => {
    if (filterSelected) {
      if (nextContactDate) {
        setNextContactDate(value);
      } else if (nextMeetingDate) {
        setNextMeetingDate(value);
      }
      handleStoreChange("selectedButton", value);
    }
  };

  const build_filters = (new_filters) => {
    return {
      ...new_filters,
      filter_by_counselor: counselorId,
      search_by_text: searchValue,
      filter_by_grad_year: gradYear,
      filter_by_last_meeting: daysSinceLastMeeting,
      filter_by_next_booked_workshop_name: nextBookedWorkshopName,
      filter_by_next_booked_workshop_date: nextBookedWorkshopDate,
      filter_by_program:  programSelect,
    }
  };
  return (
    <div className="left-menu-button">
      <select
        className="dropdown-style no-shadow"
        value={dropdownOptionSelected}
        onChange={(e) => handleNextFilterChange(e.target.value)}
      >
        <option value="">Select a filter</option>
        <option value="nextContactDate">Next Contact Date</option>
        <option value="nextMeetingDate">Next Meeting</option>
      </select>
      <button
        className={`date-filter-button ${
          selectedButton === "Today" ? "date-filter-selected" : ""
        }`}
        value="Today"
        onClick={() => handleButtonClick("Today")}
      >
        Today
      </button>
      <button
        className={`date-filter-button ${
          selectedButton === "7 days" ? "date-filter-selected" : ""
        }`}
        value="7 days"
        onClick={() => handleButtonClick("7 days")}
      >
        7 days
      </button>
      <button
        className={`date-filter-button ${
          selectedButton === "30 days" ? "date-filter-selected" : ""
        }`}
        value="30 days"
        onClick={() => handleButtonClick("30 days")}
      >
        30 days
      </button>
    </div>
  );
}

export default DateFilters;
