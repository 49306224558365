import React from "react";
import "../../../../assets/stylesheets/backend/dashboard/dashboard.scss";
import TutorTable from "./TutorTable";
function Tutor({
  counselor_list,
  grad_year_list,
  employee,
  paginationData,
  program_list,
}) {
  return (
    <div>
      <h1 className="dashboard-title">Tutor Dashboard</h1>
      <TutorTable
        counselor_list={counselor_list}
        grad_year_list={grad_year_list}
        employee={employee}
        paginationData={paginationData}
        program_list={program_list}
      />
    </div>
  );
}
export default Tutor;
