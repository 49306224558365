import React, { useState, useEffect } from 'react';
import useGlobal from "../../../hooks/store"
import { Card, CardBody, Col, Row } from 'reactstrap';
import { default as checkIcon } from '../../../../assets/images/check-circle.svg';
import { default as arrowIcon } from '../../../../assets/images/arrow-icon.svg';
import CollegeMenu from "./CollegeMenu";

const GeneralInformation = ({collegeId, name, city, state, institutionType, avatarUrl, collegeLists, priorityType, adminImpersonating, arrayCollegesType, arrayCollegePriority, addedBy}) => {
  const [globalState, globalActions] = useGlobal()
  const { collegesList, currentListType } = globalState.student
  const [currentPrioryType, setCurrentPriorityTypeGeneralInformation] = useState(priorityType)
  const [currentAddedBy, setCurrentAddedBy] = useState(addedBy);
  const [currentList, setCurrentList] = useState([])

  useEffect(() => {
    if(collegesList.length === 0 && currentListType === null) {
      globalActions.student.handleStoreChange("collegesList", collegeLists)
      globalActions.student.handleStoreChange("currentListType", "my_colleges")
    }
  })

  useEffect(() => {
    setCurrentList(collegesList.filter(collegeList => (collegeList.list_type === currentListType ? collegeList : null)))
  }, [currentListType, collegesList])

  useEffect(() => {
    const [isRecommended] = collegesList.filter(collegeList => collegeList.college.id === collegeId && collegeList.list_type === 'for_you')
    isRecommended ? setCurrentAddedBy(isRecommended.added_by_person) : setCurrentAddedBy(null)
  }, [collegesList])

  const myCollegesIds = []
  collegesList.map(collegeList => {
    if(myCollegesIds.indexOf(collegeList.college.id) === -1 && collegeList.list_type === "my_colleges"){
        myCollegesIds.push(collegeList.college.id)
    }
  })

  return (
    <>
      <Card className='general-information-container'>
        <CardBody>
          <Row style={{width: '100%', paddingTop: '5px', margin: 'auto'}}>
            <Col sm={2} md={2} lg={2}>
              <div className="logo-container">
                <img src={avatarUrl} alt='College Avatar' className= 'college-logo-small' />
              </div>

            </Col>
            <Col id="general-info" sm={5} md={5} lg={5}>
              <div style={{marginTop: '10px', paddingLeft: '5px'}}>
                <p id="inst-type" className='h5 general-information-font'>{institutionType}</p>
                <p id="inst-name" className='college-name-p h3 general-information-font'>{name}</p>
                <p id="inst-city-state" className='h4 general-information-font'>{city}, {state}</p>
              </div>
            </Col>
            <Col sm={4} md={4} lg={4} className="extra-information-container">
              
              <div className="extra-information-body">  
                {
                  currentAddedBy &&
                    <Row className="custom-row custom-margin d-flex">
                      <Col sm={1} md={1} lg={1} className="custom-col ml-2">
                        <img src={checkIcon}></img>
                      </Col>
                      <Col sm={10} md={9} lg={9}>
                        <p className='custom-p'> {currentAddedBy ? <> {<small style={{textDecoration: 'underline'}}> {currentAddedBy}</small>} <small>Recommended</small></>  : "Not recommended"} </p>
                      </Col>
                    </Row>
                }

                <Row className={`custom-row d-flex ${!currentAddedBy && "padding-bottom-0"}`}>
                  <Col sm={1} md={1} lg={1} className="custom-col ml-2">  
                    {currentPrioryType ? <img src={arrowIcon}></img> : <></>}  
                  </Col>
                  <Col sm={10} md={9} lg={9}>
                  <p className='custom-p capital-letter'>{currentPrioryType ? currentPrioryType : ""}</p>
                  </Col>
                </Row>

              </div>

            </Col>

            <Col sm={1} md={1} lg={1}>
              <CollegeMenu
                collegeId={collegeId}
                adminImpersonating={adminImpersonating}
                priorityType={currentPrioryType}
                arrayCollegesType={arrayCollegesType}
                arrayCollegePriority={arrayCollegePriority}
                isInMyColleges={myCollegesIds.indexOf(collegeId) !== -1}
                collegeIsInList={true}
                setCurrentPriorityTypeGeneralInformation={setCurrentPriorityTypeGeneralInformation}
                hideCategory={true}
              />
          
            </Col>
          </Row>

        </CardBody>
      </Card>
    </>

  )

}


export default GeneralInformation