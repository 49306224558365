import React from "react";
import { useTable, useSortBy } from "react-table";
import { Col, Row } from "reactstrap";
import "../../../../assets/stylesheets/backend/milestone_report/milestone_report.scss";
import useGlobal from "../../../hooks/store";
import { emptyMessage } from "../dashboard/utils";

function MilestoneTable({ milestonecolumns, data, loading }) {

  const [globalState, globalActions] = useGlobal();
  const {
    filterType
  } = globalState.milestoneFilters;

  const columns = React.useMemo(() => milestonecolumns(), [milestonecolumns]);

  const tableInstance = useTable(
    {
      columns,
      data: data, // Provide actual data here
      initialState: {},
    },
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const message = emptyMessage(filterType, data);

  return (
    <div className="table-container">
      <table {...getTableProps()} className="table table-milestone">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps()}
                  
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!loading && message && (
            <div className="tasks-dashboard-title message">{message}</div>
          )}
      {loading && <div className="loading">Loading...</div>}
    </div>
  );
}

export default MilestoneTable;
