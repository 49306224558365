import React from "react";
import {
  Card,
  Col,
  Row,
} from "reactstrap";
import startCase from 'lodash/startCase';


const CustomSectionBody = ({ keyName, value }) => {
  return (
    <Col sm={12} md={12} lg={12} xs={12} className="college-title">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <span id="custom-section-row-value" className="props-font float-right">{value ? value : 'n/a'}</span>
        <span className="control-label">{startCase(keyName)}: </span>
        <hr className="solid" />
      </div>
    </Col>
  )
}

const CustomSection = ({ props, leftColumnElements }) => {
  return (
    <div className="row page--font-color m-0 safe-subgroup">
      <Col lg={6} md={6} sm={12} xs={12}>
        {
          Object.keys(props).map((keyName, i) => {
            return (
              <>
                {
                  (i < leftColumnElements) ?
                    <CustomSectionBody key={i} keyName={keyName} value={props[keyName]} /> : null
                }
              </>
            )
          })
        }
      </Col>
      <Col lg={6} md={6} sm={12} xs={12}>
        {
          Object.keys(props).map((keyName, i) => {
            return (
              <>
                {
                  (i >= leftColumnElements) ?
                    <CustomSectionBody key={i} keyName={keyName} value={props[keyName]} /> : null
                }
              </>
            )
          })
        }
      </Col>
    </div>

  )
}


// For when we need to Iterate properties. Ex: "Email: test@test.com"
const Section = ({ title, props, customeref, leftColumnElements }) => {

  const Propsdivider = (props) => {
    const propsSize = props ? Object.values(props).length : 0;
    return propsSize <= 4 ? 12 : 6;
  };

  const columnNumbers = Propsdivider(props);

  const rankingTableRender = (rankings) => {
    if (Object.keys(rankings).length !== 0) {
      return (
        Object.keys(rankings).map((key) => {
          return (
            <div className="row page--font-color m-0 safe-subgroup">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <span id="rank-name" className="control-label">{key}: </span>
                <span id="rank-value" className="props-font float-right">{rankings[key]}</span>
                <hr className="solid" />
              </div>
            </div>
          )
        })
      )
    }
    else {
      return (<span id="no-rankings">There are no rankings for this college.</span>)
    }
  }

  const tableRender = (props) => {
    return (
      !leftColumnElements ?
        <div id="table-body" className="row page--font-color m-0 safe-subgroup">
          {props ? (
            Object.keys(props).map((keyName, i) => {
              return (
                <Col key={i} sm={12} md={columnNumbers} lg={columnNumbers} xs={12} className="college-title">
                  <div className={columnNumbers != 6 ? "col-lg-6 col-md-6 col-sm-12 col-xs-12" : "col-lg-12 col-md-12 col-sm-12 col-xs-12"}>
                    {
                      getFieldComponent(props, title, keyName)
                    }
                    <hr className="solid" />

                  </div>
                </Col>
              );
            })
          ) : (<></>)
          }
        </div>
        : (<CustomSection props={props} leftColumnElements={leftColumnElements} />)
    )
  }

  const chooseTableToRender = (props) => {
    return (
      title === "Rankings" ? (
        rankingTableRender(props)
      ) : (
        tableRender(props)
      )
    )
  }

  return (
    <>
      <div id="table">
        <label id="title" className="College-Font" ref={customeref}>{title}</label>
        <Card>
          <div className="safe-subtitle-body">
            <div id="body" className="college-decision-space">
              {chooseTableToRender(props)}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

const getFieldComponent = (props, title, keyName) => {
  switch (true) {
    case title == "Acceptance Rates" && keyName == "acceptance_rate":
      return (
        <>
          <span id="row-title" className="control-label"><strong>{startCase(keyName)}</strong> </span> <br />
          <span id="row-info" className="control-label" > <h2 style={{ marginTop: "0px" }}><strong>{props[keyName] ? props[keyName] : 'n/a'} </strong></h2></span>
        </>
      );
    case title == "Costs" && (keyName == "costOfAttendanceInState" || keyName == "costOfAttendanceOutOfState"):
      return (
        <>
          <span className="control-label"><strong>{startCase(keyName)}</strong> </span> <br />
          <>(Includes tuition, room & board, fees)</><br />
          <span className="control-label" > <h2 id="row-cost-value" style={{ marginTop: "0px" }}><strong>{props[keyName] ? props[keyName] : 'n/a'} </strong></h2></span>
        </>
      );
    case title == "Financial Aid & Scolarships" && keyName == "ofNeedMetForFreshmen":
      return (
        <>
          <span id="row-title" className="control-label">% {startCase(keyName)} </span>
          <span id="row-info" className="props-font float-right">{props[keyName] ? props[keyName] : 'n/a'}</span>
        </>
      );
    default:
      return (
        <>
          <span id="row-title" className="control-label">{startCase(keyName)}: </span>
          <span id="row-info" className="props-font float-right">{props[keyName] ? props[keyName] : 'n/a'}</span>
        </>
      );
  }
}

export default Section;
