import React from "react"
import useGlobal from "../../../../hooks/store"
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FilterLine from "./FilterLine";
import TwoYrsMenu from "./TwoYrsMenu";

library.add(fas);
dom.watch();

const MainMenu = ({ clearFilters, setFilter }) => {
  const [globalState, globalActions] = useGlobal()
  const { filters, currentPage } = globalState.college
  const { states, range, education, unweightedGPA, acceptanceRate, retention, needMet, include2YrColleges, duration} = filters

  const filter = () => {
    globalActions.college.handleStoreChange("currentPage", 1)
    globalActions.college.handleStoreChange("searchBy", "filter")
    globalActions.college.filterColleges(currentPage)
    $(".dropdown-options").toggle()
  }
  const handleChange = (key, value) => {
    globalActions.college.handleStoreChange("filters", { ...globalState.college.filters, [key]: value })
  }
  
  return (
    <div>
      <div className="navigation-options-container__margin">
        <div className="profile-completition-message">
          <div className='filter-done-btn filter--done-button col-xs-4 col-sm-4 col-md-4 col-lg-4'>
            <span onClick={() => clearFilters()}>Clear</span>
          </div>
          <div className='filter--title col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding'></div>
          <div className='filter-done-btn filter--done-button col-xs-4 col-sm-4 col-md-4 col-lg-4'>
            <span onClick={() => filter()}>Search</span>
          </div>
        </div>
      </div>
      <hr className='title-separator' />
      <TwoYrsMenu include2YrColleges={include2YrColleges} setInclude2YrColleges={(value) => handleChange('include2YrColleges', value)}/>
      <hr className="element-separator" />
      <FilterLine title={"State"} tooltipMessage={""} selectedOptions={states} filter={"state"} setFilter={(filter) => setFilter(filter)} />
      <hr className="element-separator" />
      <FilterLine title={"Size"} tooltipMessage={"The number of undergraduates attending the college or university."} selectedOptions={range} filter={"undergraduate"} setFilter={(filter) => setFilter(filter)} />
      <hr className="element-separator" />
      <li className='filter--element-label' onClick = {() => setFilter("education")}>
        <div className="profile-completition-message purple-tooltip">
        <span className="filter-name">Public/Private</span>
          <div
            data-toggle="tooltip"
            data-placement="top"
            title="The major difference between public universities and private colleges lies in how they are funded. Public money is why tuition is lower at a public university."
          >
            <FontAwesomeIcon icon="info-circle" size="lg" className="filter--information-icons"/>
          </div>
        </div>
        <div className="filter--element-description">
          {education}
        </div>
        <span>{">"}</span>
      </li>
      <hr className="element-separator" />
      <FilterLine title={"Unweighted GPA"} tooltipMessage={"GPA strength can vary widely depending on high school. This is only one measure of selectivity. To get an accurate view of your selectivity fit you will also want to consider ACT/SAT scores and other factors."} selectedOptions={unweightedGPA} filter={"gpa"} setFilter={(filter) => setFilter(filter)} />
      <hr className="element-separator" />
      <FilterLine title={"Acceptance Rate"} tooltipMessage={"The acceptance rate of a university is the ratio of the number of applicants offeredadmission to the total number of applicants. If a university receives 100 applications and offers admission to 75 students, the acceptance rate is 75%. It is not a measure of how many students actually join."} selectedOptions={acceptanceRate} filter={"acceptance"} setFilter={(filter) => setFilter(filter)} />
      <hr className="element-separator" />
      <FilterLine title={"1st Year Retention"} tooltipMessage={"The freshman retention rate refers to the number of freshmen in a college or university who return for their sophomore year. This is an important number because it tells you how many freshmen liked their experience and were successful enough to return to that same school as sophomores."} selectedOptions={retention} filter={"retention"} setFilter={(filter) => setFilter(filter)} />
      <hr className="element-separator" />
      <FilterLine title={"% of Need Met"} tooltipMessage={"Family Financial Need is the cost of attendance (“COA”) less expected family contribution (“EFC”) which equals “Demonstrated Financial Need”. A school that meets 100% need-met (full-need) provides financial aid that equals the family's demonstrated need"} selectedOptions={needMet} filter={"needmet"} setFilter={(filter) => setFilter(filter)} />
      <hr className="element-separator" />
      <FilterLine title={"BS/MD Duration"} tooltipMessage={"Duration in years of Bachelor Science or Medical Degree"} selectedOptions={duration} filter={"duration"} setFilter={(filter) => setFilter(filter)} />
    </div>
  )
}
export default MainMenu
