import React, { useEffect, useState } from "react";
import ReportTable from "./ReportTable";
import useGlobal from "../../../hooks/store";
import "../../../../assets/stylesheets/backend/healthy_report/healthy_report.scss";
import FormulaNote from "./FormulaNote";
import { Filters } from "./Filters";

function Report({ years, program_list }) {
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataReport, setDataReport] = useState([]);
  const [globalState, globalActions] = useGlobal();
  const { handleStoreChange } = globalActions.healthyContactFilters;
  const { programNames } = globalState.healthyContactFilters;

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const filterDataParams = (commonFilters) => commonFilters;

  const filterData = async (options, filterType) => {
    try {
      const response = await globalActions.healthyContactFilters.filterDataBy({
        ...options,
      });
      const dataReport = response.data;
      const formattedDataReport = Object.values(dataReport[0]).map(
        (columnData) => ({
          ...columnData,
        })
      );
      setDataReport(formattedDataReport);
      handleStoreChange("filterType", filterType);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("counselors");
        const data = await response.json();

        setColumns(data);
        setLoading(false);

        if (!isInitialLoad) {
          const filterParams = filterDataParams({
            filter_by_programs: programNames || [],
          });
          await filterData(filterParams, "");
        }

        setIsInitialLoad(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [programNames]);

  return (
    <div className="report-container">
      <h1 className="report-title-filters">Healthy Contact</h1>
      <Filters program_list={program_list} filterData={filterData} />
      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <ReportTable columns={columns} tableData={dataReport} />
      )}
      <FormulaNote years={years} />
    </div>
  );
}

export default Report;
