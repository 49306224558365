import React from 'react'
import {
    Card,
    Col,
    Row,
  } from "reactstrap";
  import startCase from 'lodash/startCase';
  
const Majors = ({title, props, customRef}) => {
    const half = Math.ceil(props.bs_md_duration?.values?.length / 2)
    const leftColumnValues = props.bs_md_duration?.values?.slice(0, half)
    const rightColumnValues = props.bs_md_duration?.values?.slice(half)

    const isEmpty = (array) => {
      return array && array.length === 0
    }

    return (
        <>
        <Card id="table-majors">
          <label id="title" ref={customRef} className="College-Font">{title}</label>
          <div className="safe-subtitle-body">
            <div className="college-decision-space">
              <div className="row page--font-color m-0 safe-subgroup">
                <Row>
                  <div className={"col-lg-6 col-md-6 col-sm-12 col-xs-12"}>
                    {props ? (
                        Object.keys(props).map((keyName, i) => {
                            if(keyName !== "bs_md_duration"){
                                return (
                                    <Col key={i} sm={12} md={12} lg={12} xs={12} className="college-title">
                                    <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12"}>
                                    <span className="control-label">{keyName} </span>
                                    <span className="props-font float-right">{props[keyName] ? props[keyName] : 'n/a'}</span>
                                    <hr className="solid " />
                                    </div>
                                    </Col>
                                )
                            }    
                        })) : 
                        (
                        <></>
                        )}
                  </div>
                  <div className={"col-lg-6 col-md-6 col-sm-12 col-xs-12"} style={{margin: 'auto'}}>
                    <Col sm={12} md={12} lg={12} xs={12} className="college-title">
                      <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12"}>
                        <span className="control-label">{props.bs_md_duration.title}: </span>
                        {
                          !isEmpty(props.bs_md_duration.values) ? (
                            <Row>
                              <div className={"col-lg-6 col-md-6 col-sm-11 col-xs-11"}>
                                 {
                                   leftColumnValues.map((e, i) => {
                                     return (
                                       <Row key={i}>
                                         <span className="props-font float-right" > {e}</span>
                                       </Row>
                                     )
                                   })
                                 }
                              </div>
                              <div className={"col-lg-6 col-md-6 col-sm-11 col-xs-11"}>
                                {
                                  rightColumnValues.map((e, i) => {
                                    return (
                                      <Row key={i}>
                                      <span className="props-font float-right" > {e}</span>
                                      </Row>
                                    )
                                  })
                                }
                              </div>
                          </Row>
                          ) : (

                            <span id="majors-row-value" className="props-font float-right"> {"n/a"}</span>                            
                          )
                        }
                        <hr className='solid'/>
                      </div>
                    </Col>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </Card>
      </>
    )
}

export default Majors;